import React from 'react'
import s from "./dayPriceTitle.module.scss";
import { Rub } from "../Currency/Rub";

export const DayPriceTitle = ( { price, page } ) => {
  return (
    <div style={ { display: 'flex', justifyContent: 'space-between' } }>
      <div className={ s.title3 } style={ { fontSize: (page === 'account' ? '14px' : '20px') } }>Количество дней</div>
      <div className={ s.price_value }>{ price ? price.day : 0 } <Rub/> &nbsp;/ день</div>
    </div>
  )
}
