import React from 'react';
import s from './wrapper.module.scss';

export const Wrapper = ({ children }) => {
  return (
    <div className={s.main_wrapper}>
      {children}
      <div id='modals'></div>
      <div id='popups'></div>
    </div>
  );
};
