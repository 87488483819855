import axios from "axios";

const DADATA = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"
const ORDER = '/api/order'
const SAND_PHONE = '/api/user/phones'
const RETRY_SAND_PHONE = '/api/user/phones/retry'
const PHONE_CONFIRM = '/api/user/phones/confirmation'
const UPDATE_USER = '/api/user'
const PROMOCODE = '/api/order/promocodes?'

export const autocompletesLoad = (query, token, search) => {
  return axios.post(
    DADATA,
    {query: query, to_bound: {value: search}},
    {headers: {authorization: `Token ${token}`}},
  )
}

export const createOrder = (data) => axios.post(ORDER, data)

export const sandPhone = (data) => axios.post(SAND_PHONE, data)
export const retrySandPhone = (data) => axios.post(RETRY_SAND_PHONE, data)
export const phoneConfirmation = (data) => axios.post(PHONE_CONFIRM, data)

export const updateUserInfo = (data) => axios.put(UPDATE_USER, data)

export const checkPromoCode = (data) => {
  return axios(PROMOCODE + new URLSearchParams({
    promo_code: data.promo,
    days: data.days,
    diet: data.diet,
    ...(data.city && { city: data.city })
  }))
}
