import React from "react";

export const LogoIcon = () => (
  <svg width="137" height="45" viewBox="0 0 137 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="44" height="44" rx="22" fill="#FF6938"/>
    <g clipPath="url(#clip0_762_925)">
      <path d="M24.1966 12.1535C24.3551 12.5795 24.3808 13.0417 24.2705 13.482C24.1602 13.9223 23.9189 14.3208 23.5769 14.6273C23.2349 14.9338 22.8076 15.1345 22.3489 15.2042C21.8902 15.2739 21.4206 15.2095 20.9994 15.019C20.5781 14.8284 20.2241 14.5204 19.982 14.1337C19.7399 13.747 19.6204 13.299 19.6388 12.8461C19.6572 12.3932 19.8125 11.9558 20.0851 11.589C20.3578 11.2222 20.7357 10.9424 21.171 10.785C21.4616 10.6799 21.7706 10.6319 22.0803 10.6439C22.39 10.6558 22.6942 10.7275 22.9754 10.8547C23.2566 10.9819 23.5093 11.1621 23.7189 11.385C23.9285 11.6079 24.0908 11.8691 24.1966 12.1535Z" fill="white"/>
      <path d="M30.7178 9.77503L30.6494 9.71662C30.5654 9.64133 30.4667 9.58322 30.3593 9.54574C30.2518 9.50826 30.1378 9.49215 30.0239 9.49837C29.91 9.50459 29.7985 9.53302 29.696 9.58197C29.5935 9.63091 29.5021 9.6994 29.4272 9.78338L23.8716 15.8498H20.1366L14.581 9.78338C14.5061 9.6994 14.4147 9.63091 14.3122 9.58197C14.2097 9.53302 14.0983 9.50459 13.9844 9.49837C13.8704 9.49215 13.7564 9.50826 13.649 9.54574C13.5416 9.58322 13.4429 9.64133 13.3588 9.71662L13.2904 9.77503C13.2044 9.8482 13.1343 9.93744 13.0841 10.0375C13.034 10.1375 13.0049 10.2464 12.9985 10.3576C12.9922 10.4688 13.0087 10.5801 13.047 10.685C13.0854 10.7899 13.1449 10.8862 13.2221 10.9683L18.9828 17.285L19.0768 17.3685V33.223C19.0768 33.5704 19.2182 33.9036 19.4698 34.1493C19.7215 34.395 20.0628 34.533 20.4187 34.533C20.7745 34.533 21.1159 34.395 21.3675 34.1493C21.6192 33.9036 21.7605 33.5704 21.7605 33.223V24.0691H22.2392V33.1896C22.2392 33.537 22.3805 33.8703 22.6322 34.116C22.8838 34.3616 23.2252 34.4997 23.581 34.4997C23.9369 34.4997 24.2782 34.3616 24.5299 34.116C24.7816 33.8703 24.9229 33.537 24.9229 33.1896V17.3351L30.7093 11.01C30.7979 10.9324 30.869 10.8375 30.9179 10.7315C30.9669 10.6255 30.9926 10.5106 30.9934 10.3944C30.9942 10.2781 30.9701 10.1629 30.9226 10.0563C30.8752 9.94962 30.8054 9.85382 30.7178 9.77503Z" fill="white"/>
    </g>
    <path d="M55.5659 28.5001V15.9001H67.0499V28.5001H64.1519V17.6101L64.8179 18.2761H57.7979L58.4639 17.6101V28.5001H55.5659Z" fill="#252525"/>
    <path d="M76.2033 28.7161C75.2073 28.7161 74.2833 28.5541 73.4313 28.2301C72.5913 27.9061 71.8593 27.4501 71.2353 26.8621C70.6233 26.2741 70.1433 25.5841 69.7953 24.7921C69.4593 24.0001 69.2913 23.1361 69.2913 22.2001C69.2913 21.2641 69.4593 20.4001 69.7953 19.6081C70.1433 18.8161 70.6293 18.1261 71.2533 17.5381C71.8773 16.9501 72.6093 16.4941 73.4493 16.1701C74.2893 15.8461 75.2013 15.6841 76.1853 15.6841C77.1813 15.6841 78.0933 15.8461 78.9213 16.1701C79.7613 16.4941 80.4873 16.9501 81.0993 17.5381C81.7233 18.1261 82.2093 18.8161 82.5573 19.6081C82.9053 20.3881 83.0793 21.2521 83.0793 22.2001C83.0793 23.1361 82.9053 24.0061 82.5573 24.8101C82.2093 25.6021 81.7233 26.2921 81.0993 26.8801C80.4873 27.4561 79.7613 27.9061 78.9213 28.2301C78.0933 28.5541 77.1873 28.7161 76.2033 28.7161ZM76.1853 26.2321C76.7493 26.2321 77.2653 26.1361 77.7333 25.9441C78.2133 25.7521 78.6333 25.4761 78.9933 25.1161C79.3533 24.7561 79.6293 24.3301 79.8213 23.8381C80.0253 23.3461 80.1273 22.8001 80.1273 22.2001C80.1273 21.6001 80.0253 21.0541 79.8213 20.5621C79.6293 20.0701 79.3533 19.6441 78.9933 19.2841C78.6453 18.9241 78.2313 18.6481 77.7513 18.4561C77.2713 18.2641 76.7493 18.1681 76.1853 18.1681C75.6213 18.1681 75.0993 18.2641 74.6193 18.4561C74.1513 18.6481 73.7373 18.9241 73.3773 19.2841C73.0173 19.6441 72.7353 20.0701 72.5313 20.5621C72.3393 21.0541 72.2433 21.6001 72.2433 22.2001C72.2433 22.7881 72.3393 23.3341 72.5313 23.8381C72.7353 24.3301 73.0113 24.7561 73.3593 25.1161C73.7193 25.4761 74.1393 25.7521 74.6193 25.9441C75.0993 26.1361 75.6213 26.2321 76.1853 26.2321Z" fill="#252525"/>
    <path d="M85.1001 28.6981C84.8841 28.6981 84.6561 28.6801 84.4161 28.6441C84.1881 28.6081 83.9361 28.5601 83.6601 28.5001L83.8401 26.0341C84.0081 26.0821 84.1821 26.1061 84.3621 26.1061C84.8541 26.1061 85.2381 25.9081 85.5141 25.5121C85.7901 25.1041 85.9881 24.5221 86.1081 23.7661C86.2281 23.0101 86.3061 22.0921 86.3421 21.0121L86.5221 15.9001H96.2061V28.5001H93.3261V17.6101L93.9921 18.2761H88.4301L89.0241 17.5741L88.9161 20.9041C88.8801 22.1401 88.7961 23.2381 88.6641 24.1981C88.5321 25.1581 88.3281 25.9741 88.0521 26.6461C87.7761 27.3181 87.3981 27.8281 86.9181 28.1761C86.4381 28.5241 85.8321 28.6981 85.1001 28.6981Z" fill="#252525"/>
    <path d="M105.455 20.0401C106.979 20.0401 108.155 20.3821 108.983 21.0661C109.811 21.7381 110.225 22.7521 110.225 24.1081C110.225 25.5481 109.763 26.6401 108.839 27.3841C107.927 28.1281 106.661 28.5001 105.041 28.5001H99.353V15.9001H102.251V20.0401H105.455ZM104.879 26.3401C105.623 26.3401 106.211 26.1541 106.643 25.7821C107.075 25.4101 107.291 24.8701 107.291 24.1621C107.291 23.4661 107.075 22.9681 106.643 22.6681C106.223 22.3561 105.635 22.2001 104.879 22.2001H102.251V26.3401H104.879Z" fill="#252525"/>
    <path d="M110.84 27.3841L111.74 25.2961C112.28 25.6201 112.856 25.8721 113.468 26.0521C114.092 26.2201 114.698 26.3161 115.286 26.3401C115.886 26.3521 116.426 26.2921 116.906 26.1601C117.398 26.0281 117.788 25.8301 118.076 25.5661C118.376 25.3021 118.526 24.9661 118.526 24.5581C118.526 24.0781 118.328 23.7241 117.932 23.4961C117.536 23.2561 117.008 23.1361 116.348 23.1361H113.27V20.9401H116.132C116.72 20.9401 117.176 20.8201 117.5 20.5801C117.836 20.3401 118.004 20.0101 118.004 19.5901C118.004 19.2301 117.884 18.9421 117.644 18.7261C117.404 18.4981 117.074 18.3301 116.654 18.2221C116.246 18.1141 115.784 18.0661 115.268 18.0781C114.752 18.0901 114.218 18.1741 113.666 18.3301C113.114 18.4861 112.586 18.7021 112.082 18.9781L111.2 16.7281C112.016 16.3081 112.874 16.0201 113.774 15.8641C114.686 15.6961 115.562 15.6541 116.402 15.7381C117.254 15.8101 118.022 16.0021 118.706 16.3141C119.39 16.6141 119.936 17.0101 120.344 17.5021C120.752 17.9941 120.956 18.5761 120.956 19.2481C120.956 19.8481 120.8 20.3821 120.488 20.8501C120.176 21.3181 119.738 21.6781 119.174 21.9301C118.622 22.1821 117.986 22.3081 117.266 22.3081L117.338 21.6601C118.178 21.6601 118.904 21.8041 119.516 22.0921C120.14 22.3681 120.62 22.7581 120.956 23.2621C121.304 23.7541 121.478 24.3241 121.478 24.9721C121.478 25.5721 121.316 26.1121 120.992 26.5921C120.68 27.0601 120.248 27.4561 119.696 27.7801C119.144 28.1041 118.514 28.3441 117.806 28.5001C117.098 28.6561 116.342 28.7221 115.538 28.6981C114.746 28.6741 113.948 28.5601 113.144 28.3561C112.34 28.1401 111.572 27.8161 110.84 27.3841Z" fill="#252525"/>
    <path d="M122.365 28.5001L127.981 15.9001H130.861L136.495 28.5001H133.435L128.827 17.3761H129.979L125.353 28.5001H122.365ZM125.173 25.8001L125.947 23.5861H132.427L133.219 25.8001H125.173Z" fill="#252525"/>
    <defs>
      <clipPath id="clip0_762_925">
        <rect width="18" height="25" fill="white" transform="translate(13 9.5)"/>
      </clipPath>
    </defs>
  </svg>
)
