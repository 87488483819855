import React from "react"

import AccountSettingsPopup from "./popups/accountSettingsPopup"
import CashOrderPopup from "./popups/cashOrderPopup"
import CityPopup from "./popups/cityPopup"
import EmailCollectAccount from "./popups/emailCollectAccount"
import EmailCollectLanding from "./popups/emailCollectLanding"
import emitterClient from "./emitterClient"

export default class PopupControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = { queue: this.props.queue, current: {}, totalDelay: 0 }
    this.pushToQueue = this.pushToQueue.bind(this)
    this.appendToQueue = this.appendToQueue.bind(this)
    this.closeCurrent = this.closeCurrent.bind(this)
  }

  componentDidMount() {
    const { queue, totalDelay } = this.state
    const [metaData, ...updatedQueue] = queue
    if (metaData) {
      setTimeout(() => {
        this.setState({
          current: { popup: metaData.popup, props: metaData.props },
          queue: updatedQueue,
          totalDelay: totalDelay + metaData.delay,
        })
      }, metaData.delay)
    }
  }

  pushToQueue(popup, delay = 0, props = {}) {
    const { queue, current } = this.state
    if (current.popup) {
      this.setState({ queue: [{ popup, delay, props }, ...queue] })
    } else {
      setTimeout(() => {
        this.setState({ current: { popup, props } })
      }, delay)
    }
  }

  appendToQueue(popup, delay = 0, props = {}) {
    const { queue, current } = this.state
    if (current.popup) {
      this.setState({ queue: [...queue, { popup, delay, props }] })
    } else {
      setTimeout(() => {
        this.setState({ current: { popup, props } })
      }, delay)
    }
  }

  closeCurrent() {
    const { queue, totalDelay } = this.state
    const [metaData, ...updatedQueue] = queue
    this.setState({ current: {}, queue: updatedQueue })
    if (metaData) {
      this.setState({ totalDelay: totalDelay + metaData.delay })
      setTimeout(() => {
        this.setState({ current: metaData })
      }, Math.max(5000, metaData.delay - totalDelay))
    }
  }

  componentWillMount() {
    emitterClient.on("PUSH_POPUP", this.pushToQueue)
    emitterClient.on("APPEND_POPUP", this.appendToQueue)
  }

  componentWillUnmount() {
    emitterClient.removeListener("PUSH_POPUP", this.pushToQueue)
    emitterClient.removeListener("APPEND_POPUP", this.appendToQueue)
  }

  renderPopup() {
    const { current } = this.state
    switch (current.popup) {
      case "accountSettingsPopup":
        return (
          <AccountSettingsPopup
            onClose={this.closeCurrent}
            {...current.props}
          />
        )
      case "cityPopup":
        return <CityPopup onClose={this.closeCurrent} {...current.props} />
      case "cashOrderPopup":
        return <CashOrderPopup onClose={this.closeCurrent} {...current.props} />
      case "emailCollectAccount":
        return (
          <EmailCollectAccount onClose={this.closeCurrent} {...current.props} />
        )
      case "emailCollectLanding":
        return (
          <EmailCollectLanding onClose={this.closeCurrent} {...current.props} />
        )
      case "emailCollectPromo":
        return (
          <EmailCollectPromo onClose={this.closeCurrent} {...current.props} />
        )
      default:
        return null
    }
  }

  render() {
    return this.renderPopup()
  }
}
