import React from 'react';
import cx from 'classnames';
import s from './top.module.scss';
import { contentList } from './contentList';
import HeaderBackgroundMobileJpg from '../../../../app/assets/images/header_background_mobile.jpg';
import HeaderBackgroundTabletJpg from '../../../../app/assets/images/header_background_tablet.jpg';
import HeaderBackgroundDesktopJpg from '../../../../app/assets/images/header_background_desktop.jpg';
import DecorImage from '../../../../app/assets/images/decor/decor-2.jpg';
import { CoupleTopButtons } from '../Entity/CoupleTopButtons/CoupleTopButtons';

export const Top = ({ page = 'main' }) => {
  const { firstLine, secondLine } = contentList[page] || contentList.main;

  return (
    <div className={s.heading}>
      <div className={s.heading__container}>
        <div className={s.heading__wrap}>
          <h1 className={s.heading__caption}>{firstLine}</h1>
          <span className={s.heading__text}>{secondLine}</span>
          <CoupleTopButtons />
        </div>
        <div className={s.heading__image}>
          <picture>
            <source srcSet={HeaderBackgroundMobileJpg} media="(max-width: 500px)" type="image/jpeg" />
            <source srcSet={HeaderBackgroundTabletJpg} media="(min-width: 501px) and (max-width: 1023px)" type="image/jpeg" />
            <source srcSet={HeaderBackgroundDesktopJpg} media="(min-width: 1024px)" type="image/jpeg" />
            <img src={HeaderBackgroundMobileJpg} alt="Polza diet" />
          </picture>
        </div>
      </div>
      <img src={DecorImage} alt="Decor diet" className={cx(s.decor, 'lazy-landing')} />
    </div>
  );
};
