import React from "react"

export default class IncomingCalls extends React.Component {
  constructor(props) {
    super(props)
    this.state = { collection: gon.incoming_calls }
    this.renew = this.renew.bind(this)
  }

  componentDidMount() {
    gon.watch(
      "incoming_calls",
      { interval: 1000, url: this.props.refresh_url },
      this.renew,
    )
  }

  renew(collection) {
    this.setState({ collection: collection })
  }

  componentWillUnmount() {
    gon.unwatch("incoming_calls", this.renew)
  }

  renderCall({
    id,
    user_id,
    user_name,
    phone,
    operator,
    result,
    call_type,
    created_at,
  }) {
    let color
    if (result == "ANSWER") {
      color = "white"
    } else if (result == "PENDING") {
      color = "#fae57f"
    } else {
      color = "#fa6e83"
    }
    return (
      <tr key={id} style={{ backgroundColor: color }}>
        <td className="cell-data cell-data--string">
          <span className="action-show">{`+7${phone}`}</span>
        </td>
        <td className="cell-data cell-data--belongs-to">
          <a href={`/admin/users/${user_id}`}>{user_name || "Нет имени"}</a>
        </td>
        <td className="cell-data cell-data--date-time">{created_at}</td>
        <td className="cell-data cell-data--string">{call_type}</td>
        <td className="cell-data cell-data--string">{result}</td>
        <td className="cell-data cell-data--string">{operator}</td>
      </tr>
    )
  }

  render() {
    return (
      <table>
        <tbody>
          {this.state.collection.map((call) => this.renderCall(call))}
        </tbody>
      </table>
    )
  }
}
