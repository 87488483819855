import React from 'react'

export const VisaSvg = () => {
  return (
    <svg width="78" height="44" viewBox="0 0 78 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="78" height="44" rx="4" fill="#E4CEC7" fillOpacity="0.2" />
      <path d="M58.6399 15.2006H55.7661C54.8972 15.2006 54.2289 15.4679 53.8279 16.3367L48.3475 28.8346H52.2239C52.2239 28.8346 52.8922 27.1638 53.0259 26.7628C53.4269 26.7628 57.2364 26.7628 57.7711 26.7628C57.9048 27.2306 58.2389 28.7678 58.2389 28.7678H61.7143L58.6399 15.2006ZM54.0952 23.9558C54.4294 23.1538 55.5656 20.1463 55.5656 20.1463C55.5656 20.2131 55.8997 19.3443 56.0334 18.8764L56.3007 20.0794C56.3007 20.0794 57.0359 23.3543 57.1696 24.0226H54.0952V23.9558Z" fill="#3362AB" />
      <path d="M48.6149 24.3567C48.6149 27.1637 46.0752 29.0351 42.132 29.0351C40.4611 29.0351 38.8571 28.7009 37.9883 28.2999L38.523 25.2256L38.9908 25.4261C40.1938 25.9607 40.9958 26.1612 42.4662 26.1612C43.5355 26.1612 44.6717 25.7602 44.6717 24.8246C44.6717 24.2231 44.2038 23.8221 42.7335 23.1537C41.33 22.4854 39.4586 21.416 39.4586 19.4779C39.4586 16.8045 42.0652 15 45.741 15C47.1445 15 48.3475 15.2673 49.0827 15.6015L48.548 18.5422L48.2807 18.2749C47.6124 18.0075 46.7435 17.7402 45.4737 17.7402C44.0702 17.807 43.4018 18.4085 43.4018 18.9432C43.4018 19.5447 44.2038 20.0125 45.4737 20.614C47.6124 21.6165 48.6149 22.7527 48.6149 24.3567Z" fill="#3362AB" />
      <path d="M16 15.3341L16.0668 15.0668H21.8145C22.6165 15.0668 23.218 15.3341 23.4185 16.2029L24.6884 22.218C23.4185 19.01 20.4779 16.4034 16 15.3341Z" fill="#F9B50B" />
      <path d="M32.7753 15.2005L26.9607 28.7678H23.0175L19.6759 17.406C22.0819 18.9432 24.0869 21.3492 24.8221 23.0201L25.2231 24.4236L28.8321 15.1337H32.7753V15.2005Z" fill="#3362AB" />
      <path d="M34.3124 15.1337H37.9883L35.6491 28.7678H31.9733L34.3124 15.1337Z" fill="#3362AB" />
    </svg>
  )
}
