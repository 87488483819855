import React from 'react'

export const FourthStepSvg = ({ style }) => {
  return (
    <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107 71.97" className={style}>
      <defs>
        <style>
          {`
            .work-4-cls-1 {
              fill: #fff;
            }
            .work-4-cls-2 {
              fill: #ff6938;
            }
          `}
        </style>
      </defs>
      <title>work-4</title>
      <g id="Page-1">
        <g id="mainpage">
          <g id="Group-43">
            <g id="Group-42">
              <g id="food_1-line-4" data-name="food 1-line-4">
                <ellipse id="Oval" className="work-4-cls-1" cx="55" cy="37.65" rx="34" ry="33.5" />
                <path id="Path-3" className="work-4-cls-1" d="M12.2,64.06l0.53-35S17,24.89,17,22.83Q17,20.77,15.93.15H2.07L1,22.83,2.07,27,5.27,29v35l1.07,2.06,2.67,1,1.6-1Z" />
                <polygon id="Path-4" className="work-4-cls-1" points="103 67.15 106 64.06 106 39.32 106 0.15 101 1.18 96 5.3 95 11.49 95 35.2 98 39.32 99 39.32 99 53.75 99 65.09 103 67.15" />
                <path className="work-4-cls-2" d="M54.54,72A34.65,34.65,0,0,1,19.75,37.53a34.79,34.79,0,0,1,69.57,0A34.65,34.65,0,0,1,54.54,72Zm0-66.88A32.65,32.65,0,0,0,21.75,37.53a32.79,32.79,0,0,0,65.57,0A32.65,32.65,0,0,0,54.54,5.09Z" />
                <path className="work-4-cls-2" d="M54.54,57.57a20,20,0,1,1,20.23-20A20.16,20.16,0,0,1,54.54,57.57Zm0-38.07a18,18,0,1,0,18.23,18A18.16,18.16,0,0,0,54.54,19.49Z" />
                <path className="work-4-cls-2" d="M102.36,68.88a4.57,4.57,0,0,1-4.64-4.49V40.1a1,1,0,0,1,2,0V64.39a2.64,2.64,0,0,0,5.28,0V40.28a1,1,0,0,1,2,0V64.39A4.57,4.57,0,0,1,102.36,68.88Z" />
                <path className="work-4-cls-2" d="M8.28,18.46a1,1,0,0,1-1-1V1a1,1,0,1,1,2,0V17.46A1,1,0,0,1,8.28,18.46Z" />
                <path className="work-4-cls-2" d="M106,41.1h-5.63a6.85,6.85,0,0,1-4.8-1.93,6.59,6.59,0,0,1-2-4.7V12.1A12.28,12.28,0,0,1,106,0a1,1,0,0,1,1,1V40.1A1,1,0,0,1,106,41.1ZM105,2A10.24,10.24,0,0,0,95.56,12.1V34.47A4.52,4.52,0,0,0,97,37.73a4.93,4.93,0,0,0,3.41,1.37H105V2Z" />
                <path className="work-4-cls-2" d="M8.71,68.88a4.52,4.52,0,0,1-4.5-4.52V30.2A7.19,7.19,0,0,1,0,23.3L1.06,1a1,1,0,0,1,1-1H15.37a1,1,0,0,1,1,.95L17.59,23.3v0A7.19,7.19,0,0,1,13.22,30V64.36A4.52,4.52,0,0,1,8.71,68.88ZM3,2L2,23.4a5.17,5.17,0,0,0,3.52,5.16,1,1,0,0,1,.69.95V64.36a2.5,2.5,0,1,0,5,0v-35a1,1,0,0,1,.71-1,5.18,5.18,0,0,0,3.67-5L14.42,2H3Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}
