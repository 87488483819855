import React from 'react'

export const useCalculator = (
  {
    activeDiet,
    dietsOption,
    setActiveDiet,
    setPrice,
    city,
    dayPrices,
    prices,
    days,
    activeExtraOption,
    setActiveExtraOption,
    setExtraOptions,
    setDays,
    currentDiet,
    setCurrentDiet
  }) => {
  const dietTitle = activeDiet
  ? dietsOption.find(tab => tab.dietType === activeDiet.type).diets.find(d => d.diet === activeDiet.diet).title
  : null

  const selectDiet = ( type, diet ) => {
    let currentDietName = diet;
    if (type === 'gentle') {
      const [calories] = diet.split('_').reverse();
      const currentDietArray = currentDiet.split('_').slice(1);
      currentDietArray.unshift(type)
      currentDietName = currentDietArray.slice(0, -1).join('_') + '_' + calories;
    }
    setCurrentDiet(currentDietName);
    setPrice({
      day: dayPrices[currentDietName][city][days]["with_breakfast"],
      total: prices[currentDietName][city][days]["with_breakfast"]
    })
    setActiveDiet({ type, diet })
    const options = dietsOption.find(tab => tab.dietType === type).diets.find(d => d.diet === diet).options
    if (!options) {
      setExtraOptions(null)
      setActiveExtraOption(null)
      return
    }
    setExtraOptions([{ name: 'default', title: 'Базовое' }, ...options])
    activeExtraOption || setActiveExtraOption({ name: 'default', title: 'Базовое' })
  }

  const changeDays = ( days ) => {
    setDays(days)
    setPrice({
      day: dayPrices[currentDiet][city][days]["with_breakfast"],
      total: prices[currentDiet][city][days]["with_breakfast"]
    })
  }

  const selectExtraDiet = (extraDiet) => {
    setActiveExtraOption(extraDiet)
    const dietName = extraDiet.name === 'default' ? activeDiet.diet : extraDiet.name
    setCurrentDiet(dietName)
    setPrice({
      day: dayPrices[dietName][city][days]["with_breakfast"],
      total: prices[dietName][city][days]["with_breakfast"]
    })
  }

  return {
    dietTitle,
    selectDiet,
    changeDays,
    selectExtraDiet,
  }
}
