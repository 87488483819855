import React from 'react';
import s from './delivery.module.scss'
import { useScrollTo } from '../../../../hooks/useScrollTo';
import { DELIVERY } from '../../../../constants/scrollList';
import { useCityContext } from '../../../../context/CityContext';

export const Delivery = () => {
  const { city } = useCityContext();

  const [deliveryRef] = useScrollTo(DELIVERY, 'start');

  return (
    <div className={s.wrapper} id='delivery'>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
        <div ref={deliveryRef} className={s.block_title}>Бесплатная доставка</div>
      </div>

      <div className={s.content}>
        <div className={city === 'Moscow' ? s.map_msk : s.map_spb}/>

        <div className={s.text_block}>
          <div className={s.text}>Доставка осуществляется бесплатно, каждые 2 дня, с 18:00 до 23:00 в удобный для Вас двухчасовой
            интервал.
          </div>
          <div style={{display: 'flex', marginTop: '30px'}}>
            <div className={s.green}/>
            <div style={{ fontWeight: 600 }}>Бесплатно</div>
          </div>
          <div style={{display: 'flex', margin: '30px 0'}}>
            <div className={s.yellow}/>
            <div style={{ fontWeight: 600 }}>Уточните условия доставки</div>
          </div>
        </div>

      </div>
    </div>
  )
}
