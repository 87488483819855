import React from 'react'
import { OrderChange } from "../../../Widgets/OrderChange/OrderChange";
import s from './auth-popap.module.scss'
import { UserInformation } from "./UserInformation";
import { Order } from "./Order";
import { PhoneConfirmation1 } from "./PhoneConfirmation1";
import { PhoneConfirmation2 } from "./PhoneConfirmation2";
import {
  phoneConfirmation,
  retrySandPhone,
  sandPhone,
  updateUserInfo
} from "../../../../api/authPopup";
import { SuccessRequest, BadRequest } from "../../../Widgets/RequestComponents";
import { Modal } from "../../../Entity/modal/modal";
import { totalNumber } from "../../../../helpers/numberHelper";
import { LandingPageContext } from "../LandingContext/LandingProvider";
import { useCalculator, useOrderHook } from "../../../../hooks";
import { useCityContext } from '../../../../context/CityContext';
import { useAuthContext, AUTH_ACTION } from '../../../../context/AuthContext';

const { OPEN_MODAL_CALCULATOR, OPEN_MODAL_PREFOOTER, OPEN_MODAL_ONLY_LOGIN } = AUTH_ACTION;

export const AuthPopup = ({
  availableDeliveryDays,
  dadataApiKey,
  initialSettings,
  dietsOption,
  daysOption,
  prices,
  dayPrices,
}) => {
  const [open, setOpen] = React.useState(false)
  const [tabs, setTabs] = React.useState('Phone1')
  const [generalError, setGeneralError] = React.useState(null)

  const [activeDiet, setActiveDiet] = React.useState({ type: initialSettings.dietType, diet: initialSettings.diet })
  const [currentDiet, setCurrentDiet] = React.useState(initialSettings.diet)
  const [activeExtraOption, setActiveExtraOption] = React.useState(null)
  const [extraOptions, setExtraOptions] = React.useState(null)

  const { city } = useCityContext();
  const [days, setDays] = React.useState(initialSettings.days);
  const [price, setPrice] = React.useState({
    day: dayPrices[initialSettings.diet][initialSettings.city][initialSettings.days]["with_breakfast"],
    total: prices[initialSettings.diet][initialSettings.city][initialSettings.days]["with_breakfast"]
  });

  const [number, setNumber] = React.useState('+7')
  const [numberError, setNumberError] = React.useState(false)
  const [isShowV2, setIsShowV2] = React.useState(false)
  const [codeError, setCodeError] = React.useState(false)

  const [deliveryDay, setDeliveryDay] = React.useState({
    ...availableDeliveryDays[0],
    title: `${availableDeliveryDays[0].dateInterface} (${availableDeliveryDays[0].weekday})`
  })
  const [isCash, setIsCash] = React.useState(false)
  const [promo, setPromo] = React.useState({ code: '', isCurrent: false })
  const [promoError, setPromoError] = React.useState('')
  const [onlyLogin, setOnlyLogin] = React.useState(false)

  const {
    openAuthAction,
    setOpenAuthAction,
    authArguments,
    setAuthArguments,
  } = useAuthContext();

  const { user, setUser } = React.useContext(LandingPageContext);
  const { dietTitle, selectDiet, changeDays, selectExtraDiet } = useCalculator({
    daysOption,
    activeDiet,
    dietsOption,
    setActiveDiet,
    setPrice,
    city,
    dayPrices,
    prices,
    days,
    activeExtraOption,
    setActiveExtraOption,
    setExtraOptions,
    setDays,
    currentDiet,
    setCurrentDiet,
  })
  const { acceptOrder, checkPromo, isRecurrent, setIsRecurrent } = useOrderHook({
    onSuccess: () => setTabs('SuccessRequest'),
    onFail: (reason) => {
      setGeneralError(reason ? reason : 'Во время оплаты произошла ошибка')
      setTabs('BadRequest')
    },
    days,
    date: deliveryDay.date,
    dietName: () => {
      if (!activeDiet) return ''
      if (activeExtraOption) return activeExtraOption.name !== 'default' ? activeExtraOption.name : activeDiet.diet
      return activeDiet.diet
    },
    isCash,
    promo,
    onCompletePayment: () => setTabs('SuccessRequest'),
    onAcceptOrderFailed: (error) => commonError(error),

    city,
    onPromoSuccess: (newPrice) => {
      setPrice({ ...price, total: newPrice })
      setPromo({ ...promo, isCurrent: true })
    },
    onPromoFailed: (errorDetail, status) => {
      setPromo({ ...promo, isCurrent: false })
      setPromoError(errorDetail)
      commonError(status)
    },
  })

  const commonError = (error) => {
    if (error === 429) {
      setGeneralError('Слишком частые запросы')
      setTabs('BadRequest')
    }
    if (error === 500) {
      setGeneralError('Попробуйте позже')
      setTabs('BadRequest')
    }
  }

  const phoneAccept = (token, version) => {
    const currentNumber = totalNumber(number)

    if (currentNumber.length < 10) {
      setNumberError(true)
      return
    }

    sandPhone({
      phone: currentNumber,
      captcha_token: token,
      captcha_version: version
    })
      .then(() => setTabs('Phone2'))
      .catch(error => {
        commonError(error.response.data.errors.status)
        setIsShowV2(true)
      }
      )
  }
  const phoneConfirm = (code) => {
    phoneConfirmation({
      phone: totalNumber(number),
      code: code
    }).then(({ data }) => {
      setUser({
        isUserDataFilled: data.user_data.valid,
        isUserLogged: data.user_data.user_logged_in,
        phone: data.user_data.phone
      })
      if (onlyLogin && data.user_data.user_logged_in) {
        window.location.href = '/account';
        return
      }
      data.user_data.valid
        ? setTabs('Order')
        : setTabs('UserInformation')
    })
      .catch(error => {
        commonError(error.response.data.errors.status)
        setCodeError(true)
      }
      )
  }
  const refreshCode = () => {
    retrySandPhone({ phone: totalNumber(number) })
      .then(() => setCodeError(false))
      .catch(error => commonError(error.response.data.errors.status))
  }
  const updateUser = (data) => {
    updateUserInfo(data)
      .then(() => {
        setUser({ ...user, isUserDataFilled: true })
        setTabs('Order')
      }
      )
      .catch(error => commonError(error.response.data.errors.status))
  }

  React.useEffect(() => {
    if (openAuthAction) {
      setOpenAuthAction('');
      setAuthArguments({});

      if (openAuthAction === OPEN_MODAL_CALCULATOR) {
        const { days, activeDiet, activeExtraOption, price } = authArguments;
        setActiveDiet(activeDiet)
        setCurrentDiet(activeDiet.diet)
        setActiveExtraOption(activeExtraOption)
        setDays(days)
        setPrice(price)
        const options = dietsOption
          .find(d => d.dietType === activeDiet.type)
          .diets.find(d => d.diet === activeDiet.diet)
          .options;

        if (options) {
          setExtraOptions([{ name: 'default', title: 'Базовое' }, ...options])
        }
        setOpen(true)

        if (user.isUserLogged) {
          return user.isUserDataFilled
            ? setTabs('Order')
            : setTabs('UserInformation')
        }
        setTabs('Phone1')
      }
      if (openAuthAction === OPEN_MODAL_PREFOOTER) {
        const { number } = authArguments;
        setNumber(number)
        const options = dietsOption
          .find(d => d.dietType === initialSettings.dietType)
          .diets.find(d => d.diet === initialSettings.diet)
          .options

        if (options) {
          setExtraOptions([{ name: 'default', title: 'Базовое' }, ...options])
          setActiveExtraOption({ name: 'default', title: 'Базовое' })
        }

        setOpen(true)
        if (user.isUserLogged) return user.isUserDataFilled ? setTabs('Order') : setTabs('UserInformation')
        setTabs('Phone2')
      }
      if (openAuthAction === OPEN_MODAL_ONLY_LOGIN) {
        setOnlyLogin(true)
        setTabs('Phone1')
        setOpen(true)
      }
    }
  }, [openAuthAction])

  React.useEffect(() => {
    return () => {
      if (open) setOnlyLogin(false);
    }
  }, [open])

  const modals = {
    'Phone1': <PhoneConfirmation1
      setNumber={setNumber}
      number={number}
      phoneAccept={phoneAccept}
      isShowV2={isShowV2}
      setIsShowV2={setIsShowV2}
      numberError={numberError}
      setNumberError={setNumberError}
    />,
    'Phone2': <PhoneConfirmation2
      number={number}
      previousTab={() => setTabs('Phone1')}
      phoneConfirmation={phoneConfirm}
      refreshCode={refreshCode}
      codeError={codeError}
      setCodeError={setCodeError}
    />,
    'UserInformation': <UserInformation
      token={dadataApiKey}
      acceptRegistration={updateUser}
      city={city}
    />,
    'Order': <Order
      changeOrder={() => setTabs('OrderChange')}
      availableDeliveryDays={availableDeliveryDays}
      deliveryDay={deliveryDay}
      setDeliveryDay={setDeliveryDay}
      setPromo={setPromo}
      promo={promo}
      isCash={isCash}
      setIsCash={setIsCash}
      acceptOrder={acceptOrder}
      days={days}
      dietTitle={dietTitle}
      price={price}
      checkPromo={checkPromo}
      promoError={promoError}
      isRecurrent={isRecurrent}
      setIsRecurrent={setIsRecurrent}
    />,
    'OrderChange': <OrderChange
      extraOptions={extraOptions}
      dietsOption={dietsOption}
      selectDiet={selectDiet}
      selectExtraDiet={selectExtraDiet}
      setActiveExtraOption={setActiveExtraOption}
      activeExtraOption={activeExtraOption}
      activeDiet={activeDiet}

      setSliderDays={changeDays}
      daysOption={daysOption}
      days={days}

      dietTitle={dietTitle}
      price={price}
      previousTab={() => setTabs('Order')}
    />,
    'BadRequest': <BadRequest
      onOkay={() => {
        setOpen(false)
        setTabs('Phone1')
        setGeneralError(null)
      }}
      errorText={generalError ? generalError : ''}
    />,
    'SuccessRequest': <SuccessRequest
      onOkay={() => {
        setOpen(false)
        setTabs('Order')
      }}
    />
  }
  const titles = {
    'Phone1': 'Вход или регистрация',
    'Phone2': 'Вход или регистрация',
    'UserInformation': 'Регистрация',
    'Order': 'Заказ',
    'OrderChange': 'Заказ',
    'BadRequest': 'Заказ',
    'SuccessRequest': 'Заказ',
  }

  if (!open) return null

  return (
    <Modal onClose={() => {
      setOpen(false)
      setIsShowV2(false)
    }}>
      <div className={s.auth_popup_wrapper} data-target='auth-popup-wrapper'>
        <div className={s.popup_title} id='popup_title'>{titles[tabs]}</div>
        {!onlyLogin && (<div style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '2px',
          marginTop: '15px',
          marginBottom: '25px',
        }}>
          <div className={s.bread_active} />
          <div className={tabs !== 'Phone1' && tabs !== 'Phone2' ? s.bread_active : s.bread} />
          <div className={tabs !== 'Phone1' && tabs !== 'Phone2' && tabs !== 'UserInformation' ? s.bread_active : s.bread} />
        </div>)}
        {modals[tabs]}
      </div>
    </Modal>
  )
}
