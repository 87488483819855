import React from "react"
import axios from "axios"
import StarRatings from "react-star-ratings"

export default class UserReview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      food: 0,
      delivery: 0,
      package_errors: false,
      comment: "",
      alert: null,
      thanks: null,
      width: 0,
    }
    this.changeRating = this.changeRating.bind(this)
    this.handleChangeComment = this.handleChangeComment.bind(this)
    this.changePackageErrors = this.changePackageErrors.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    this.setState({
      width: window.innerWidth,
    })
  }

  changeRating(newRating, name) {
    this.setState({
      [name]: newRating,
      alert: null,
    })
  }

  changePackageErrors({ target }) {
    this.setState({ package_errors: target.checked })
  }

  handleChangeComment(event) {
    this.setState({ comment: event.target.value })
  }

  onSubmit() {
    let { food, delivery, comment, package_errors } = this.state
    if (food == 0 || delivery == 0) {
      this.setState({ alert: true })
    } else {
      axios
        .post("/user_reviews", {
          food: food,
          delivery: delivery,
          order_token: this.props.order_token,
          comment: comment,
          package_errors: package_errors
        })
        .then(
          function (response) {
            this.setState({ thanks: true })
          }.bind(this),
        )
    }
  }

  renderThanks() {
    return (
      <div className="visible-block">
        <div className="visible-block__wrap">
          <div className="visible-block__inner">
            <div className="visible-block__form">
              <span className="visible-block__label">
                {" "}
                Спасибо вам за ваш отзыв!{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderRatingForm() {
    const starWidth = this.state.width < 425 ? "30px" : "50px"
    let alert = (
      <div className="alert alert-warning" role="alert">
        Оцените качество еды и доставки
      </div>
    )
    return (
      <div className="visible-block">
        <div className="visible-block__wrap">
          <div className="visible-block__inner">
            <div className="visible-block__form">
              {this.state.alert ? alert : null}
              <span className="visible-block__label">
                {" "}
                Качество еды от 1 до 5
              </span>
              <div className="visible-block__input-wrap">
                <StarRatings
                  rating={this.state.food}
                  starRatedColor="#ff6938"
                  changeRating={this.changeRating}
                  numberOfStars={5}
                  starDimension={'20%'}
                  name="food"
                  starDimension={starWidth}
                />
              </div>
              <span className="visible-block__label"> Доставка от 1 до 5</span>
              <div className="visible-block__input-wrap">
                <StarRatings
                  rating={this.state.delivery}
                  starRatedColor="#ff6938"
                  changeRating={this.changeRating}
                  numberOfStars={5}
                  name="delivery"
                  starDimension={starWidth}
                />
              </div>
              <span className="visible-block__label"> Комплектация</span>
              <div className="visible-block__input-wrap">
                <input type="checkbox" style={ {'-webkit-appearance': 'checkbox', width: '30px', height: '30px', display: 'inline-block' } }  checked={this.state.package_errors} onChange={this.changePackageErrors} />
                <span style={ {'display': 'inline-block', 'font-size': '15px', 'vertical-align': 'top', 'width': '200px', 'text-align': 'left', 'margin-left': '10px' } }> У меня случались ошибки комлпектации (не хватало приемов или попадались повторные, доставка не соотвестовала меню) </span>
              </div>
              <span className="visible-block__label">
                {" "}
                Комментарий (не обязательно){" "}
              </span>
              <div className="visible-block__input-wrap">
                <textarea
                  value={this.state.сomment}
                  onChange={this.handleChangeComment}
                  style={{ height: "120px", lineHeight: "30px" }}
                />
              </div>
              <div className="visible-block__btn-wrap">
                <button className="btn" type="submit" onClick={this.onSubmit}>
                  {" "}
                  Отправить{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.thanks) {
      return this.renderThanks()
    } else {
      return this.renderRatingForm()
    }
  }
}
