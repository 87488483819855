import React from "react";
import s from './auth-popap.module.scss'
import { SelectedDiet } from "./components/SelectedDiet";
import { PromoInput } from "./components/PromoInput";
import { PencilIcon, CardIcon, CashIcon } from "../../../icons/AuthPopupIcons";
import { Dropdown } from "../../../Entity/Selects";

export const Order = ( {
  changeOrder,
  availableDeliveryDays,
  deliveryDay,
  setDeliveryDay,
  promo,
  setPromo,
  acceptOrder,
  days,
  dietTitle,
  price,
  checkPromo,
  promoError,
  setIsCash,
  isCash,
  isRecurrent,
  setIsRecurrent,
} ) => {
  const [isPromo, setIsPromo] = React.useState(false)
  const checkboxes = [
    {
      icon: <CardIcon/>,
      style: { display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between' },
      onClick: () => setIsCash(false),
      label: 'Оплата картой',
      isChecked: !isCash,
    },
    {
      icon: <CashIcon/>,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        cursor: 'pointer',
        justifyContent: 'space-between'
      },
      onClick: () => setIsCash(true),
      label: 'Оплата наличными',
      isChecked: isCash,
    },
  ]

  const recurrentHandler = () => {
    if (!isCash) {
      setIsRecurrent(!isRecurrent)
    }
  }

  return (
    <div className={ s.order2 }>
      <div>
        <div style={ { position: 'relative', margin: '10px 0' } }>
          <SelectedDiet
            days={ days }
            dietTitle={ dietTitle }
            price={ price }
          />
          <div
            id='change_promo_btn'
            className={ s.changeOrderIcon }
            onClick={ changeOrder }
          >
            <PencilIcon/>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <Dropdown
            title={ 'Дата первой доставки' }
            options={ availableDeliveryDays.map(item => ({
              ...item,
              title: `${ item.dateInterface } (${ item.weekday })`
            })) }
            activeValue={ deliveryDay }
            satActiveValue={ setDeliveryDay }
            optionsTitle={ 'title' }
          />
        </div>

        { checkboxes.map(( checkbox, idx ) => (
          <div
            style={ {
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              cursor: 'pointer',
              justifyContent: 'space-between'
            } }
            onClick={ checkbox.onClick }
            key={ idx }
          >
            <div style={ { display: 'flex' } }>
              { checkbox.icon }
              <div className={ s.text }>{ checkbox.label }</div>
            </div>
            <input
              checked={ checkbox.isChecked }
              onChange={ () => { } }
              className={ s.modalCheckbox }
              type='checkbox'
            />
          </div>
        )) }

        <div
          className={ s.title2 }
          style={ { cursor: 'pointer', color: '#FF6938', margin: '20px 0' } }
          onClick={ () => setIsPromo(!isPromo) }
        >
          { isPromo ? 'Свернуть' : 'У меня есть промокод' }
        </div>

        <div className={s.title2}>
          <div className={s.recurrentContainer}
            onClick={recurrentHandler}
            style={{ cursor: isCash ? 'default' : 'pointer' }}
          >
            <span>Оформить подписку</span>
            <input
              checked={ isRecurrent && !isCash }
              onChange={ () => { } }
              className={ s.recurrentCheckbox }
              type='checkbox'
              disabled={isCash}
            />
          </div>
        </div>

        { isPromo &&
          <PromoInput
            promoError={ promoError }
            setPromo={ setPromo }
            checkPromo={ checkPromo }
            promo={ promo }
          />
        }
      </div>

      <div style={ { marginTop: '20px' } }>
        <button
          className="makeorder"
          data-target="place_order"
          onClick={ acceptOrder }
        >
          Оформить заказ
        </button>
      </div>
    </div>
  )
}
