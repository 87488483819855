const getCommonDescription = (mainText) => {
  return `
    Мы разработали щадящую диету, рекомендованную ${mainText}. 
    Скудный список стандартных блюд был переосмыслен, и, благодаря фантазии шеф-повара, 
    диета получилась не только полезной, но так же вкусной и разнообразной.
  `
}

const getTableDescription = (num) => {
  return `
    Мы разработали эту линейку питания в соответствии с диетой Стол${"\u00a0"}№${num}. 
    Скудный список стандартных блюд был переосмыслен, и, благодаря фантазии шеф-повара, 
    диета получилась не только полезной, но так же вкусной и разнообразной. 
    Она подойдет при определенных показаниях, таких как:
  `
}

export const dietitianList = Object.freeze({
  main: {
    text: `
      Медицинский консультант нашего проекта. Помогла нам разработать 
      щадящую диету, а так же диету, рекомендованную при Диабете 2 типа.
    `,
    diagnoses: false,
  },
  stol1: {
    text: getTableDescription(1),
    diagnoses: true,
  },
  stol5: {
    text: getTableDescription(5),
    diagnoses: true,
  },
  stol9: {
    text: `
      Мы разработали эту линейку питания в соответствии с диетой Стол${"\u00a0"}№9. 
      Она способствует нормализации углеводного обмена и предупреждает нарушения жирового обмена
    `,
    diagnoses: false,
  },
  diabet: {
    text: `
      Мы разработали эту линейку питания в соответствии с диетой Стол${"\u00a0"}№9, 
      рекомендованной при Диабете 2 типа. Она способствует нормализации углеводного обмена и 
      предупреждает нарушения жирового обмена.
    `,
    diagnoses: false,
  },
  holetsistit: {
    text: getCommonDescription("при хроническом холецистите"),
    diagnoses: false,
  },
  duodenit: {
    text: getCommonDescription("при хроническом дуодените"),
    diagnoses: false,
  },
  appendicit: {
    text: getCommonDescription("после удаления аппендицита"),
    diagnoses: false,
  },
  refluks: {
    text: getCommonDescription("при рефлюкс-эзофагите"),
    diagnoses: false,
  },
  yazva: {
    text: getCommonDescription("при хронической язве"),
    diagnoses: false,
  },
  gastrit: {
    text: getCommonDescription("при гастрите"),
    diagnoses: false,
  },
  udalenie_zhelchnogo_puzyrya: {
    text: getCommonDescription("после удаления желчного пузыря"),
    diagnoses: false,
  },
  pankreatit: {
    text: getCommonDescription("при хроническом панкреатите"),
    diagnoses: false,
  },
})
