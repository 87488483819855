import React from 'react'

export const ThirdStepSvg = ({ style }) => {
  return (
    <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 68" className={style}>
      <defs>
        <style>
          {`
            .work-3-cls-1 {
              fill: #fff;
            }
            .work-3-cls-2 {
              fill: #ff6938;
            }
          `}
        </style>
      </defs>
      <title>work-3</title>
      <g id="Page-1">
        <g id="mainpage">
          <g id="Group-41">
            <g id="Group-40">
              <g id="e-commerce_2-line-61" data-name="e-commerce 2-line-61">
                <path id="Path-2" className="work-3-cls-1" d="M8,54L22.47,1,77,2,74.05,16H84.37L95,31.35,104,36V53.94l-3.09,1H85Q81.22,67,74,67t-8-7.35l1-4.71H38q-3.75,11-12,12t-6-12Z" />
                <path className="work-3-cls-2" d="M100.14,56H84.71V54h15.43c2.16,0,2.86-.69,2.86-2.86V36.33L96.47,33,89,36.71H68l0.31-1.24c0.26-1.06,6.38-25.88,7.14-28.75,0.38-1.41.73-3.14,0.09-4A3,3,0,0,0,73.14,2H23V0H73.14a4.75,4.75,0,0,1,4,1.53c1.16,1.51.81,3.63,0.25,5.7-0.66,2.49-5.55,22.29-6.83,27.48h18l8-4L105,35.1v16C105,54.41,103.41,56,100.14,56Z" />
                <rect className="work-3-cls-2" x="8" y="54" width="13" height="2" />
                <rect className="work-3-cls-2" x="39" y="54" width="28" height="2" />
                <polygon className="work-3-cls-2" points="95.19 32.59 83.82 17 74 17 74 15 84.84 15 96.81 31.41 95.19 32.59" />
                <rect className="work-3-cls-2" x="8" y="16" width="42" height="2" />
                <rect className="work-3-cls-2" y="31" width="42" height="2" />
                <path className="work-3-cls-2" d="M25.91,68a7.71,7.71,0,0,1-6.39-2.91,8.79,8.79,0,0,1-1.07-7.87A14.11,14.11,0,0,1,31.1,47h0a7.67,7.67,0,0,1,6.35,2.95,8.73,8.73,0,0,1,1.12,7.83A14.11,14.11,0,0,1,25.92,68h0Zm5.25-19a12.09,12.09,0,0,0-10.79,8.77,6.85,6.85,0,0,0,.75,6.11A5.73,5.73,0,0,0,25.85,66a12.09,12.09,0,0,0,10.79-8.77h0a6.79,6.79,0,0,0-.79-6.07A5.69,5.69,0,0,0,31.16,49Z" />
                <path className="work-3-cls-2" d="M72.91,68a7.71,7.71,0,0,1-6.39-2.91,8.79,8.79,0,0,1-1.07-7.87A14.11,14.11,0,0,1,78.1,47h0a7.67,7.67,0,0,1,6.35,2.95,8.73,8.73,0,0,1,1.12,7.83A14.11,14.11,0,0,1,72.92,68h0Zm5.25-19a12.09,12.09,0,0,0-10.79,8.77,6.85,6.85,0,0,0,.75,6.11A5.73,5.73,0,0,0,72.85,66a12.09,12.09,0,0,0,10.79-8.77h0a6.79,6.79,0,0,0-.79-6.07A5.69,5.69,0,0,0,78.16,49Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}
