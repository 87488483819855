import React from "react";
import s from './dropdowns.module.scss'
import { ArrowIcon } from "../../icons/AuthPopupIcons";

export const Autocomplete = ( { value, disabled, error, placeholder, onSelect, optionsList, onInputChange } ) => {
  const [isAutocomplete, setIsAutocomplete] = React.useState(false)

  React.useEffect(() => {
    const hideModal = ( event ) => {
      const isItAutocomplete = !!(event.target).closest(`[data-modal=${ placeholder }]`);
      !isItAutocomplete && setIsAutocomplete(false);
    }
    document.addEventListener('click', hideModal);
    return () => document.removeEventListener('click', hideModal);
  }, [isAutocomplete]);

  const chooseItem = ( value ) => {
    setIsAutocomplete(false)
    onSelect(value)
  }

  return (
    <div style={ { position: 'relative' } }>
      <input
        value={ value }
        onChange={ e => onInputChange(e.target.value) }
        data-modal={ placeholder }
        className={ s.input + ' ' + (error ? s.error : '') }
        placeholder={ placeholder }
        disabled={ disabled }
        onFocus={ () => setIsAutocomplete(true) }
      />
      { isAutocomplete && optionsList.length
        ? <div className={ s.menu }>
            { optionsList.map(( item, idx ) =>
              <div
                key={ idx }
                className={ s.menuItem + ' ' + s.text }
                style={ { marginLeft: 0 } }
                onClick={ () => chooseItem(item) }
              >
                { item }
              </div>
            ) }
          </div>
        : null
      }
      <div className={ s.arrow_wrapper } style={ { transform: (isAutocomplete ? 'rotate(180deg)' : '') } }>
        <ArrowIcon/>
      </div>
    </div>
  )
}
