import React from 'react'
import { VK_GROUP } from '../../constants/links'

export const VKontakteSvg = () => {
  return (
    <a href={VK_GROUP} target="_blank">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_430_210)">
          <path fillRule="evenodd" clipRule="evenodd" d="M13.8667 0C25.0667 0 14.9333 0 26.1333 0C37.3333 0 40 2.66667 40 13.8667C40 25.0667 40 14.9333 40 26.1333C40 37.3333 37.3333 40 26.1333 40C14.9333 40 25.0667 40 13.8667 40C2.66667 40 0 37.3333 0 26.1333C0 14.9333 0 20.1209 0 13.8667C0 2.66667 2.66667 0 13.8667 0Z" fill="#E4CEC7" fillOpacity="0.2" />
          <path fillRule="evenodd" clipRule="evenodd" d="M32.7571 13.7805C32.9424 13.1625 32.7571 12.7084 31.875 12.7084H28.9584C28.2168 12.7084 27.8749 13.1007 27.6894 13.5332C27.6894 13.5332 26.2062 17.1486 24.105 19.497C23.4253 20.1767 23.1162 20.393 22.7454 20.393C22.56 20.393 22.2917 20.1767 22.2917 19.5588V13.7805C22.2917 13.0389 22.0764 12.7084 21.4584 12.7084H16.875C16.4116 12.7084 16.1329 13.0526 16.1329 13.3788C16.1329 14.0818 17.1834 14.2439 17.2917 16.2216V20.5167C17.2917 21.4584 17.1217 21.6291 16.7508 21.6291C15.7621 21.6291 13.3569 17.9976 11.9304 13.8423C11.6509 13.0346 11.3705 12.7084 10.6251 12.7084H7.70838C6.87505 12.7084 6.70837 13.1007 6.70837 13.5332C6.70837 14.3058 7.69719 18.1374 11.3125 23.205C13.7226 26.6657 17.1184 28.5417 20.2084 28.5417C22.0624 28.5417 22.2917 28.125 22.2917 27.4073V24.7917C22.2917 23.9584 22.4674 23.7921 23.0544 23.7921C23.487 23.7921 24.2286 24.0084 25.9591 25.677C27.9367 27.6545 28.2627 28.5417 29.375 28.5417H32.2917C33.125 28.5417 33.5417 28.125 33.3013 27.3028C33.0383 26.4833 32.0941 25.2942 30.8413 23.8848C30.1614 23.0814 29.1418 22.2162 28.8327 21.7835C28.4001 21.2274 28.5237 20.9801 28.8327 20.4858C28.8327 20.4858 32.3863 15.48 32.7571 13.7805Z" fill="#5181B8" />
        </g>
        <defs>
          <clipPath id="clip0_430_210">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  )
}
