import React from 'react'
import { OK_GROUP } from '../../constants/links'

export const OdnoklassnikiSvg = () => {
  return (
    <a href={OK_GROUP} target="_blank">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_430_226)">
          <path fillRule="evenodd" clipRule="evenodd" d="M13.8667 0C25.0667 0 14.9333 0 26.1333 0C37.3333 0 40 2.66667 40 13.8667C40 25.0667 40 14.9333 40 26.1333C40 37.3333 37.3333 40 26.1333 40C14.9333 40 25.0667 40 13.8667 40C2.66667 40 0 37.3333 0 26.1333C0 14.9333 0 20.1209 0 13.8667C0 2.66667 2.66667 0 13.8667 0Z" fill="#E4CEC7" fillOpacity="0.2" />
          <g clipPath="url(#clip1_430_226)">
            <path d="M20.5097 20.381C24.2055 20.3798 27.199 17.3827 27.1979 13.6881C27.1967 9.99237 24.1995 6.99881 20.505 7C16.8104 7.00119 13.8156 9.99833 13.8168 13.6929C13.8216 17.3863 16.8164 20.3786 20.5097 20.381ZM20.5097 10.9223C22.0405 10.9223 23.2815 12.1633 23.2815 13.6941C23.2815 15.2248 22.0405 16.4647 20.5097 16.4647C18.979 16.4647 17.7379 15.2237 17.7379 13.6929C17.7403 12.1633 18.9802 10.9235 20.5097 10.9223ZM23.2148 25.84C24.5917 25.5277 25.9055 24.9828 27.1013 24.2306C28.0121 23.6428 28.2744 22.4292 27.6866 21.5184C27.1084 20.6218 25.9186 20.3512 25.0102 20.9103C22.2551 22.633 18.7572 22.633 16.0021 20.9103C15.0877 20.3369 13.88 20.6111 13.3042 21.5243C12.726 22.4387 12.9978 23.65 13.9134 24.2282C13.9146 24.2282 13.9158 24.2294 13.9158 24.2294C15.1103 24.9804 16.4241 25.5253 17.7999 25.8388L14.0588 29.5799C13.3137 30.3667 13.3483 31.609 14.1363 32.3541C14.8934 33.0694 16.076 33.0694 16.833 32.3541L20.5073 28.6774L24.184 32.3541C24.9494 33.1183 26.1881 33.1183 26.9535 32.3541C27.7176 31.5887 27.7176 30.35 26.9535 29.5846L23.2148 25.84Z" fill="#EB722E" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_430_226">
            <rect width="40" height="40" fill="white" />
          </clipPath>
          <clipPath id="clip1_430_226">
            <rect width="15" height="25.9263" fill="white" transform="translate(13 7)" />
          </clipPath>
        </defs>
      </svg>
    </a>
  )
}
