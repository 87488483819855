import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import s from './modal.module.scss'

export const Modal = ({children, onClose}) => {
  const modalRoot = typeof document !== "undefined" && document.getElementById("modals");

  useEffect(() => {
    const closeOnEscape = e => e.key === "Escape" ? onClose && onClose() : null
    document.body.classList.add('no-scroll', 'compensate');
    document.body.addEventListener("keydown", closeOnEscape);

    return () => {
      document.body.classList.remove('no-scroll', 'compensate');
      document.body.removeEventListener("keydown", closeOnEscape);
    }
  }, [onClose]);

  return createPortal(
    <>
      <div className={`${s.popup}`}>
        <button className={s.close} onClick={onClose}>Close</button>
        <div className={s.popup_body}>
          {children}
        </div>
      </div>
      <div className={s.popup_overlay} onClick={onClose}></div>
    </>,
    modalRoot
  );
}
