import React from'react';
import styles from './dietpicker.module.scss';
import { SimpleSelect } from "../../Entity/Selects";

export const DietPicker = (
  {
    dietsOption,
    extraOptions,
    activeExtraOption,
    activeDiet,
    selectDiet,
    selectExtraDiet
  }) => {
  if(!activeDiet) return <></>
  return (
    <>
      <div className={ styles.options_wrapper }>
        { dietsOption.length > 1 && dietsOption.map(( tab, idx ) => (
          <div
            className={ `
              diet_option
              ${ styles.options_button }
              ${ activeDiet.type === tab.dietType ? styles.options_button_active : '' }
              ${ styles.title3 }
            ` }
            data-target="diet_option"
            key={ idx }
            onClick={ () => selectDiet(tab.dietType, tab.diets[0].diet) }
          >
            { tab.dietTitle }
          </div>
        )) }
      </div>

      <div className={ styles.diets_wrapper }>
        { dietsOption.find(tab => tab.dietType === activeDiet.type).diets.map(( diet, index ) => (
          <div
            data-target={ diet.diet }
            key={ index }
            className={ 'size_option' + ' ' + styles.diet_wrapper + ' ' + (diet.diet === activeDiet.diet ? styles.diet_wrapper_active : '') }
            onClick={ () => selectDiet(activeDiet.type, diet.diet) }
          >
            <span
              className={ `${styles.subtitle1} ${styles.button_text2}` }
              style={ { ...(diet.diet === activeDiet.diet && { color: '#FF6938' }) } }
            >
              { diet.humanName.first }
            </span>
            <span
              className={ styles.subtitle2 }
              style={ { ...(diet.diet === activeDiet.diet && { color: '#FF6938' }) } }
            >
              { diet.humanName.second }
            </span>
          </div>
        )) }
      </div>

      { extraOptions && activeExtraOption &&
        <div style={ { marginTop: 20 } }>
          <SimpleSelect
            title='Дополнительные опции'
            activeValue={ activeExtraOption }
            options={ extraOptions }
            onSelect={ selectExtraDiet }
            optionsTitle={ 'title' }
          />
        </div>
      }
    </>
  )
}
