import React, { useState } from "react"
import AdminAddress from "./adminAddress"
import uniqueId from "lodash/uniqueId"

const AdminAddressesForm = (props) => {
  const [addresses, setAddresses] = useState(props.addresses)

  const onAddClick = (event) => {
    event.preventDefault()
    setAddresses([...addresses, { temp_id: uniqueId('new_address-') }])
  }

  const onRemoveClick = (event, id) => {
    event.preventDefault()
    setAddresses([...addresses.filter(address => {
      if (address.temp_id && address.temp_id === id) return 
      return address
    })])
  }

  return (
    <>
      {addresses.map((address, i) => {
        return (
          <div key={address.id || address.temp_id}>
            <AdminAddress
              address={address}
              dadata_api_key={props.dadata_api_key}
              city={props.city}
              cities={props.cities}
              index={i}
            />
            {address.temp_id && <button onClick={(event) => onRemoveClick(event, address.temp_id)}>Delete new address</button>} 
          </div>
        )
      })}
      <button onClick={onAddClick}>Add new address</button>
    </>
  )
}

export default AdminAddressesForm
