import React from 'react'

export const LogoSvg = ({ style }) => {
  return (
    <svg className={style} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="51" height="51" rx="25.5" fill="#FF6938" />
      <g clipPath="url(#clip0_406_1816)">
        <path d="M28.0627 13.7337C28.2476 14.2427 28.2776 14.7952 28.1489 15.3213C28.0202 15.8474 27.7386 16.3236 27.3396 16.6898C26.9407 17.0561 26.4421 17.296 25.907 17.3793C25.3718 17.4626 24.824 17.3856 24.3325 17.1579C23.8411 16.9302 23.4281 16.5621 23.1456 16.1C22.8631 15.6379 22.7238 15.1025 22.7452 14.5614C22.7666 14.0202 22.9478 13.4974 23.266 13.0591C23.5841 12.6208 24.0249 12.2865 24.5328 12.0983C24.8718 11.9728 25.2324 11.9155 25.5937 11.9298C25.955 11.9441 26.3099 12.0297 26.6379 12.1817C26.966 12.3336 27.2608 12.549 27.5053 12.8154C27.7498 13.0817 27.9392 13.3938 28.0627 13.7337Z" fill="white" />
        <path d="M35.6709 10.8917L35.5912 10.8219C35.4931 10.732 35.378 10.6625 35.2527 10.6177C35.1273 10.5729 34.9943 10.5537 34.8614 10.5611C34.7285 10.5686 34.5984 10.6025 34.4789 10.661C34.3593 10.7195 34.2527 10.8013 34.1652 10.9017L27.6838 18.151H23.3262L16.8447 10.9017C16.7573 10.8013 16.6507 10.7195 16.5311 10.661C16.4115 10.6025 16.2815 10.5686 16.1486 10.5611C16.0157 10.5537 15.8827 10.5729 15.7573 10.6177C15.632 10.6625 15.5169 10.732 15.4188 10.8219L15.339 10.8917C15.2387 10.9792 15.1568 11.0858 15.0984 11.2054C15.0399 11.3249 15.0059 11.455 14.9985 11.5879C14.991 11.7208 15.0103 11.8538 15.0551 11.9791C15.0999 12.1045 15.1693 12.2196 15.2593 12.3177L21.9801 19.8661L22.0897 19.9658V38.9117C22.0897 39.3269 22.2547 39.7251 22.5483 40.0187C22.8419 40.3123 23.2401 40.4772 23.6553 40.4772C24.0705 40.4772 24.4687 40.3123 24.7623 40.0187C25.0559 39.7251 25.2208 39.3269 25.2208 38.9117V27.9729H25.7792V38.8718C25.7792 39.287 25.9441 39.6852 26.2377 39.9788C26.5313 40.2724 26.9295 40.4373 27.3447 40.4373C27.7599 40.4373 28.1581 40.2724 28.4517 39.9788C28.7453 39.6852 28.9103 39.287 28.9103 38.8718V19.9259L35.661 12.3675C35.7644 12.2748 35.8474 12.1614 35.9044 12.0347C35.9615 11.908 35.9915 11.7708 35.9924 11.6318C35.9934 11.4929 35.9653 11.3553 35.9099 11.2278C35.8545 11.1004 35.7731 10.9859 35.6709 10.8917Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_406_1816">
          <rect width="21" height="30" fill="white" transform="translate(15 10.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
