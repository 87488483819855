import React from "react";

export const ClockIcon = () => (
  <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23.0004" cy="22.043" r="20" transform="rotate(6.2 23.0004 22.043)" fill="#FF6938"/>
    <g clipPath="url(#clip0_793_1040)">
      <path d="M26.5634 23.7661L23.7748 21.6747L23.7748 17.4144C23.7748 16.9861 23.4286 16.6398 23.0002 16.6398C22.5718 16.6398 22.2256 16.9861 22.2256 17.4144L22.2256 22.062C22.2256 22.306 22.3402 22.5361 22.5354 22.6817L25.6338 25.0055C25.7732 25.1101 25.9359 25.1604 26.0978 25.1604C26.334 25.1604 26.5664 25.0543 26.7182 24.8498C26.9755 24.5082 26.9057 24.0225 26.5634 23.7661Z" fill="white"/>
      <path d="M23 12C17.4857 12 13 16.4857 13 22C13 27.5143 17.4857 32 23 32C28.5143 32 33 27.5143 33 22C33 16.4857 28.5143 12 23 12ZM23 30.4508C18.3408 30.4508 14.5492 26.6592 14.5492 22C14.5492 17.3408 18.3408 13.5492 23 13.5492C27.66 13.5492 31.4508 17.3408 31.4508 22C31.4508 26.6592 27.6592 30.4508 23 30.4508Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_793_1040">
        <rect width="20" height="20" fill="white" transform="translate(13 12)"/>
      </clipPath>
    </defs>
  </svg>
)
