import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Popup from 'reactjs-popup';
import s from './header.module.scss';

export const CityPopup = ({ cityData, cities, open, onClose, setCity }) => {
  const popupRoot = typeof document !== "undefined" && document.getElementById("popups");

  const [offset, setOffset] = useState(0);
  const popupWidth = 300;

  useEffect(() => {
    const selectCityElement = document.getElementById('citySelect');
    const { left, width } = selectCityElement.getBoundingClientRect();
    setOffset(left + (width - popupWidth) / 2);
  }, [open]);

  const { idToCityNameDef, list } = cities;
  const otherCity = list.find((elem) => elem.id !== cityData.id);

  return createPortal(
    <Popup
      open={open}
      contentStyle={{
        borderRadius: '10px',
        padding: '10px',
        margin: 0,
        left: `${offset}px`,
        top: '70px',
        width: `${popupWidth}px`,
        height: '140px',
      }}
      onClose={onClose}
    >
      <div>
        <div className={s.header__city_select__qstn}>
          {' '}
          Вы находитесь в <b>{idToCityNameDef[cityData.id]}</b>?{' '}
        </div>
        <div className={s.header__city_select__answr}>
          <a
            className={s.header__city_select__answr_opt}
            data-target="city"
            onClick={() => {
              setCity(cityData.id);
            }}
          >
            {' '}
            Да, я в {idToCityNameDef[cityData.id]}{' '}
          </a>
          <a
            className={s.header__city_select__answr_opt}
            onClick={() => {
              setCity(otherCity.id);
            }}
          >
            {' '}
            Нет, я в {idToCityNameDef[otherCity.id]}{' '}
          </a>
        </div>
      </div>
    </Popup>,
    popupRoot
  );
};
