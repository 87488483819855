import React from "react";

export const SnowIcon = () => (
  <svg width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" >

    <path d="M0 0h48v48H0z" fill="none"/>
    <g id="Shopicon">
      <polygon points="40.32,12.269 37.061,14.15 36.853,10.685 32.86,10.925 33.188,16.386 26,20.536 26,12.237 30.895,9.79
		29.105,6.212 26,7.765 26,4.001 22,4.001 22,7.765 18.895,6.212 17.105,9.79 22,12.237 22,20.536 14.812,16.386 15.14,10.925
		11.147,10.685 10.939,14.15 7.68,12.269 5.68,15.733 8.939,17.615 6.041,19.528 8.246,22.866 12.812,19.851 19.999,24.001
		12.812,28.151 8.246,25.136 6.041,28.474 8.939,30.387 5.68,32.269 7.68,35.733 10.939,33.852 11.147,37.317 15.14,37.077
		14.812,31.616 22,27.465 22,35.765 17.105,38.212 18.895,41.79 22,40.237 22,44.001 26,44.001 26,40.237 29.105,41.79
		30.895,38.212 26,35.765 26,27.465 33.188,31.616 32.86,37.077 36.853,37.317 37.061,33.852 40.32,35.733 42.32,32.269
		39.061,30.387 41.959,28.474 39.754,25.136 35.188,28.151 28.001,24.001 35.188,19.851 39.754,22.866 41.959,19.528 39.061,17.615
		42.32,15.733 	"/>
    </g>
  </svg>
)
