import React, { useCallback } from "react";
import s from "../auth-popap.module.scss";
import { ClockIcon2 } from "../../../../icons/AuthPopupIcons";

export const ResendCountdown = ({ refreshCode }) => {
  const [disabled, setDisabled] = React.useState(true)
  const [counter, setCounter] = React.useState(30)

  const timer = useCallback(() => {
    setTimeout(() => {
      setCounter(count => {
        if (count === 0) {
          setDisabled(false)
          return count
        }
        timer()
        return count - 1
      })
    }, 1000)
  }, [setDisabled, setCounter])

  React.useEffect(() => timer(), [timer])

  const sandAgain = () => {
    if (disabled) return
    setCounter(30)
    timer()
    refreshCode()
    setDisabled(true)
  }

  return (
    <div>
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
        <ClockIcon2 />
        <div className={s.phone_retry}>
          {`Отправить код повторно через 0:${counter > 9 ? counter : `0${counter}`}`}
        </div>
      </div>
      <button
        className={s.disabled_button}
        onClick={sandAgain}
        disabled={disabled}
      >
        Отправить повторно
      </button>
    </div>
  )
}
