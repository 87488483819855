import React from "react"
import EmailCollectPopup from "./emailCollectPopup"

export default class EmailCollectAccount extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <EmailCollectPopup {...this.props}>
        <h4>
          {" "}
          Хотите получать полезные статьи про здоровье и советы от нашего
          диетолога, информацию о акциях и многое другое?{" "}
        </h4>
        <div className="email-popup__discount">
          За подписку дарим промокод на{" "}
          <span className="email-popup__discount--bold">скидку 200<span className="rub">руб.</span></span>
        </div>
      </EmailCollectPopup>
    )
  }
}
