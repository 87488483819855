import React from 'react'

export const MirSvg = () => {
  return (
    <svg width="78" height="44" viewBox="0 0 78 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="78" height="44" rx="4" fill="#E4CEC7" fillOpacity="0.2" />
      <path d="M59.6542 14H49.643C50.2262 17.3047 53.4337 20.415 56.9327 20.415H64.9028C65 20.1234 65 19.6374 65 19.3458C65 16.4299 62.5701 14 59.6542 14Z" fill="url(#paint0_linear_407_2374)" />
      <path d="M50.5178 20.998V29.0653H55.3776V24.7887H59.6542C61.9869 24.7887 64.028 23.1364 64.7084 20.998H50.5178Z" fill="#4FAD50" />
      <path d="M33.4112 14V28.9682H37.6879C37.6879 28.9682 38.757 28.9682 39.3402 27.8991C42.2561 22.1645 43.1308 20.415 43.1308 20.415H43.714V28.9682H48.5738V14H44.2972C44.2972 14 43.228 14.0972 42.6449 15.0692C40.2149 20.0262 38.8542 22.5533 38.8542 22.5533H38.271V14H33.4112Z" fill="#4FAD50" />
      <path d="M13 29.0654V14.0972H17.8598C17.8598 14.0972 19.2206 14.0972 19.9981 16.2355C21.9421 21.8729 22.1365 22.6504 22.1365 22.6504C22.1365 22.6504 22.5252 21.2897 24.2748 16.2355C25.0523 14.0972 26.4131 14.0972 26.4131 14.0972H31.2729V29.0654H26.4131V20.9981H25.8299L23.1084 29.0654H20.9701L18.2486 20.9981H17.6654V29.0654H13Z" fill="#4FAD50" />
      <defs>
        <linearGradient id="paint0_linear_407_2374" x1="49.6257" y1="17.2535" x2="64.9656" y2="17.2535" gradientUnits="userSpaceOnUse">
          <stop stopColor="#27B1E6" />
          <stop offset="1" stopColor="#148ACA" />
        </linearGradient>
      </defs>
    </svg>
  )
}
