import React, { useRef } from "react";
import OtpInput from "react-otp-input";
import cx from "classnames";
import s from "./auth-popap.module.scss";
import { ResendCountdown } from "./components/ResendCountdown";

const smsLength = 4;

export const PhoneConfirmation2 = ({
  previousTab,
  phoneConfirmation,
  number,
  refreshCode,
  codeError,
  setCodeError,
}) => {
  const [sms, setSms] = React.useState('')
  const lastInputSymbol = useRef(null)

  const onChange = (value) => {
    setCodeError(false)
    if (value.length === smsLength) {
      phoneConfirmation(String(value))
      lastInputSymbol.current()
    }
    setSms(value)
  }

  const getRenderInput = (props, index) => {
    if (index === smsLength - 1) {
      lastInputSymbol.current = props.ref
    }

    return <input {...props} />
  }

  return (
    <div className={s.phone2}>
      <div>
        <div className={s.phone_break}>
          <div className={s.title2}>Вы отправили смс-код на номер</div>
          <div className={s.title2}>&nbsp;{number}</div>
        </div>

        <div
          className={s.text} style={{ cursor: 'pointer', color: '#FF6938', marginTop: '5px', }}
          onClick={previousTab}
        >
          Изменить
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <OtpInput
            value={sms}
            onChange={onChange}
            numInputs={smsLength}
            inputType={'number'}
            placeholder={`${'•'.repeat(smsLength)}`}
            renderSeparator={<span className={s.separatorBetween}></span>}
            renderInput={getRenderInput}
            inputStyle={cx(s.smsInputField, { [s.smsInputError]: codeError })}
            skipDefaultStyles={true}
          />
        </div>

        {/* Hidden input for autoComplete */}
        <input
          className={s.smsInputHidden}
          style={{ borderBottom: (codeError ? '1px solid #FF5D5D' : '') }}
          value={sms}
          type="text"
          onChange={e => onChange(e.target.value)}
          autoComplete="one-time-code"
          inputMode='numeric'
          autoCorrect="off"
          autoCapitalize="off"
        />

        {codeError && <div className={s.errorMessage} style={{ marginTop: '5px' }}>
          Неправильно введен смс-код
        </div>}
      </div>

      <ResendCountdown refreshCode={refreshCode} />
    </div>
  )
}
