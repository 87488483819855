import React from 'react';

const style = {
  cursor: 'pointer',
  transition: '0.15s ease-in-out',
  display: 'flex',
};

export const ChevronSvg = ({ isDown = false }) => {
  const rotateStyle = isDown ? { transform: 'rotate(-180deg)' } : {};

  return (
    <div style={{ ...rotateStyle, ...style }}>
      <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" d="M11.5073 6.5L6.50726 1.5L1.50726 6.5" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};
