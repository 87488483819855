import React from 'react';

export const QuotesSvg = ({ style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.4 83.5" className={style}>
    <defs>
      <style>
        {`
          .quotes-cls-1 {
            opacity: 0.07;
          }
          .quotes-cls-2 {
            fill: #252525;
          }
        `}
      </style>
    </defs>
    <title>quotes</title>
    <g id="Page-1" className="cls-1">
      <g id="mainpage">
        <path id="_" data-name="“" className="quotes-cls-2" d="M96.09,5.42A40.77,40.77,0,0,0,81,16.55Q74.56,24,73.24,31.79a40.92,40.92,0,0,0-.73,7,17.88,17.88,0,0,0,.73,5.57,23.12,23.12,0,0,1,17.43-7.18q10.55,0,17.14,6.15t6.59,17q0,10.25-7,16.7T89.79,83.5q-14.65,0-21.39-10.55a36.43,36.43,0,0,1-4.83-11.21A53.48,53.48,0,0,1,61.82,48a52.28,52.28,0,0,1,7.91-28.12Q77.64,7.18,93.31,0Zm-61.82,0A40.77,40.77,0,0,0,19.19,16.55Q12.74,24,11.43,31.79a40.92,40.92,0,0,0-.73,7,17.88,17.88,0,0,0,.73,5.57,23.12,23.12,0,0,1,17.43-7.18q10.55,0,17.14,6.15t6.59,17q0,10.25-7,16.7T28,83.5q-14.65,0-21.39-10.55A36.43,36.43,0,0,1,1.76,61.74,53.48,53.48,0,0,1,0,48,52.28,52.28,0,0,1,7.91,19.92Q15.82,7.18,31.49,0Z" />
      </g>
    </g>
  </svg>
);
