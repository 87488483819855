import React from 'react'
import s from './Prefooter.module.scss';
import {LeftSide} from "./LeftSide/LeftSide";

export const Prefooter = ({expire, show}) => {
  const [timerSeconds, setTimerSeconds] = React.useState(expire ? expire : 0)
  const [isTimerActive, setIsTimerActive] = React.useState(show)
  const minutes = Math.floor(timerSeconds/60)
  const seconds = timerSeconds - minutes * 60

  const timer = React.useCallback(() => {
    setTimeout(() => setTimerSeconds(time => time - 1), 1000)
  }, [setTimerSeconds])

  React.useEffect(() => {
    timerSeconds ? timer() : setIsTimerActive(false)
  }, [timer, timerSeconds])

  const degree = (360 / 3600) * (3600 - timerSeconds)

  const circleCenter = 140
  const circleRadius = 135

  const x = Number(Math.cos(2 * Math.PI * timerSeconds / 3600) * circleRadius + 0.5) + circleCenter
  const y = Number(Math.sin(2 * Math.PI * timerSeconds / 3600) * circleRadius + 0.5) + circleCenter

  //TODO Need to update project libraries. Than can be use x,y variables only
  const iosCssFixX = x < 275 ? x : 275
  const iosCssFixY = (y < 131) || (x < 275) ? y : 131

  const circleStyle = {
    // TODO current string when ios will be fixed:  background: `conic-gradient(#FF6938 0 ${360 - degree}deg, #FFFFFF ${360 - degree}deg 100%)`,
    background: `conic-gradient(#FF6938 0 ${ (360 - degree) < 352 ? 360 - degree : 355 }deg, #FFFFFF ${ (360 - degree) < 352 ? 360 - degree : 355 }deg 100%)`,
    '--background-style': `radial-gradient( circle closest-side at ${iosCssFixX}px ${iosCssFixY}px, #FF6938 0.3em, #0000 0.3em)`,
  }

  return (
    <div className={`${s.prefooter} ${isTimerActive ? '' : s.prefooter_without_timer}`}>
      <div className={s.prefooter_wrapper}>
        <LeftSide
          isTimerActive={isTimerActive}
          setIsTimerActive={setIsTimerActive}
        />

        {isTimerActive &&
          <div className={s.timer_circle} style={circleStyle}>
            <div className={s.timer_dot}>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '170px'}}>
                <div className={s.timer}>{minutes <= 9 ? 0 : `${minutes}`[0]}</div>
                <div className={s.timer}>{minutes <= 9 ? minutes : `${minutes}`[1]}</div>
                <div className={s.timer} style={{width: '20px'}}>:</div>
                <div className={s.timer}>{seconds <= 9 ? 0 : `${seconds}`[0]}</div>
                <div className={s.timer}>{seconds <= 9 ? seconds : `${seconds}`[1]}</div>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '160px'}}>
                <div>минут</div>
                <div>секунд</div>
              </div>
              <div className={s.timer_text}>
                Успейте получить скидку на первый заказ
                <div className={s.orange_text}>25%</div>
              </div>
            </div>
          </div>
        }

        <div className={`${s.right_side} ${isTimerActive ? '' : s.right_side_without_timer}`}>
          <div className={s.right_background}>
            <div className={`${s.dish2} ${isTimerActive ? '' : s.dish2_without_timer}`}/>
          </div>
        </div>
        <div className={s.dish1}/>
        <div className={s.tomatoes}/>
      </div>
    </div>
  )
}
