import React from "react"
import Popup from "reactjs-popup"

import emitterClient from "../emitterClient"


export default class CityPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = { city_data: this.props.city_data, offset: 0 }
    this.width = 300
  }

  componentDidMount() {
    const selectCityElement =
      document.getElementById("citySelect") || // for the new header
      document.getElementsByClassName("header__city-select")[0]; // for the old header

    const { left, width } = selectCityElement.getBoundingClientRect()
    this.setState({ offset: left + (width - this.width) / 2 })
  }

  setCity(selectedId) {
    emitterClient.emit("CITY_SELECTED", selectedId)
    this.props.onClose()
  }

  render() {
    const {
      city_data: { id },
      offset,
    } = this.state
    const {
      cities: { id_to_city_name, id_to_city_name_def, list },
    } = this.props
    const otherCity = list.filter((elem) => elem["id"] != id)[0]
    return (
      <Popup
        open
        contentStyle={{
          borderRadius: "10px",
          padding: "10px",
          margin: 0,
          left: `${offset}px`,
          top: "70px",
          width: `${this.width}px`,
          height: "140px",
        }}
        onClose={this.props.onClose}
      >
        <div>
          <div className="header__city-select--qstn">
            {" "}
            Вы находитесь в <b>{id_to_city_name_def[id]}</b>?{" "}
          </div>
          <div className="header__city-select--answr">
            <a
              className="header__city-select--answr-opt"
              data-target="city"
              onClick={() => this.setCity(id)}
            >
              {" "}
              Да, я в {id_to_city_name_def[id]}{" "}
            </a>
            <a
              className="header__city-select--answr-opt"
              onClick={() => this.setCity(otherCity.id)}
            >
              {" "}
              Нет, я в {id_to_city_name_def[otherCity.id]}{" "}
            </a>
          </div>
        </div>
      </Popup>
    )
  }
}
