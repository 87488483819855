export const snakeToCamel = ( str ) => str.replace(/(_\w)/g, k => k[1].toUpperCase())
export const objectToCamel = ( obj ) => {
  if (Array.isArray(obj)) return obj.map(v => objectToCamel(v))
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj)
      .reduce(( acc, value ) =>
          ({ ...acc, [snakeToCamel(value)]: objectToCamel(obj[value]), }),
        {},
      );
  }
  return obj;
}
export const changeProfileProps = ( props ) => {
  const renamedProps = objectToCamel(props)
  return ({
    dietsOption: renamedProps.dietsOption,
    availableDeliveryDays: renamedProps.upcomingDeliveries,
    daysOption: renamedProps.daysOption,
    initialSettings: renamedProps.initialCalc,

    prices: props.prices,
    dayPrices: props.relative_prices,
  })
}
export const changeLandingProps = ( props ) => {
  const renamedProps = objectToCamel(props)

  return ({
    calculatorData: {
      dietsOption: renamedProps.dietsOption,
      daysOption: renamedProps.daysOption,
      initialSettings: renamedProps.initialCalc,
      initialWeekDay: renamedProps.currentDate,
      dishes: renamedProps.dishes,

      nutritionStats: props.nutrition_stats,
      menu: props.menu,
      prices: props.prices,
      dayPrices: props.relative_prices,
      oldPrices: props.old_prices,
    },
    providerProps: {
      userData: {
        phone: renamedProps.userData.phone,
        isUserLogged: renamedProps.userData.userLoggedIn,
        isUserDataFilled: renamedProps.userData.valid
      },
      captchaKeys: renamedProps.captchaKeys,
    },
    authPopupData: {
      availableDeliveryDays: renamedProps.upcomingDeliveries,
      dadataApiKey: renamedProps.dadataApiKey,
      initialSettings: renamedProps.initialCalc,
      dietsOption: renamedProps.dietsOption,
      daysOption: renamedProps.daysOption,

      prices: props.prices,
      dayPrices: props.relative_prices,
    },
    faq: renamedProps.faq,
    prefooter: renamedProps.prefooter,
    header: renamedProps.header,
  })
}
