import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AUTH_ACTION = Object.freeze({
  OPEN_MODAL_CALCULATOR: 'OPEN_MODAL_CALCULATOR',
  OPEN_MODAL_PREFOOTER: 'OPEN_MODAL_PREFOOTER',
  OPEN_MODAL_ONLY_LOGIN: 'OPEN_MODAL_ONLY_LOGIN',
})

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [openAuthAction, setOpenAuthAction] = useState('');
  const [authArguments, setAuthArguments] = useState({});

  return (
    <AuthContext.Provider
      value={{
        openAuthAction,
        setOpenAuthAction,
        authArguments,
        setAuthArguments,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
