import React from "react"
import {
  AddressInput,
  AddressInputStrict,
  addressWrapper,
  payloadModifierStreet,
  buildPayloadModifierHouse,
  DefaultAddress,
  DestroyAddress
} from "./addressWrapper"

class WrappedAdminAddress extends React.Component {
  render() {
    const { dadata_api_key } = this.props
    const { house, street_fias_id } = this.props.addressProps
    const indexedName = `user[addresses_attributes][${this.props.index}]`
    return (
      <div>
        <div className="field-unit field-unit--string">
          <div className="field-unit__label">
            <label htmlFor="user_address">Address</label>
          </div>
          <div>
            <AddressInputStrict
              wrapperClassName="field-unit__field"
              label="Улица*"
              token={dadata_api_key}
              payloadModifier={payloadModifierStreet}
              onChange={this.props.onStreetSelect}
              query={this.props.addressProps.street}
            />
            <AddressInputStrict
              wrapperClassName="field-unit__field"
              label="Дом*"
              token={dadata_api_key}
              payloadModifier={buildPayloadModifierHouse(street_fias_id)}
              onChange={this.props.onHouseSelect}
              query={this.props.addressProps.house}
            />
            <AddressInput
              wrapperClassName="field-unit__field"
              label="кв. / офис"
              inputProps={{
                name: "placement",
                value: this.props.addressProps.placement,
                onChange: this.props.onChange,
              }}
            />

            <div>
              <AddressInput
                wrapperClassName="field-unit__field"
                label="под."
                inputProps={{
                  name: "entrance",
                  value: this.props.addressProps.entrance,
                  onChange: this.props.onChange,
                }}
              />
              <AddressInput
                wrapperClassName="field-unit__field"
                label="эт."
                inputProps={{
                  name: "floor",
                  value: this.props.addressProps.floor,
                  onChange: this.props.onChange,
                }}
              />
              <AddressInput
                labelClassName="field-unit__field"
                label="Код домофона"
                inputProps={{
                  name: "intercom_code",
                  value: this.props.addressProps.intercom_code,
                  onChange: this.props.onChange,
                }}
              />
              <AddressInput
                labelClassName="field-unit__field"
                label="Комментарий"
                inputProps={{
                  name: "comment",
                  value: this.props.addressProps.comment,
                  onChange: this.props.onChange,
                }}
              />
              <DefaultAddress
                labelClassName="field-unit__field"
                label="Адрес по умолчанию"
                inputProps={{
                  name: "default",
                  defaultChecked: this.props.addressProps.default,
                  value: this.props.addressProps.default,
                  onClick: this.props.onChecked,
                }}
              />
              <DestroyAddress
                labelClassName="field-unit__field"
                label="Пометить адрес на удаление"
                inputProps={{
                  name: "_destroy",
                  defaultChecked: this.props.addressProps._destroy,
                  value: this.props.addressProps._destroy,
                  onClick: this.props.onCheckedDestroy,
                  addressid: this.props.addressProps.id
                }}
              />
            </div>
          </div>
          <div className="field-unit__field">
            <input
              type="hidden"
              value={this.props.addressProps.id}
              name={`${indexedName}[id]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.district}
              name={`${indexedName}[district]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.street}
              name={`${indexedName}[street]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.house}
              name={`${indexedName}[house]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.placement}
              name={`${indexedName}[placement]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.entrance}
              name={`${indexedName}[entrance]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.floor}
              name={`${indexedName}[floor]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.intercom_code}
              name={`${indexedName}[intercom_code]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.comment}
              name={`${indexedName}[comment]`}
            />
            <input
              type="hidden"
              value={
                this.props.addressProps.metaData.city_district_with_type || ""
              }
              name={`${indexedName}[city_district_with_type]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.metaData.city_with_type || ""}
              name={`${indexedName}[city_with_type]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.metaData.area_with_type || ""}
              name={`${indexedName}[area_with_type]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps.metaData.city_area || ""}
              name={`${indexedName}[city_area]`}
            />
            <input
              type="hidden"
              checked={this.props.addressProps.default}
              value={this.props.addressProps.default}
              name={`${indexedName}[default]`}
            />
            <input
              type="hidden"
              value={this.props.addressProps._destroy}
              name={`${indexedName}[_destroy]`}
            />
            <div></div>
          </div>
        </div>
      </div>
    )
  }
}

const AdminAddress = addressWrapper(WrappedAdminAddress)

export default AdminAddress
