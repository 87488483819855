import React from 'react'

export const MasterCardSvg = () => {
  return (
    <svg width="78" height="44" viewBox="0 0 78 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="78" height="44" rx="4" fill="#E4CEC7" fillOpacity="0.2" />
      <path d="M63 21.4C63 29.3015 56.5754 35.8 48.6 35.8C40.6985 35.8 34.2 29.3015 34.2 21.4C34.2 13.4985 40.6246 7 48.5262 7C56.5754 7 63 13.4985 63 21.4Z" fill="#F9B50B" />
      <path d="M43.7262 21.4738C43.7262 20.44 43.5785 19.4062 43.4308 18.4462H34.5692C34.6431 17.9292 34.7908 17.4862 34.9385 16.8954H42.84C42.6923 16.3785 42.4708 15.8615 42.2492 15.3446H35.5292C35.7508 14.8277 36.0462 14.3846 36.3415 13.7938H41.4369C41.1415 13.2769 40.7723 12.76 40.3292 12.2431H37.5231C37.9662 11.7262 38.4092 11.2831 39 10.7662C36.4892 8.40308 33.0923 7 29.3262 7C21.4985 7.22154 15 13.4985 15 21.4C15 29.3015 21.4246 35.8 29.4 35.8C33.1662 35.8 36.4892 34.3231 39.0738 32.0338C39.5908 31.5908 40.0338 31.0738 40.5508 30.4831H37.5969C37.2277 30.04 36.8585 29.5231 36.5631 29.0062H41.5846C41.88 28.5631 42.1754 28.0462 42.3969 27.4554H35.6769C35.4554 27.0123 35.2338 26.4954 35.0862 25.9046H42.9877C43.4308 24.5754 43.7262 23.0985 43.7262 21.4738Z" fill="#C8191C" />
      <path d="M34.4954 25.0923L34.7169 23.7631C34.6431 23.7631 34.4954 23.8369 34.3477 23.8369C33.8308 23.8369 33.7569 23.5415 33.8308 23.3939L34.2739 20.7354H35.0862L35.3077 19.2585H34.5692L34.7169 18.3723H33.1662C33.1662 18.3723 32.28 23.3939 32.28 23.9846C32.28 24.8708 32.7969 25.3139 33.5354 25.3139C33.9785 25.3139 34.3477 25.1662 34.4954 25.0923Z" fill="white" />
      <path d="M35.0123 22.6555C35.0123 24.797 36.4892 25.3139 37.6707 25.3139C38.7784 25.3139 39.2954 25.0924 39.2954 25.0924L39.5907 23.6155C39.5907 23.6155 38.7784 23.9847 37.9661 23.9847C36.2677 23.9847 36.5631 22.7293 36.5631 22.7293H39.6646C39.6646 22.7293 39.8861 21.7693 39.8861 21.3262C39.8861 20.3662 39.3692 19.1108 37.6707 19.1108C36.1938 19.037 35.0123 20.7355 35.0123 22.6555ZM37.6707 20.4401C38.4831 20.4401 38.3354 21.4001 38.3354 21.4739H36.6369C36.7107 21.4001 36.8584 20.4401 37.6707 20.4401Z" fill="white" />
      <path d="M47.3446 25.0923L47.64 23.3938C47.64 23.3938 46.9015 23.763 46.3107 23.763C45.2769 23.763 44.76 22.9507 44.76 21.9907C44.76 20.1446 45.6461 19.1846 46.7538 19.1846C47.4923 19.1846 48.1569 19.6276 48.1569 19.6276L48.3784 18.003C48.3784 18.003 47.4923 17.6338 46.6061 17.6338C44.8338 17.6338 43.1354 19.1846 43.1354 22.0646C43.1354 23.9846 44.0215 25.2399 45.8677 25.2399C46.5323 25.3138 47.3446 25.0923 47.3446 25.0923Z" fill="white" />
      <path d="M26.1507 19.0369C25.1169 19.0369 24.3046 19.3323 24.3046 19.3323L24.083 20.6615C24.083 20.6615 24.7477 20.3661 25.7815 20.3661C26.2984 20.3661 26.7415 20.4399 26.7415 20.883C26.7415 21.1784 26.6677 21.2522 26.6677 21.2522H26.003C24.6738 21.2522 23.2707 21.7692 23.2707 23.5415C23.2707 24.9446 24.1569 25.2399 24.7477 25.2399C25.7815 25.2399 26.2984 24.5753 26.3723 24.5753L26.2984 25.1661H27.5538L28.1446 20.9569C28.1446 19.1107 26.6677 19.0369 26.1507 19.0369ZM26.4461 22.4338C26.4461 22.6553 26.2984 23.9107 25.4123 23.9107C24.9692 23.9107 24.8215 23.5415 24.8215 23.3199C24.8215 22.9507 25.043 22.4338 26.2246 22.4338C26.3723 22.4338 26.4461 22.4338 26.4461 22.4338Z" fill="white" />
      <path d="M29.6216 25.2399C29.9908 25.2399 31.9108 25.3138 31.9108 23.2461C31.9108 21.3261 30.0647 21.6953 30.0647 20.9569C30.0647 20.5876 30.36 20.4399 30.877 20.4399C31.0985 20.4399 31.9108 20.5138 31.9108 20.5138L32.1323 19.1107C32.1323 19.1107 31.6154 18.963 30.6554 18.963C29.5477 18.963 28.3662 19.4061 28.3662 20.9569C28.3662 22.7292 30.2862 22.5815 30.2862 23.2461C30.2862 23.6892 29.7693 23.763 29.4 23.763C28.7354 23.763 27.997 23.5415 27.997 23.5415L27.7754 24.9446C27.9231 25.0922 28.3662 25.2399 29.6216 25.2399Z" fill="white" />
      <path d="M60.0461 17.8553L59.7507 19.923C59.7507 19.923 59.16 19.1846 58.2738 19.1846C56.8707 19.1846 55.6892 20.883 55.6892 22.8769C55.6892 24.1323 56.28 25.3877 57.6092 25.3877C58.4954 25.3877 59.0861 24.7969 59.0861 24.7969L59.0123 25.3138H60.5631L61.6707 18.003L60.0461 17.8553ZM59.3077 21.843C59.3077 22.6553 58.9384 23.763 58.0523 23.763C57.5354 23.763 57.24 23.32 57.24 22.5077C57.24 21.2523 57.7569 20.5138 58.4954 20.5138C59.0123 20.5877 59.3077 20.9569 59.3077 21.843Z" fill="white" />
      <path d="M17.8062 25.1661L18.6923 19.7015L18.84 25.1661H19.8739L21.8677 19.7015L21.0554 25.1661H22.68L23.9354 17.8553H21.4247L19.8739 22.36L19.8 17.8553H17.5847L16.3293 25.1661H17.8062Z" fill="white" />
      <path d="M41.4369 25.1661C41.88 22.6553 41.9538 20.5876 43.0615 20.9569C43.2092 19.9969 43.4308 19.5538 43.5785 19.1846H43.2831C42.6185 19.1846 42.0277 20.0707 42.0277 20.0707L42.1754 19.2584H40.6985L39.7385 25.1661H41.4369Z" fill="white" />
      <path d="M50.8892 19.0369C49.8553 19.0369 49.043 19.3323 49.043 19.3323L48.8215 20.6615C48.8215 20.6615 49.4861 20.3661 50.52 20.3661C51.0369 20.3661 51.48 20.4399 51.48 20.883C51.48 21.1784 51.4061 21.2522 51.4061 21.2522H50.7415C49.4123 21.2522 48.0092 21.7692 48.0092 23.5415C48.0092 24.9446 48.8953 25.2399 49.4861 25.2399C50.52 25.2399 51.0369 24.5753 51.1107 24.5753L51.0369 25.1661H52.44L53.0307 20.9569C53.0307 19.1107 51.4061 19.0369 50.8892 19.0369ZM51.2584 22.4338C51.2584 22.6553 51.1107 23.9107 50.2246 23.9107C49.7815 23.9107 49.6338 23.5415 49.6338 23.3199C49.6338 22.9507 49.8553 22.4338 51.0369 22.4338C51.1846 22.4338 51.1846 22.4338 51.2584 22.4338Z" fill="white" />
      <path d="M54.2123 25.1661C54.6554 22.6553 54.7293 20.5876 55.837 20.9569C55.9846 19.9969 56.2062 19.5538 56.3539 19.1846H56.0585C55.3939 19.1846 54.8031 20.0707 54.8031 20.0707L54.9508 19.2584H53.4739L52.5139 25.1661H54.2123Z" fill="white" />
    </svg>
  )
}
