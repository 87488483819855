const defaultSecondLine = `Не теряйте время на кухне и в продуктовом магазине – заказывайте диетическое питание от Польза.Диет`

export const contentList = Object.freeze({
  main: {
    firstLine: "Доставка готовой еды для диеты по медицинским показаниям",
    secondLine: defaultSecondLine,
  },
  stol1: {
    firstLine: "Cтол №1 с доставкой",
    secondLine: "Вкусное питание с учетом ограничений",
  },
  stol9: {
    firstLine: "Cтол №9 с доставкой",
    secondLine: `Вкусное питание c учетом ограничений и рекомендаций при Диабете II типа и преддиабете`,
  },
  stol5: {
    firstLine: "Cтол №5 с доставкой",
    secondLine: "Вкусное питание с учетом ограничений",
  },
  holetsistit: {
    firstLine: "Как питаться при холецистите?",
    secondLine: "Решим эту проблему за вас",
  },
  duodenit: {
    firstLine: "Готовое питание при дуодените",
    secondLine: defaultSecondLine,
  },
  appendicit: {
    firstLine: "Готовое питание после удаления аппендицит",
    secondLine: defaultSecondLine,
  },
  refluks: {
    firstLine: "Готовое питание при рефлюксе",
    secondLine: defaultSecondLine,
  },
  yazva: {
    firstLine: "Готовое питание при язве",
    secondLine: defaultSecondLine,
  },
  gastrit: {
    firstLine: "Готовое питание при гастрите",
    secondLine: defaultSecondLine,
  },
  udalenie_zhelchnogo_puzyrya: {
    firstLine: "Готовое питание после удаления желчного пузыря",
    secondLine: defaultSecondLine,
  },
  pankreatit: {
    firstLine: "Готовое питание при панкреатите",
    secondLine: defaultSecondLine,
  },
  diabet: {
    firstLine: "Готовая еда для диабетиков",
    secondLine: "Решим эту проблему за вас",
  },
})
