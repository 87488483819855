import React from "react"
import axios from "axios"
import Popup from "reactjs-popup"

import emitterClient from "../emitterClient"
import { DeliverySelect } from "../deliveryChart"


export default class AccountSettingsPopup extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      delivery_time_range_id: this.props.delivery_time_range_id || 1,
      request_status: "idle",
    }
  }

  handleChangeSelect = (delivery_time_range_id) => {
    this.setState((state) => ({
      ...state,
      delivery_time_range_id,
    }))
  }

  submitForm = (event) => {
    event.preventDefault()
    this.setState((state) => ({
      ...state,
      request_status: "pending",
    }))
    axios
      .patch("/user", {
        delivery_time_range_id: Number(this.state.delivery_time_range_id),
      })
      .then(() => {
        this.setState((state) => ({
          ...state,
          request_status: "success",
        }))
        emitterClient.emit(
          "DELIVERY_TIME_SELECTED",
          this.state.delivery_time_range_id,
        )
        this.props.onClose()
      })
  }

  render() {
    const { delivery_time_range_id, request_status } = this.state
    const { delivery_time_options = [], onClose } = this.props

    return (
      <Popup
        open={true}
        closeOnDocumentClick={true}
        contentStyle={{
          padding: "24px 32px",
          width: "100%",
          maxWidth: "460px",
        }}
        onClose={onClose}
      >
        <form onSubmit={this.submitForm}>
          <label className="label" htmlFor="delivery_time_range_id">
            Время доставки по умолчанию:
          </label>
          <DeliverySelect
            disabled={request_status === "pending"}
            value={
              delivery_time_options.find(
                (option) => option.id === delivery_time_range_id,
              ).name
            }
            options={delivery_time_options}
            onChange={this.handleChangeSelect}
          />
          <div className="d-flex stack--horizontal mt-4">
            <button
              type="button"
              className="btn btn--gray"
              disabled={request_status === "pending"}
              onClick={onClose}
            >
              Закрыть
            </button>
            <button
              type="submit"
              className="btn btn--gray"
              disabled={request_status === "pending"}
            >
              Сохранить
            </button>
          </div>
        </form>
      </Popup>
    )
  }
}
