import "regenerator-runtime/runtime"

import ReactOnRails from "react-on-rails"

import AccountSettingsButton from "../startup/ClientAccountSettingsButton"
import AccountSettingsPopup from "../startup/ClientAccountSettingsPopup"
import Address from "../startup/ClientAddress"
import AdminAddress from "../startup/ClientAdminAddress"
import CitySelect from "../components/citySelect"
import DeliveryChart from "../startup/ClientDeliveryChart"
import GoogleTag from "../components/googleTag"
import IncomingCalls from "../startup/ClientIncomingCalls"
import PopupControl from "../startup/ClientPopupControl"
import UserReview from "../startup/ClientUserReview"
import AdminAddressesForm from "../startup/ClientAdminAddressesForm"
import DeliveryAndPay from "../startup/ClientDeliveryAndPay"
import LandingPage from "../startup/ClientLandingPage"
import ProfileOrder from "../startup/ClientProfileOrder"
import CheckoutPay from "../startup/ClientCheckoutPay"
import { Footer } from "../components/Footer/Footer"

ReactOnRails.setOptions({
  traceTurbolinks: true,
})

ReactOnRails.register({
  AccountSettingsButton,
  AccountSettingsPopup,
  Address,
  AdminAddress,
  CitySelect,
  DeliveryChart,
  GoogleTag,
  IncomingCalls,
  PopupControl,
  UserReview,
  AdminAddressesForm,
  DeliveryAndPay,
  LandingPage,
  ProfileOrder,
  CheckoutPay,
  Footer,
})
