import React from 'react';
import cx from 'classnames';
import s from './dietitian.module.scss';
import { dietitianList } from './dietitianList';
import { QuotesSvg } from '../svgs/QuotesSvg';
import DoctorMobileJpg from '../../../../app/assets/images/doctor_2_mobile.jpg';
import DoctorDesktopJpg from '../../../../app/assets/images/doctor_2_desktop.jpg';

const positionText = `Врач-диетолог, гастроэнтеролог, Центр${'\u00a0'}Гастроэнтерологии СПбГУ`;

export const Dietitian = ({ page = 'main' }) => {
  const { text, diagnoses } = dietitianList[page] || dietitianList.main;

  return (
    <div className={s.about_person}>
      <div className={s.container}>
        <div className={s.about_person__wrap}>
          <div className={s.about_person__info}>
            <span className={s.about_person__name}>Александра Белодедова</span>
            <span className={s.about_person__position}>{positionText}</span>
            <span className={s.about_person__descr}>
              {text}
              {diagnoses && (
                <ul className={s.about_person__diagnoses}>
                  <li>панкреатит</li>
                  <li>рефлюкс-эзофагит</li>
                  <li>гастрит</li>
                  <li>желчнокаменная болезнь</li>
                  <li>дуоденит</li>
                  <li>язва</li>
                </ul>
              )}
              {diagnoses && '* в хронической стадии'}
            </span>
            {page !== 'main' && <QuotesSvg style={cx(s.icon_quotes, s.decor_2)} />}
          </div>
          <div className={s.about_person__img_wrap}>
            <picture>
              <source srcSet={DoctorDesktopJpg} media="(min-width: 801px)" type="image/jpeg" />
              <img src={DoctorMobileJpg} alt="Doctor" className={'lazy-landing'} />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
}
