import React from "react";
import styles from "../calculator.module.scss";
export const WeekString = ({onChange, currentDate}) => {
  const weekDays = ['пн ', 'вт ', 'ср ', 'чт ', 'пт ', 'сб ', 'вс '];

  return (
    <div className={styles.days} data-target="days">
      {weekDays.map((day, index) => (
        <span
          key={day}
          className={`${styles.day} ${index + 1 === currentDate ? styles.day_active : ''}`}
          onClick={() => onChange(index)}
        >
         {day}
        </span>
      ))}
    </div>
  )
}
