import React from 'react';
import style from './dropdowns.module.scss';
import { ArrowIcon } from "../../icons/AuthPopupIcons";

export const Dropdown = ({ title, options, activeValue, satActiveValue, optionsTitle }) => {
  const [isAutocomplete, setIsAutocomplete] = React.useState(false)
  React.useEffect(() => {
    const hideModal = ( event ) => {
      const isItAutocomplete = !!(event.target).closest(`[data-modal='date-select']`);
      !isItAutocomplete && setIsAutocomplete(false);
    }

    document.addEventListener('click', hideModal);
    return () => document.removeEventListener('click', hideModal);
  }, [isAutocomplete]);
  return (
    <>
      <span className={ style.title2 }>{ title }</span>
      <div style={ { position: 'relative' } }>
        <input
          value={ activeValue[optionsTitle] }
          data-modal={ 'date-select' }
          className={ style.dropdown }
          style={ { cursor: 'pointer' } }
          readOnly={ true }
          onChange={ () => { } }
          onClick={ () => setIsAutocomplete(true) }
        />
        { isAutocomplete && options.length
          ? <div className={ style.menu }>
            { options.map(( item, idx ) =>
              <div
                key={ idx }
                className={ style.menuItem + ' ' + style.text }
                style={ { marginLeft: 0 } }
                onClick={ () => satActiveValue(item) }
              >
                { item[optionsTitle] }
              </div>
            ) }
          </div>
          : null
        }
        <div className={ style.arrow_wrapper } style={ { transform: (isAutocomplete ? 'rotate(180deg)' : '') } }>
          <ArrowIcon/>
        </div>
      </div>
    </>
  )
}
