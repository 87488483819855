import React from 'react'
import s from "./LeftSide.module.scss";
import {Modal} from "../../../../Entity/modal/modal";
import {SuccessIcon} from "../../../../icons/AuthPopupIcons";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import {totalNumber} from "../../../../../helpers/numberHelper";
import {sandPhone} from "../../../../../api/authPopup";
import {LandingPageContext} from "../../LandingContext/LandingProvider";
import {WarningIcon} from "../../../../icons/Prefooter/WarningIcon";
import { useAuthContext, AUTH_ACTION } from '../../../../../context/AuthContext';

const { OPEN_MODAL_PREFOOTER } = AUTH_ACTION;

export const PromoInform = ({open, setOpen, number, isLoggedError}) => {
  const [tokenV2, setTokenV2] = React.useState('')
  const [isShowV2, setIsShowV2] = React.useState(false)
  const [tokenV3, setTokenV3] = React.useState('')

  const { setOpenAuthAction, setAuthArguments } = useAuthContext();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const {user, reCaptchaV2Token} = React.useContext(LandingPageContext);

  const sandSmS = () => {
    const currentNumber = user.isUserLogged ? user.phone : totalNumber(number)

    sandPhone({
      phone: currentNumber,
      captcha_token: isShowV2 ? tokenV2 : tokenV3,
      captcha_version: isShowV2 ? 'v2' : 'v3'
    })
      .then(() => {
        setOpen(!open)
        setOpenAuthAction(OPEN_MODAL_PREFOOTER)
        setAuthArguments({ isOpen: true, number })
      })
      .catch(() => setIsShowV2(true))
  }

  React.useEffect(() => {
    if (!executeRecaptcha) return
    executeRecaptcha('auth_popup').then(token => setTokenV3(token))
  }, [open, executeRecaptcha, setTokenV3])

  if (!open) return null
  return (
    <Modal onClose={() => setOpen(!open)}>
      <div className={s.modal_body}>
        <div>
          <div style={{display: 'flex', justifyContent: 'center'}}>{ isLoggedError ? <WarningIcon/> : <SuccessIcon/> }</div>
          {isLoggedError
            ? <>
                <div className={s.subtitle1} style={{marginTop: '20px'}}>
                  Ой, мы ужа нашли у вас заказы или промокод на первый заказ.
                </div>
                <div className={s.subtitle1} style={{marginTop: '20px'}}>
                  Не расстраивайтесь, для постоянных клиентов предусмотрены скидки
                </div>
              </>
            : <>
                <div className={s.title1} style={{marginTop: '20px'}}>Промокод отправлен</div>
                <div className={s.subtitle1} style={{marginTop: '15px'}}>Мы отправили вам промокод на номер</div>
                <div className={s.colored_text} style={{textAlign: 'center', marginTop: '5px'}}>{number}</div>
              </>
          }
        </div>

        {isShowV2 &&
          <ReCAPTCHA
            sitekey={reCaptchaV2Token}
            onChange={setTokenV2}
          />
        }

        <button onClick={sandSmS}>Заказать</button>
      </div>
    </Modal>
  )
}
