import React from "react"

export const useScrollBar = () => {
  const [inPlace, setInPlace] = React.useState(true)

  React.useEffect(() => {
    const handleScroll = () => {
      setInPlace(!window.scrollY)
    }

    handleScroll()
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return inPlace
}
