import React from "react";
import { ClockIcon, ErrorIcon, LetterIcon } from "../../icons/AuthPopupIcons";


export const BadRequest = ( { errorText, onOkay } ) => {
  return (
    <div style={ { height: '355px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } }>
      <div style={ {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      } }>
        <div style={ { position: 'relative' } }>
          <LetterIcon/>
          <div style={ { position: 'absolute', top: '-5px', right: '-15px' } }>
            { errorText ? <ErrorIcon/> : <ClockIcon/> }
          </div>
        </div>
        <div style={ { fontWeight: '500', fontSize: '16px' } }>
          { errorText ? errorText : 'Попробуйте позже' }
        </div>
      </div>
      <button onClick={ onOkay }>окей</button>
    </div>
  )
}
