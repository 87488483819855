import React from 'react'

export const SecondStepSvg = ({ style }) => {
  return (
    <svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 93" className={style} >
      <defs>
        <style>
          {`
            .work-2-cls-1 {
            fill: #fff;
            }
            .work-2-cls-2 {
            fill: #ff6938;
            }
          `}
        </style>
      </defs>
      <title>work-2</title>
      <g id="Page-1">
        <g id="mainpage">
          <g id="Group-39">
            <g id="food_1-line-33" data-name="food 1-line-33">
              <path id="Path" className="work-2-cls-1" d="M10.11,28q-2,39.72,33,43.69T82,28H10.11Z" />
              <path className="work-2-cls-2" d="M10,32H1a1,1,0,0,1,0-2h9A1,1,0,0,1,10,32Z" />
              <path className="work-2-cls-2" d="M91,32H82a1,1,0,0,1,0-2h9A1,1,0,0,1,91,32Z" />
              <path className="work-2-cls-2" d="M46,20a1,1,0,0,1-1-1V1a1,1,0,0,1,2,0V19A1,1,0,0,1,46,20Z" />
              <path className="work-2-cls-2" d="M25,20a1,1,0,0,1-1-1V10a1,1,0,0,1,2,0v9A1,1,0,0,1,25,20Z" />
              <path className="work-2-cls-2" d="M67,20a1,1,0,0,1-1-1V7a1,1,0,0,1,2,0V19A1,1,0,0,1,67,20Z" />
              <path className="work-2-cls-2" d="M22,92a1,1,0,0,1-.8-1.59l14-19a1,1,0,0,1,1.61,1.19l-14,19A1,1,0,0,1,22,92Z" />
              <path className="work-2-cls-2" d="M70,93a1,1,0,0,1-.82-0.43l-14-20a1,1,0,0,1,1.64-1.15l14,20A1,1,0,0,1,70,93Z" />
              <path className="work-2-cls-2" d="M46,74A37,37,0,0,1,9,37V28a1,1,0,0,1,1-1H82a1,1,0,0,1,1,1v9A37,37,0,0,1,46,74ZM11,29v8a35,35,0,0,0,70,0V29H11Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}
