import { useRef, useEffect, useState } from "react"

export const useElementVisibilityObserver = (operation = () => {}) => {
  const [isVisible, setIsVisible] = useState(false)
  const targetRef = useRef(null)
  const operationRef = useRef(operation)

  useEffect(() => {
    operationRef.current = operation
  }, [operation])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          operationRef.current();
        } else {
          setIsVisible(false)
        }
      })
    }, options)

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  return [targetRef, isVisible]
}
