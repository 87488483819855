import React from 'react'
import { INSTAGRAM } from '../../constants/links'

export const InstagramSvg = () => {
  return (
    <a href={INSTAGRAM} target="_blank">
      <svg width="46" height="40" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_430_214)">
          <path d="M2.50001 2.72166C-0.643327 5.98666 6.57886e-06 9.455 6.57886e-06 19.9917C6.57886e-06 28.7417 -1.52666 37.5133 6.46334 39.5783C8.95834 40.22 31.065 40.22 33.5567 39.575C36.8833 38.7167 39.59 36.0183 39.96 31.3133C40.0117 30.6567 40.0117 9.33833 39.9583 8.66833C39.565 3.65666 36.48 0.768329 32.415 0.183329C31.4833 0.0483293 31.2967 0.00832928 26.5167 -4.05312e-06C9.56167 0.00832928 5.84501 -0.746671 2.50001 2.72166Z" fill="#E4CEC7" fillOpacity="0.2" />
          <path d="M19.9967 5.23166C13.945 5.23166 8.19835 4.69333 6.00335 10.3267C5.09668 12.6533 5.22835 15.675 5.22835 20.0017C5.22835 23.7983 5.10668 27.3667 6.00335 29.675C8.19335 35.3117 13.9867 34.7717 19.9933 34.7717C25.7883 34.7717 31.7634 35.375 33.985 29.675C34.8934 27.325 34.76 24.3483 34.76 20.0017C34.76 14.2317 35.0784 10.5067 32.28 7.70999C29.4467 4.87666 25.615 5.23166 19.99 5.23166H19.9967ZM18.6733 7.89333C31.2967 7.87333 32.9033 6.47 32.0167 25.965C31.7017 32.86 26.4517 32.1033 19.9983 32.1033C8.23168 32.1033 7.89335 31.7667 7.89335 19.995C7.89335 8.08666 8.82668 7.89999 18.6733 7.88999V7.89333ZM27.88 10.345C27.4101 10.345 26.9595 10.5317 26.6273 10.8639C26.295 11.1962 26.1083 11.6468 26.1083 12.1167C26.1083 12.5865 26.295 13.0372 26.6273 13.3694C26.9595 13.7017 27.4101 13.8883 27.88 13.8883C28.3499 13.8883 28.8005 13.7017 29.1328 13.3694C29.465 13.0372 29.6517 12.5865 29.6517 12.1167C29.6517 11.6468 29.465 11.1962 29.1328 10.8639C28.8005 10.5317 28.3499 10.345 27.88 10.345ZM19.9967 12.4167C19.0007 12.4168 18.0145 12.613 17.0944 12.9943C16.1743 13.3755 15.3383 13.9343 14.6341 14.6386C13.212 16.0611 12.4131 17.9902 12.4133 20.0017C12.4136 22.0131 13.2128 23.9421 14.6353 25.3642C16.0578 26.7864 17.9869 27.5852 19.9983 27.585C22.0098 27.5848 23.9388 26.7855 25.3609 25.3631C26.7831 23.9406 27.5819 22.0114 27.5817 20C27.5815 17.9885 26.7822 16.0596 25.3597 14.6374C23.9373 13.2153 22.0081 12.4164 19.9967 12.4167ZM19.9967 15.0783C26.505 15.0783 26.5133 24.925 19.9967 24.925C13.49 24.925 13.48 15.0783 19.9967 15.0783Z" fill="white" />
          <path d="M19.9967 5.23166C13.945 5.23166 8.19835 4.69333 6.00335 10.3267C5.09668 12.6533 5.22835 15.675 5.22835 20.0017C5.22835 23.7983 5.10668 27.3667 6.00335 29.675C8.19335 35.3117 13.9867 34.7717 19.9933 34.7717C25.7883 34.7717 31.7634 35.375 33.985 29.675C34.8934 27.325 34.76 24.3483 34.76 20.0017C34.76 14.2317 35.0784 10.5067 32.28 7.70999C29.4467 4.87666 25.615 5.23166 19.99 5.23166H19.9967ZM18.6733 7.89333C31.2967 7.87333 32.9033 6.47 32.0167 25.965C31.7017 32.86 26.4517 32.1033 19.9983 32.1033C8.23168 32.1033 7.89335 31.7667 7.89335 19.995C7.89335 8.08666 8.82668 7.89999 18.6733 7.88999V7.89333ZM27.88 10.345C27.4101 10.345 26.9595 10.5317 26.6273 10.8639C26.295 11.1962 26.1083 11.6468 26.1083 12.1167C26.1083 12.5865 26.295 13.0372 26.6273 13.3694C26.9595 13.7017 27.4101 13.8883 27.88 13.8883C28.3499 13.8883 28.8005 13.7017 29.1328 13.3694C29.465 13.0372 29.6517 12.5865 29.6517 12.1167C29.6517 11.6468 29.465 11.1962 29.1328 10.8639C28.8005 10.5317 28.3499 10.345 27.88 10.345ZM19.9967 12.4167C19.0007 12.4168 18.0145 12.613 17.0944 12.9943C16.1743 13.3755 15.3383 13.9343 14.6341 14.6386C13.212 16.0611 12.4131 17.9902 12.4133 20.0017C12.4136 22.0131 13.2128 23.9421 14.6353 25.3642C16.0578 26.7864 17.9869 27.5852 19.9983 27.585C22.0098 27.5848 23.9388 26.7855 25.3609 25.3631C26.7831 23.9406 27.5819 22.0114 27.5817 20C27.5815 17.9885 26.7822 16.0596 25.3597 14.6374C23.9373 13.2153 22.0081 12.4164 19.9967 12.4167ZM19.9967 15.0783C26.505 15.0783 26.5133 24.925 19.9967 24.925C13.49 24.925 13.48 15.0783 19.9967 15.0783Z" fill="url(#paint0_linear_430_214)" />
        </g>
        <g clipPath="url(#clip1_430_214)">
          <path d="M45.625 2.47405H43.9572L44.8769 1.04999C44.9041 1.0078 44.8928 0.950615 44.8506 0.921553L44.1438 0.44624C44.1334 0.439396 44.1217 0.434669 44.1095 0.432331C44.0972 0.429993 44.0847 0.430089 44.0725 0.432614C44.0603 0.43514 44.0487 0.440044 44.0384 0.447048C44.0281 0.454051 44.0193 0.463015 44.0125 0.473428L43 2.04187L41.9875 0.473428C41.9734 0.451865 41.9519 0.437803 41.9275 0.432178C41.9022 0.42749 41.8769 0.432178 41.8562 0.44624L41.1494 0.921553C41.1072 0.950615 41.0959 1.0078 41.1231 1.04999L42.0428 2.47405H40.375C40.3234 2.47405 40.2812 2.51624 40.2812 2.5678V3.43218C40.2812 3.48374 40.3234 3.52593 40.375 3.52593H42.0428L41.1231 4.94999C41.0959 4.99218 41.1072 5.04936 41.1494 5.07843L41.8562 5.55374C41.8769 5.5678 41.9022 5.57249 41.9275 5.5678C41.9519 5.56218 41.9734 5.54811 41.9875 5.52655L43 3.95811L44.0125 5.52655C44.0298 5.55346 44.0601 5.5694 44.0912 5.56968C44.11 5.56968 44.1278 5.56405 44.1438 5.55374L44.8506 5.07843C44.8928 5.04936 44.9041 4.99218 44.8769 4.94999L43.9572 3.52593H45.625C45.6766 3.52593 45.7188 3.48374 45.7188 3.43218V2.5678C45.7188 2.51624 45.6766 2.47405 45.625 2.47405Z" fill="url(#paint1_linear_430_214)" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_430_214" x1="7.20567" y1="32.8634" x2="34.6036" y2="9.1649" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFDD55" />
            <stop offset="0.5" stopColor="#FF543E" />
            <stop offset="1" stopColor="#C837AB" />
          </linearGradient>
          <linearGradient id="paint1_linear_430_214" x1="40.6463" y1="5.23486" x2="45.4393" y2="0.844972" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFDD55" />
            <stop offset="0.5" stopColor="#FF543E" />
            <stop offset="1" stopColor="#C837AB" />
          </linearGradient>
          <clipPath id="clip0_430_214">
            <rect width="40" height="40" fill="white" />
          </clipPath>
          <clipPath id="clip1_430_214">
            <rect width="6" height="6" fill="white" transform="translate(40)" />
          </clipPath>
        </defs>
      </svg>
    </a>
  )
}
