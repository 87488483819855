import React from 'react';
import s from './footer.module.scss';
import { Logo } from '../Entity/Logo/Logo';
import { WhatsAppSvg } from '../svgs/WhatsAppSvg';
import { TelegramSvg } from '../svgs/TelegramSvg';
import { VKontakteSvg } from '../svgs/VKontakteSvg';
import { OdnoklassnikiSvg } from '../svgs/OdnoklassnikiSvg';
import { InstagramSvg } from '../svgs/InstagramSvg';
import { VisaSvg } from '../svgs/VisaSvg';
import { MasterCardSvg } from '../svgs/MasterCardSvg';
import { SBPSvg } from '../svgs/SBPSvg';
import { CloudPaymentsSvg } from '../svgs/CloudPaymentsSvg';
import { MirSvg } from '../svgs/MirSvg';
import {
  CONFIDENTIALITY_LINK,
  CONTACTS,
  OFFER_LINK,
  TELEGRAM_BOT,
  MAIN_LINKS as mainLinks,
} from '../../constants/links';
import { DietMenu } from './DietMenu/DietMenu';

const { TEL, EMAIL } = CONTACTS;

export const Footer = () => {
  const getMainLinks = () => (
    <ul>
      {mainLinks.map(({ name, path }, index) => (
        <li key={index} className={s.link}>
          <a href={path} target={name === 'Статьи' && '_blank'}>{name}</a>
        </li>
      ))}
    </ul>
  );

  return (
    <footer className={s.footer_container}>
      <div className={s.footer_wrapper}>
        <div className={s.top_container}>
          <div className={s.company}>
            <div className={s.logo}>
              <Logo />
            </div>
            <div className={s.addresses}>
              <div className={s.address}>
                <p className={s.city}>Москва</p>
                <p className={s.street}>ул. Талалихина, д. 41</p>
              </div>
              <div className={s.address}>
                <p className={s.city}>Санкт-Петербург</p>
                <p className={s.street}>пр-кт. Большой П.С., дом 31 пом. 15-н</p>
              </div>
            </div>
          </div>
          <div className={s.navigation}>
            <div className={s.navigation_link}>{getMainLinks()}</div>
            <DietMenu />
          </div>
          <div className={s.contacts}>
            <div className={s.contacts_us}>
              <div className={s.tittle}>Связь с нами</div>
              <div className={s.contacts_info}>
                <a href={`tel:${TEL.HREF}`}>{TEL.VIEW}</a>
              </div>
              <div className={s.contacts_details}>Время работы: с 8:00 до 00:00</div>
              <div className={s.contacts_info}>
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
              </div>
              <div className={s.social}>
                <WhatsAppSvg />
                <TelegramSvg link={TELEGRAM_BOT} />
              </div>
            </div>
            <div className={s.social_container}>
              <div className={s.tittle}>Соц. сети</div>
              <div className={s.social}>
                <VKontakteSvg />
                <OdnoklassnikiSvg />
                <TelegramSvg />
                <InstagramSvg />
              </div>
              <div className={s.star_notation}>
                * Запрещённая <br /> в РФ экстремистская организация
              </div>
            </div>
          </div>
        </div>
        <div className={s.bottom_container}>
          <div className={s.payment}>
            <div className={s.payment_tittle}>Мы принимаем к оплате</div>
            <div className={s.payment_cards}>
              <VisaSvg />
              <MasterCardSvg />
              <SBPSvg />
              <CloudPaymentsSvg />
              <MirSvg />
            </div>
            <div className={s.payment_tin}>ООО «ГПК» ИНН: ‍7813650417 ОГРН: ‍1217800012066</div>
          </div>
          <div className={s.info}>
            <p>© 2024 Польза — сервис доставки готового диетического питания.</p>
            <p>
              Фотографии блюд на сайте являются вариантом сервировки блюда. Внешний вид блюда может отличаться
              от фотографии на сайте.
            </p>
            <p>
              Указывая номер телефона на сайте, вы соглашаетесь с условиями{' '}
              <a href={OFFER_LINK}>Публичной оферты</a> и{' '}
              <a href={CONFIDENTIALITY_LINK}>Политикой конфедициальности</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
