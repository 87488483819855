import React from "react"
import { useScrollContext } from "../context/ScrollContext"

export const useScrollTo = (to, scrollAlignment = "end") => {
  const shouldScrollRef = React.useRef(null)
  const [shouldScroll, setShouldScroll] = React.useState(false)
  const { scrollToElement, setScrollToElement } = useScrollContext()

  React.useEffect(() => {
    if (scrollToElement === to && shouldScrollRef.current) {
      shouldScrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: scrollAlignment,
      })
      setShouldScroll(true)
    }
  }, [scrollToElement])

  React.useEffect(() => {
    if (shouldScroll) {
      setScrollToElement(null)
      setShouldScroll(false)
    }
  }, [shouldScroll, setScrollToElement])

  return [shouldScrollRef]
}
