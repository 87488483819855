import React from 'react'
import { TELEGRAM_CHANNEL } from '../../constants/links'

export const TelegramSvg = ({ link = TELEGRAM_CHANNEL }) => {
  return (
    <a href={link} target="_blank">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_408_2400)">
          <path fillRule="evenodd" clipRule="evenodd" d="M13.8667 0C25.0667 0 14.9333 0 26.1333 0C37.3333 0 40 2.66667 40 13.8667C40 25.0667 40 14.9333 40 26.1333C40 37.3333 37.3333 40 26.1333 40C14.9333 40 25.0667 40 13.8667 40C2.66667 40 0 37.3333 0 26.1333C0 14.9333 0 20.1209 0 13.8667C0 2.66667 2.66667 0 13.8667 0Z" fill="#E4CEC7" fillOpacity="0.2" />
          <g clipPath="url(#clip1_408_2400)">
            <path d="M16.4448 23.3931L16.0214 29.3493C16.6272 29.3493 16.8896 29.0891 17.2043 28.7765L20.0448 26.0619L25.9307 30.3723C27.0102 30.9739 27.7707 30.6571 28.0619 29.3792L31.9254 11.2757L31.9264 11.2747C32.2688 9.67892 31.3494 9.05492 30.2976 9.44638L7.5883 18.1408C6.03843 18.7424 6.0619 19.6064 7.32483 19.9979L13.1307 21.8037L26.6166 13.3653C27.2512 12.9451 27.8283 13.1776 27.3536 13.5979L16.4448 23.3931Z" fill="#039BE5" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_408_2400">
            <rect width="40" height="40" fill="white" />
          </clipPath>
          <clipPath id="clip1_408_2400">
            <rect width="25.6" height="25.6" fill="white" transform="translate(6.40002 7.19995)" />
          </clipPath>
        </defs>
      </svg>
    </a>
  )
}
