import React from 'react';
import './common.scss';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { LandingProvider } from "./LandingContext/LandingProvider";
import { ScrollProvider } from '../../../context/ScrollContext';
import { changeLandingProps } from "../../../helpers/renameVariablesHelper";
import { Calculator } from "./Calculator/Calculator";
import { AuthPopup } from "./AuthPopup/AuthPopup";
import Reviews from "./Reviews/Reviews";
import { Delivery } from "./Dilivery/Delivery";
import { Faq } from "./Faq/Faq";
import { Prefooter } from "./Prefooter/Prefooter";
import { HowItWorks } from '../../HowItWorks/HowItWorks';
import { Footer } from '../../Footer/Footer';
import { Dietitian } from '../../Dietitian/Dietitian';
import { Top } from '../../Top/Top';
import { Header } from '../../Header/Header';
import { Wrapper } from '../../Entity/Wrapper/Wrapper';
import { CityProvider } from '../../../context/CityContext';
import { AuthProvider } from '../../../context/AuthContext';
import { CarouselBlock } from '../../CarouselBlock/CarouselBlock';

export const LandingPage = (props) => {
  const renamedProps = changeLandingProps(props)

  return (
    <Wrapper>
      <GoogleReCaptchaProvider reCaptchaKey={props.captcha_keys.v3}>
        <LandingProvider providerProps={renamedProps.providerProps}>
          <CityProvider cityData={props.header.city_data} >
            <AuthProvider>
              <ScrollProvider>
                <Header {...renamedProps.header} />
                {props.tests.landing_head && props.tests.landing_head.value === 'with_video'
                  ? <CarouselBlock data={props.tests.landing_head.data} page={props.content} />
                  : <Top page={props.content} />
                }
                <Dietitian page={props.content} />
                <HowItWorks page={props.content} />
                <Calculator {...renamedProps.calculatorData} />
                <Reviews />
                <Delivery />
                <Faq tabs={renamedProps.faq.tabs} qa={renamedProps.faq.qa} />
                <Prefooter {...renamedProps.prefooter} />
                <Footer />
                <AuthPopup {...renamedProps.authPopupData} />
              </ScrollProvider>
            </AuthProvider>
          </CityProvider>
        </LandingProvider>
      </GoogleReCaptchaProvider>
    </Wrapper>
  )
}
