import React from "react";

export const TruckIcon = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    // style={{ fill: '#881313'}}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4091 4.04535H12.8182V0.59082H0.727287C-0.227058 0.59082 -1 1.36376 -1 2.31811V11.8181H0.727287C0.727287 13.2475 1.88887 14.409 3.3182 14.409C4.74753 14.409 5.90911 13.2475 5.90911 11.8181H11.0909C11.0909 13.2475 12.2525 14.409 13.6818 14.409C15.1112 14.409 16.2728 13.2475 16.2728 11.8181H18V7.49993L15.4091 4.04535ZM3.3182 13.1135C2.60137 13.1135 2.02274 12.5349 2.02274 11.8181C2.02274 11.1013 2.60137 10.5226 3.3182 10.5226C4.03503 10.5226 4.61365 11.1013 4.61365 11.8181C4.61365 12.5349 4.03499 13.1135 3.3182 13.1135ZM13.6818 13.1135C12.965 13.1135 12.3864 12.5349 12.3864 11.8181C12.3864 11.1013 12.965 10.5226 13.6818 10.5226C14.3987 10.5226 14.9773 11.1013 14.9773 11.8181C14.9773 12.5349 14.3986 13.1135 13.6818 13.1135ZM12.8182 7.49993V5.34085H14.9773L16.6743 7.49993H12.8182Z"
    />
  </svg>
)
