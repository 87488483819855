import React, { createContext, useContext, useState } from 'react';

const CityContext = createContext();

export const useCityContext = () => useContext(CityContext);

export const CityProvider = ({ children, cityData }) => {
  const [city, setCity] = useState(cityData.id);
  const [openCitySelectPopup, setOpenCitySelectPopup] = useState(false);
  const [isCitySelected, setIsCitySelected] = useState(cityData.status);

  return (
    <CityContext.Provider value={{
      city,
      setCity,
      openCitySelectPopup,
      setOpenCitySelectPopup,
      isCitySelected,
      setIsCitySelected,
    }}>
      {children}
    </CityContext.Provider>
  );
};