import React from 'react'
import s from './carouselBlock.module.scss'
import { CoupleTopButtons } from '../Entity/CoupleTopButtons/CoupleTopButtons'
import { images1x1, images16x9 } from './images'
import { contentList } from '../Top/contentList'

const withAnimation = 3000
const animationFrame = 500

export const CarouselBlock = ({ data, page = 'main' }) => {
  const { firstLine, secondLine } = contentList[page] || contentList.main;

  const images = window.innerWidth > 1060 || window.innerWidth < 760 ? images1x1 : images16x9
  const video = window.innerWidth > 1060 || window.innerWidth < 760 ? data['1x1'] : data['16x9']
  const carouselRef = React.useRef(null)
  const sliderRef = React.useRef(null)
  const videoRef = React.useRef(null)

  const [slide, setSlide] = React.useState(0)
  const [isLoad, setIsLoad] = React.useState(false);
  const [play, setPlay] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [period, setPeriod] = React.useState(0);

  const right = React.useCallback(() => {
    carouselRef.current.style.justifyContent = 'flex-start'
    sliderRef.current.style.transform = `translate(-${100 / images.length}%)`
    setSlide(slide => slide < images.length - 1 ? slide + 1 : 0)
  }, [setSlide, slide])

  const nextItem = React.useCallback(() => {
    sliderRef.current.addEventListener('transitionend', () => {
      sliderRef.current.appendChild(sliderRef.current.firstElementChild)
      sliderRef.current.style.transition = 'none'
      sliderRef.current.style.transform = 'translate(0)'

      setTimeout(() => {
        sliderRef.current.style.transition = `all ${animationFrame}ms`
      })
    })
    return () => sliderRef.current.removeEventListener()
  }, [sliderRef])

  const onTimeUpdate = () => {
    const duration = videoRef.current.duration;
    const currentTime = videoRef.current.currentTime;
    const progress = (currentTime / duration) * 100;
    setProgress(progress);
  };

  const onCanPlay = () => {
    if (isLoad) return
    setPeriod(videoRef.current.duration.toFixed(2))
    setIsLoad(true)
  }

  React.useEffect(() => nextItem(), [nextItem])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      !isLoad ? right() : setPlay(true)
    }, withAnimation)
    return () => clearTimeout(timer)
  }, [right, isLoad])

  return (
    <div className={s.stol5_wrapper}>
      <div className={s.container}>
        <div className={s.left_side}>
          <div className={s.background_img} />
          <div className={s.left_content}>
            <div className={s.text1}>{firstLine}</div>
            <div className={s.text2}>{secondLine}</div>

            <div className={s.btnContainer}>
              <CoupleTopButtons />
            </div>
          </div>
        </div>

        <div className={s.right_side}>
          <div className={s.carousel_wrapper}>

            <div className={s.carousel_container}>
              <div className={s.carousel} ref={carouselRef}>
                <div
                  className={s.slider}
                  style={{
                    width: `${images.length * 100}%`,
                    transition: `all ${animationFrame}ms`
                  }}
                  ref={sliderRef}
                >
                  {!play && images.map((sliderImg, idx) => (
                    <img
                      key={idx}
                      style={{ width: `${100 / images.length}%`, }}
                      className={s.slider_item}
                      src={sliderImg}
                      alt="slider"
                    />
                  ))}
                  <video
                    onCanPlayThrough={onCanPlay}
                    onTimeUpdate={onTimeUpdate}
                    className={`${s.video_item}  ${play ? s.video_play : s.video_loading}`}
                    style={{ animationDuration: `${animationFrame}ms` }}
                    ref={videoRef}
                    autoPlay
                    muted
                    src={video}
                    playsInline
                    loop
                  />
                </div>
              </div>
            </div>
            <div className={s.controls}>
              <div className={s.video_controls}>
                {play && progress < 99
                  ? <svg viewBox='0 0 100 100'>
                    <path
                      fill='none'
                      strokeLinecap='round'
                      strokeWidth='14'
                      opacity='0.5'
                      stroke='#dfe8ed'
                      strokeDasharray='251.2,0'
                      d='M50 10 a 40 40 0 0 1 0 80 a 40 40 0 0 1 0 -80'
                    />
                    <path
                      fill='none'
                      strokeLinecap='round'
                      strokeWidth='14'
                      stroke='#FFFFFF'
                      strokeDasharray='251.2,0'
                      d='M50 10 a 40 40 0 0 1 0 80 a 40 40 0 0 1 0 -80'
                    >
                      <animate
                        attributeName='stroke-dasharray'
                        from='0,251.2'
                        to='251.2,0'
                        dur={`${period || 5}s`}
                      />
                    </path>
                  </svg>
                  : null
                }
              </div>
              <div className={s.images_controls}>
                {!play && images.map((_, idx) => (
                  idx < images.length
                    ? <div
                      className={`${s.images_control} ${idx === slide ? s.images_control_active : ''}`}
                      key={idx}
                    />
                    : null
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
