import React, {useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Scrollbar } from 'swiper';
import Loader from '../../../../Entity/loader/Loader';
import "./swiper-style.scss";
import styles from './meals-slider.module.scss';
import {isMobile} from "react-device-detect";
import {Tooltip} from "../../../../Entity/ToolTip/Tooltip";
import {MealTooltip} from "./MealToottip";

const Slide = ({ src, name }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      src !== null && setImgSrc(src);
      setLoading(false)
    };
  }, [src]);

  return loading
    ? <Loader/>
    : <img alt={name} src={imgSrc} className={loading ? styles.opacity : ''}/>
};

const MealsSlider = ({ dishesList, stats}) => {
  //TODO do not remove it. For SSR.
  const [innerWidth, setInnerWidth] = useState(0);
  useEffect(() => setInnerWidth(window.innerWidth), []);
  if(!stats && !dishesList) return <></>
  return (
    <div className={styles.slider} data-target="meals">
      <Swiper
        id='swiper'
        className={`${styles.swiper} meals-carousel`}
        modules={[Scrollbar]}
        slidesPerView="auto"
        scrollbar={{draggable: true}}
      >
        {dishesList.map(dish => (
          <SwiperSlide key={dish.id.toString()} style={{width: innerWidth >= 767 ? 370 : 270}}>
            <div className={styles.card} data-target="card">
              <div className={styles.cardLink}>
                <div className={styles.cardImage}>
                  {!dish.smallPhotoUrl
                    ? <p className={styles.empty}><span className={styles.emptyText}>Фото скоро появится</span></p>
                    : <Slide src={dish.smallPhotoUrl} name={dish.name}/>
                  }
                </div>
                <div className={styles.cardInfo}>
                  <div className={styles.cardInfoDish}>{dish.mealName}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>{dish.weight} г.&nbsp;</div>
                    <div>{dish.calories} ккал.</div>
                    <div style={{marginLeft: '15px', paddingTop: '3px'}}>
                    <Tooltip>
                      <MealTooltip
                        calories={dish.calories}
                        proteins={dish.proteins}
                        lipids={dish.lipids}
                        carbons={dish.carbons}
                      />
                    </Tooltip>
                    </div>
                  </div>
                </div>
                <p className={styles.cardName}>{dish.name}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.stats}>
        <ul className={styles.list}>
          <li className={styles.item}>
            <span className={styles.title}>{isMobile ? 'Кал.' : 'Калорийность '}</span>
            <span className={styles.value}>{stats?.calories} ккал</span></li>
          <li className={styles.item}>
            <span className={styles.title}>{isMobile ? 'Б.' : 'Белки '}</span>
            <span className={styles.value}>{stats?.proteins} г.</span>
          </li>
          <li className={styles.item}>
            <span className={styles.title}>{isMobile ? 'Ж.' : 'Жиры '}</span>
            <span className={styles.value}>{stats?.lipids} г.</span>
          </li>
          <li className={styles.item}>
            <span className={styles.title}>{isMobile ? 'У.' : 'Углеводы '}</span>
            <span className={styles.value}>{stats?.carbons} г.</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MealsSlider;
