import React from "react";
import s from './dropdowns.module.scss'
import { ArrowIcon } from "../../icons/AuthPopupIcons";

export const SimpleSelect = ( { title, activeValue, options, onSelect, optionsTitle } ) => {
  const [isOpen, setIsOpen] = React.useState(false)
  React.useEffect(() => {
    const hideModal = ( event ) => {
      const isItAutocomplete = !!(event.target).closest(`[data-modal='simple_select']`);
      !isItAutocomplete && setIsOpen(false);
    }
    document.addEventListener('click', hideModal);
    return () => document.removeEventListener('click', hideModal);
  }, [isOpen]);
  return (
    <div style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
      <span className={`${s.title3} ${s.simple_title}`} >{ title }</span>
      <div
        className={ s.simple }
        data-modal='simple_select'
        onClick={ () => setIsOpen(true)}
        style={{ position: 'relative' }}
      >
        <span className={ `${ s.title3 } ${ s.simple_subtitle }` }>{ activeValue[optionsTitle] }</span>
        { isOpen && options.length
          ? <div className={ s.simple_menu }>
              { options.map(item => (
                <div
                  key={item.name}
                  className={ `
                    additional_option
                    ${ s.title3 }
                    ${ s.simple_menu_item }
                    ${ activeValue[optionsTitle] === item[optionsTitle] ? s.simple_menu_item_active : '' }
                  ` }
                  onClick={ e => {
                    e.stopPropagation()
                    onSelect(item)
                    setIsOpen(false)
                  } }
                >
                  { item[optionsTitle] }
                </div>
              )) }
            </div>
          : null
        }
        <div style={ { display: 'flex', alignItems: 'center', transform: (isOpen ? 'rotate(180deg)' : '') } }>
          <ArrowIcon/>
        </div>
      </div>
    </div>
  )
}
