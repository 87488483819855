import React, { useState } from "react";
import s from './profileOrder.module.scss'
import emitterClient from "../../emitterClient";
import { Modal } from "../../Entity/modal/modal";
import { SelectedDiet } from "../LandingPage/AuthPopup/components/SelectedDiet";
import { Order } from "../LandingPage/AuthPopup/Order";
import { SuccessRequest, BadRequest } from "../../Widgets/RequestComponents";
import { useCalculator, useOrderHook } from "../../../hooks";
import { DietPicker } from "../../Widgets/DietPicker/DietPicker";
import { Slider } from "../../Entity/Slider/Slider";
import { DayPriceTitle } from "../../Entity/DayPriceTitle/DayPriceTitle";

export const ProfileOrder = (
  {
    initialSettings,
    availableDeliveryDays,
    dietsOption,
    daysOption,
    prices,
    dayPrices,
  } ) => {
  const [tab, setTab] = React.useState('orderDetails')
  const [city, setCity] = React.useState(initialSettings.city)

  const [activeDiet, setActiveDiet] = React.useState(null)
  const [currentDiet, setCurrentDiet] = React.useState(null)
  const [activeExtraOption, setActiveExtraOption] = React.useState(null)
  const [extraOptions, setExtraOptions] = React.useState(null)

  const [days, setDays] = React.useState(initialSettings.days);
  const [deliveryDay, setDeliveryDay] = React.useState({
    ...availableDeliveryDays[0],
    title: `${ availableDeliveryDays[0].dateInterface } (${ availableDeliveryDays[0].weekday })`
  })
  const [promo, setPromo] = React.useState({ code: '', isCurrent: false })
  const [isCash, setIsCash] = React.useState(false)
  const [promoError, setPromoError] = React.useState('')
  const [price, setPrice] = useState(null);

  const [isOpen, setIsOpen] = React.useState(false)
  const [messageText, setMessageText] = React.useState(null)

  const activeDietOrExtraDietName = activeExtraOption && activeExtraOption?.name !== 'default' ? activeExtraOption.name : activeDiet?.diet;

  const { dietTitle, selectDiet, changeDays, selectExtraDiet } = useCalculator({
    activeDiet,
    dietsOption,
    setActiveDiet,
    setPrice,
    city,
    dayPrices,
    prices,
    days,
    activeExtraOption,
    setActiveExtraOption,
    daysOption,
    setExtraOptions,
    setDays,
    currentDiet,
    setCurrentDiet,
  })
  const { acceptOrder, checkPromo, isRecurrent, setIsRecurrent } = useOrderHook({
    onSuccess: () => {
      setIsOpen(true)
      setMessageText('Success')
    },
    onFail: ( reason ) => {
      setIsOpen(true)
      setMessageText(reason ? reason : 'Во время оплаты произошла ошибка')
    },
    days,
    date: deliveryDay.date,
    dietName: () => {
      if(!activeDiet) return ''
      if (activeExtraOption) return activeExtraOption.name !== 'default' ? activeExtraOption.name : activeDiet.diet
      return activeDiet.diet
    },
    isCash,
    promo,
    onCompletePayment: () => {
      setMessageText('Success')
      setIsOpen(true)
    },
    onAcceptOrderFailed: (error) => {
      setIsOpen(true)
      setMessageText(error)
    },

    city,
    onPromoSuccess: ( newPrice ) => {
      setPrice({ ...price, total: newPrice })
      setPromo({ ...promo, isCurrent: true })
    },
    onPromoFailed: (errorDetail) => {
      setPromo({ ...promo, isCurrent: false })
      setPromoError(errorDetail)
    },
  })

  React.useEffect(() => {
    const setUserCity = ( id ) => {
      setPrice({
        day: dayPrices[activeDietOrExtraDietName][id][days]["with_breakfast"],
        total: prices[activeDietOrExtraDietName][id][days]["with_breakfast"]
      })
      setCity(id)
    }
    emitterClient.on("CITY_SELECTED", setUserCity);

    return () => {
      emitterClient.removeListener("CITY_SELECTED", setUserCity)
    }
  }, [activeDietOrExtraDietName])

  React.useEffect(() => {
    setActiveDiet({ type: initialSettings.dietType, diet: initialSettings.diet })
    setCurrentDiet(initialSettings.diet)
    const options = dietsOption.find(d => d.dietType === initialSettings.dietType)?.diets.find(d => d.diet === initialSettings.diet)?.options
    if (options) {
      setExtraOptions([{ name: 'default', title: 'Базовое' }, ...options])
      setActiveExtraOption({ name: 'default', title: 'Базовое' })
    }
    setPrice({
      day: dayPrices[initialSettings.diet][initialSettings.city][initialSettings.days]["with_breakfast"],
      total: prices[initialSettings.diet][initialSettings.city][initialSettings.days]["with_breakfast"]
    })
  }, [dietsOption, prices, setPrice, setExtraOptions, setActiveExtraOption, dayPrices, setActiveDiet, setCity])

  const tabs = {
    orderDetails: (
      <div style={{display:'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: '1'}}>
        <div>
          <DietPicker
            dietsOption={ dietsOption }
            extraOptions={ extraOptions }
            activeExtraOption={ activeExtraOption }
            activeDiet={ activeDiet }
            selectExtraDiet={ selectExtraDiet }
            selectDiet={ selectDiet }
          />

          <div style={ { marginTop: 20 } }>
            <DayPriceTitle price={ price } page={'account'}/>
            <div className={ `days-slider` } style={ { marginTop: 15 } }>
              <Slider
                onChange={ changeDays }
                value={ days }
                option={ daysOption }
              />
            </div>
          </div>

          <div style={ { marginTop: 33 } }>
            <SelectedDiet
              days={ days }
              dietTitle={ dietTitle }
              price={ price }
            />
          </div>
        </div>

        <button style={ { marginBottom: '15px' } } onClick={ () => setTab('order') }>Заказать</button>
      </div>
    ),
    order: (
      <div style={ { paddingBottom: 15, flexGrow: 1, display: 'flex' } }>
        <Order
          changeOrder={ () => setTab('orderDetails') }
          availableDeliveryDays={ availableDeliveryDays }
          deliveryDay={ deliveryDay }
          setDeliveryDay={ setDeliveryDay }
          promo={ promo }
          setPromo={ setPromo }
          acceptOrder={ acceptOrder }
          days={ days }
          dietTitle={ dietTitle }
          price={ price }
          checkPromo={ checkPromo }
          promoError={ promoError }
          setIsCash={ setIsCash }
          isCash={ isCash }
          isRecurrent={ isRecurrent }
          setIsRecurrent={ setIsRecurrent }
        />
      </div>
    ),
  }

  if (!activeDiet) return <></>

  return (
    <div className={ s.wrapper }>
      <div className={ s.content_wrapper }>
        <div className={ s.details_wrapper }>
          <div className={ s.popup_title }>Заказ</div>
          <div className={ s.bread_wrapper }>
            <div className={ s.bread_active }></div>
            <div className={ tab === 'order' ? s.bread_active : s.bread }/>
          </div>
          { tabs[tab] }
        </div>
        { isOpen &&
          <Modal onClose={ () => setIsOpen(!isOpen) }>
            { messageText === 'Success'
              ? <div style={ { width: 430, height: 480, padding: '0 25px 25px 25px' } }>
                  <SuccessRequest
                    onOkay={ () => {
                      setIsOpen(!isOpen)
                      setMessageText(null)
                      setTab('orderDetails')
                    } }
                  />
                </div>
              : <div style={ { width: 430, height: 480, padding: '40px 25px 25px 25px' } }>
                  <BadRequest
                    onOkay={ () => {
                      setIsOpen(!isOpen)
                      setMessageText(null)
                    } }
                    errorText={ messageText ? messageText : '' }
                  />
                </div>
            }
          </Modal>
        }
      </div>
    </div>
  )
}
