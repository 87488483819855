import React from 'react'
import { Slider } from '../../Entity/Slider/Slider'
import { DietPicker } from '../DietPicker/DietPicker'
import { SelectedDiet } from '../../Pages/LandingPage/AuthPopup/components/SelectedDiet'
import s from './orderChange.module.scss'

export const OrderChange = (
  {
    extraOptions,
    dietsOption,
    selectDiet,
    selectExtraDiet,
    activeDiet,
    activeExtraOption,

    setSliderDays,
    daysOption,
    days,

    dietTitle,
    price,
    previousTab,
  }) => {
  return (
    <div className={s.change_order}>
      <div>
        <DietPicker
          dietsOption={ dietsOption }
          extraOptions={ extraOptions }
          activeExtraOption={ activeExtraOption }
          activeDiet={ activeDiet }
          selectExtraDiet={ selectExtraDiet }
          selectDiet={ selectDiet }
        />
        <div style={{ padding: '0 5px', marginTop: '15px' }}>
          { days &&
            <Slider
              onChange={ setSliderDays }
              value={ days }
              option={ daysOption }
            />
          }
        </div>
        <div style={{ marginTop: '40px' }}>
          <SelectedDiet
            days={days}
            dietTitle={dietTitle}
            price={price}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: '20px'}}>
        <button
          style={{ boxShadow: 'none', backgroundColor: '#fff', color: '#999999', width: '50%'}}
          onClick={previousTab}
        >
          Отменить
        </button>
        <button
          onClick={previousTab}
          style={{ width: '50%' }}
        >
          Сохранить
        </button>
      </div>
    </div>
  )
}
