import React from "react"
import ReactDadataBox from "./reactDadata"
import axios from "axios"

const payloadModifierStreet = (params) => {
  return { ...params, to_bound: { value: "street" } }
}

const payloadModifierHouse = (params) => {
  return {
    ...params,
    from_bound: { value: "house" },
    to_bound: { value: "house" },
    restrict_value: true,
  }
}

const buildPayloadModifierHouse = (street_fias_id) => {
  return (params) =>
    payloadModifierHouse({ ...params, locations: [{ street_fias_id }] })
}

class AddressInput extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { inputProps } = this.props
    return (
      <div className={this.props.wrapperClassName || ""}>
        <span className={this.props.labelClassName || ""}>
          {this.props.label}
        </span>
        <div className={this.props.inputWrapperClassName}>
          {inputProps ? <input {...inputProps} /> : this.props.children}
        </div>
      </div>
    )
  }
}

class DefaultAddress extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { inputProps } = this.props
    return (
      <div className={this.props.wrapperClassName || ""}>
        <span className={this.props.labelClassName || ""}>
          {this.props.label}
        </span>
        <div className={this.props.inputWrapperClassName}>
          <span></span>
          {inputProps ? <input type="checkbox" {...inputProps} /> : this.props.children}
        </div>
      </div>
    )
  }
}

class DestroyAddress extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { inputProps } = this.props
    if(inputProps.addressid){
      return (
        <div className={this.props.wrapperClassName || ""}>
          <span className={this.props.labelClassName || ""}>
            {this.props.label}
          </span>
          <div className={this.props.inputWrapperClassName}>
            <span></span>
            {inputProps ? <input type="checkbox" {...inputProps} /> : this.props.children}
          </div>
        </div>
      )
    }
    else{
      return("")
    }
  }
}

class AddressInputStrict extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <AddressInput
        wrapperClassName={this.props.wrapperClassName}
        labelClassName={this.props.labelClassName}
        label={this.props.label}
        inputWrapperClassName={this.props.inputWrapperClassName}
      >
        <ReactDadataBox
          type={"address"}
          token={this.props.token}
          payloadModifier={this.props.payloadModifier}
          onChange={this.props.onChange}
          query={this.props.query}
          count={this.props.count || 10}
          silentQuery={this.props.silentQuery}
        />
      </AddressInput>
    )
  }
}

const addressWrapper = (WrappedComponent) => {
  class AddressWrapper extends React.Component {
    constructor(props) {
      super(props)
      if (this.props.address) {
        this.state = {
          street: "",
          house: "",
          placement: "",
          entrance: "",
          floor: "",
          intercom_code: "",
          comment: "",
          metaData: {},
          default: false,
          _destroy: false,
          ...this.props.address,
        }
      } else {
        this.state = {
          street: "",
          house: "",
          placement: "",
          entrance: "",
          floor: "",
          intercom_code: "",
          comment: "",
          default: false,
          _destroy: false,
          metaData: {},
        }
      }
      this.onStreetSelect = this.onStreetSelect.bind(this)
      this.onHouseSelect = this.onHouseSelect.bind(this)
      this.onChange = this.onChange.bind(this)
      this.onChecked = this.onChecked.bind(this)
      this.onCheckedDestroy = this.onCheckedDestroy.bind(this)
    }

    onStreetSelect(suggestion) {
      const {
        data: { street_fias_id },
      } = suggestion
      this.setState({ valid: true, street: suggestion.value, street_fias_id })
    }

    fetchDistrict(unrestricted_value) {
      axios
        .post(
          "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
          { query: unrestricted_value, count: 1 },
          { headers: { authorization: `Token ${this.props.dadata_api_key}` } },
        )
        .then(
          function (response) {
            const {
              data: {
                city,
                city_district_with_type,
                city_with_type,
                area_with_type,
                city_area,
              },
            } = response.data.suggestions[0]
            this.setState({
              metaData: {
                city,
                city_district_with_type,
                city_with_type,
                area_with_type,
                city_area,
              },
            })
          }.bind(this),
        )
    }

    onHouseSelect(suggestion) {
      const { value, unrestricted_value } = suggestion
      this.setState({ valid: true, house: value })
      this.fetchDistrict(unrestricted_value)
    }

    onChange(e) {
      this.setState({ [e.target.name]: e.target.value })
    }

    onChecked(e) {
      this.setState({ default: !this.state.default })
    }

    onCheckedDestroy(e) {
      this.setState({ _destroy: !this.state._destroy })
    }


    render() {
      return (
        <WrappedComponent
          onStreetSelect={this.onStreetSelect}
          onHouseSelect={this.onHouseSelect}
          onChange={this.onChange}
          onChecked={this.onChecked}
          onCheckedDestroy={this.onCheckedDestroy}
          {...this.props}
          addressProps={this.state}
        />
      )
    }
  }
  return AddressWrapper
}

export {
  addressWrapper,
  AddressInput,
  AddressInputStrict,
  payloadModifierStreet,
  buildPayloadModifierHouse,
  DefaultAddress,
  DestroyAddress
}
