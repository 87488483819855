import React from "react";

export const AppleIcon = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_745_1314)">
      <path d="M9.40325 5.21567C9.71969 4.6872 10.2204 3.93085 10.853 3.20511H10.8537C11.3894 2.58916 12.4384 1.79862 12.4384 1.79862C12.5961 1.67946 12.5792 1.5135 12.3999 1.42998L11.132 0.837435C10.9527 0.753556 10.709 0.814755 10.5902 0.972792C10.5902 0.972792 9.65237 2.31809 8.54861 5.18183C5.13581 3.63818 1.89185 5.8003 1.89185 9.56006C1.89185 13.4872 4.91333 19.4527 8.99933 17.652C13.3028 19.5142 16.109 13.4876 16.109 9.56006C16.109 5.7679 13.193 3.54458 9.40325 5.21567Z" />
      <path d="M8.6389 3.94378C8.83618 3.92722 9.01042 3.75262 9.02626 3.55571C9.02626 3.55571 9.15298 2.01998 8.0827 0.94864C7.00918 -0.123058 5.4763 0.00473906 5.4763 0.00473906C5.27902 0.0212987 5.10478 0.195895 5.08858 0.392811C5.08858 0.392811 4.9597 1.92926 6.03142 3.00096C7.10242 4.07158 8.6389 3.94378 8.6389 3.94378Z"/>
    </g>
    <defs>
      <clipPath id="clip0_745_1314">
        <rect width="18" height="18"/>
      </clipPath>
    </defs>
  </svg>
)
