import React from 'react';
import cx from 'classnames';
import { LogoSvg } from '../../svgs/LogoSvg';
import s from './Logo.module.scss';

export const Logo = ({ isResponsive = false }) => {
  return (
    <div className={cx(s.container, { [s.responsive]: isResponsive })}>
      <a href='/'>
        <LogoSvg style={s.image} />
      </a>
      <div className={s.name}>
        <a href='/'>
          <span>польза</span>
        </a>
      </div>
    </div>
  )
}
