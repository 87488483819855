import React from "react";
import s from "./auth-popap.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import InputMask from 'react-input-mask';
import {LandingPageContext} from "../LandingContext/LandingProvider";

export const PhoneConfirmation1 = ({setNumber, number, phoneAccept, isShowV2, setIsShowV2, numberError, setNumberError}) => {
  const [tokenV3, setTokenV3] = React.useState('')
  const [tokenV2, setTokenV2] = React.useState('')
  const  { executeRecaptcha }  =  useGoogleReCaptcha();

  const {reCaptchaV2Token} = React.useContext(LandingPageContext);

  const handleSubmit = () => {
    if (!isShowV2) {
      phoneAccept(tokenV3, 'v3')
      return
    }
    setIsShowV2(false)
    if(!tokenV2) return
    phoneAccept(tokenV2, 'v2')
  }

  React.useEffect(() => {
    if (!executeRecaptcha) return
    executeRecaptcha('auth_popup').then(token => setTokenV3(token))
  }, [executeRecaptcha, setTokenV3])

  return (
    <div className={s.phone1}>
      <div>
        <div className={s.title2}>Номер телефона</div>
        <InputMask
          mask="+7\(999) 999 99 99"
          value={number}
          onChange={e => {
            setNumberError(false)
            setNumber(e.target.value)
          }}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              data-target="input-mask"
              className={`${s.input} ${numberError ? s.error : ''} `}
              style={{marginTop: '5px', fontSize: '20px'}}
              placeholder='+7 (999) 999 99 99'
              inputMode="numeric"
            />
          )}
        </InputMask>
        {numberError &&
          <div
            className={s.errorMessage}
            style={{ marginTop: '5px' }}
            data-target='error'
          >Телефон введён наверно</div>
        }
        {isShowV2 &&
          <ReCAPTCHA
            sitekey={reCaptchaV2Token}
            onChange={setTokenV2}
          />
        }
      </div>
      <div>
        <span className={s.opacity_text}>
          Нажимая кнопку "Далее", Вы даете согласие на обработку
          <a className={s.link} target="blank" href='/confidetiality'> персональных данных</a>
        </span>
        <button
          data-target='next'
          style={{marginTop: '20px'}}
          onClick={handleSubmit}
        >
          Далее
        </button>
      </div>
    </div>
  )
}
