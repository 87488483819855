import React from 'react';
import cx from 'classnames';
import s from './header.module.scss';
import { Logo } from '../Entity/Logo/Logo';
import { NavMenu } from './NavMenu';
import { CONTACTS } from '../../constants/links';
import CitySelect from './CitySelect';
import { useScrollBar } from '../../hooks/useScrollBar';
import { useAuthContext, AUTH_ACTION } from '../../context/AuthContext';

const { OPEN_MODAL_ONLY_LOGIN } = AUTH_ACTION;

const { TEL } = CONTACTS;

export const Header = (props) => {
  const [isOpenMenu, setOpenMenu] = React.useState(false);
  const inPlace = useScrollBar();
  const { setOpenAuthAction } = useAuthContext();

  const userData = props.userData;

  const openMenuHandler = () => {
    setOpenMenu(!isOpenMenu);
  }

  const openAuthPopup = () => {
    setOpenAuthAction(OPEN_MODAL_ONLY_LOGIN)
  }

  return (
    <header className={cx(s.header, { [s.active]: !inPlace })}>
      <div className={s.container}>
        <div className={s.header__wrap}>
          <div className={s.header__logo_wrap}>
            <Logo isResponsive={true} />
          </div>
          <div
            className={cx(s.header__btn_menu, { [s.active]: isOpenMenu })}
            onClick={openMenuHandler}
          >
            <span className={s.header__btn_line}></span>
            <span className={s.header__btn_line}></span>
          </div>
          <CitySelect {...props} />
          <div className={cx(s.header__menu, { [s.active]: isOpenMenu })}>
            <div className={s.header__phone}>
              <div className={s.ya_phone}>
                <a href={`tel:${TEL.HREF}`} className={s.header__phone_link}>{TEL.VIEW}</a>
              </div>
            </div>
            <nav className={s.header__nav}>
              <NavMenu />
            </nav>
            <div className={s.header__btn_wrap}>
              {userData.userLoggedIn ? (
                <a href='/account' className={cx(s.btn, s.btn_white)}>Личный кабинет</a>
              ) : (
                <span
                  className={cx(s.btn, s.btn_white)}
                  onClick={openAuthPopup}
                >
                  Вход
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </header >
  );
}
