import React from "react";
import s from './faq.module.scss'
import {
  UpArrowIcon,
  DownArrowIcon,
  SnowIcon,
  AppleIcon,
  TruckIcon,
  HeartIcon
} from "../../../icons/FaqIcons";

const icons = {
  0: <HeartIcon />,
  1: <TruckIcon />,
  2: <AppleIcon />,
  3: <SnowIcon />,
}

export const Faq = ({qa, tabs: propsTabs}) => {
  const [tabs, setTabs] = React.useState([])
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [selected, setSelected] = React.useState([])

  const openAccordion = (idx) => {
    selected.some(e => e === idx)
      ? setSelected([...selected.filter(e => e !== idx)])
      : setSelected([...selected, idx])
  }

  React.useEffect(() => setTabs(propsTabs), [propsTabs])
  return (
    <div style={{ display: 'flex', justifyContent: 'center'}} itemScope itemType="https://schema.org/FAQPage">
      <div className={s.faq_wrapper}>
        <div className={s.tab_wrapper}>
          <h2 className={s.title}>Частые вопросы</h2>

          <div className={s.tab_limiter}>
            <div className={s.tabs}>
              {tabs.map((tab, idx) =>
                <div
                  onClick={() => {
                    setSelectedTab(idx)
                    setSelected([])
                  }}
                  className={`${s.tab} ${idx === selectedTab ? s.selected_tab : ''}`}
                  key={idx}
                >
                  <div className={`${s.tab_icon} ${idx === selectedTab ? s.tab_icon_selected : ''}`}>{icons[idx]}</div>
                  <div className={s.tab_text}>{tab}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          itemScope
          itemType="https://schema.org/Question"
          style={{ marginTop: '20px' }}
        >
          {qa[selectedTab].map(({q: question, a: answer}, idx) => {
            const isOpen = selected.some(e => e === idx)
            return (
              <div key={idx} className={s.accordion_Wrapper}>
                <div
                  itemProp='name'
                  className={`${s.question} ${isOpen ? s.active_q : ''}`}
                  onClick={() => openAccordion(idx)}
                >
                  {question}
                  {isOpen ? <UpArrowIcon/> : <DownArrowIcon/>}
                </div>

                {isOpen &&
                  <div
                    itemProp="suggestedAnswer"
                    itemScope
                    itemType="https://schema.org/Answer"
                    className={s.answer}
                  >
                    {answer}
                  </div>
                }
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
