import React from 'react'

export const InformIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g opacity="0.8">
      <path d="M11 10.98C11 10.7148 11.1054 10.4605 11.2929 10.2729C11.4804 10.0854 11.7348 9.98002 12 9.98002C12.2652 9.98002 12.5196 10.0854 12.7071 10.2729C12.8946 10.4605 13 10.7148 13 10.98V16.98C13 17.2452 12.8946 17.4996 12.7071 17.6871C12.5196 17.8747 12.2652 17.98 12 17.98C11.7348 17.98 11.4804 17.8747 11.2929 17.6871C11.1054 17.4996 11 17.2452 11 16.98V10.98ZM12 6.05103C11.7348 6.05103 11.4804 6.15638 11.2929 6.34392C11.1054 6.53145 11 6.78581 11 7.05103C11 7.31624 11.1054 7.5706 11.2929 7.75813C11.4804 7.94567 11.7348 8.05102 12 8.05102C12.2652 8.05102 12.5196 7.94567 12.7071 7.75813C12.8946 7.5706 13 7.31624 13 7.05103C13 6.78581 12.8946 6.53145 12.7071 6.34392C12.5196 6.15638 12.2652 6.05103 12 6.05103Z" fill="#999999"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12Z" fill="#999999"/>
    </g>
  </svg>
)
