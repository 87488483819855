import {
  HOW_IT_WORKS,
  CALC_MENU,
  REVIEWS,
  DELIVERY,
} from "../../constants/scrollList"

export const navList = [
  {
    id: HOW_IT_WORKS,
    name: "Как это работает?",
  },
  {
    id: CALC_MENU,
    name: "Меню и цены",
  },
  {
    id: REVIEWS,
    name: "Отзывы",
  },
  {
    id: DELIVERY,
    name: "Доставка",
  },
]
