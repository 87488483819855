import React from "react";
import s from '../auth-popap.module.scss'
import { Rub } from "../../../../Entity/Currency/Rub";

export const SelectedDiet = ({days, dietTitle, price}) => {
  return (
    <div className={s.dietWrapper}>
      <div id='diet_title' style={{fontWeight: '400', fontSize: '20px'}}>{dietTitle}</div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <div className={s.text} style={{ color: '#BEBEBE', margin: '0 5px 0 0' }}> Стоимость заказа</div>
          <div id='price' className={s.text} style={{ color: '#FF6938' }}>
            {price?.total || 0} <Rub/>
          </div>
        </div>
        <div>
          <span className={s.text} style={{ color: '#FF6938' }}>{days} дней</span>
        </div>
      </div>
    </div>
  )
}
