import React from "react";
import Lottie from "lottie-react";
import loaderAnimation from "./loader.json";

import styles from './loader.module.scss';

const Loader = () => {
  const animation = JSON.parse(JSON.stringify(loaderAnimation));

  return (
    <div className={styles.loader}>
      <Lottie animationData={animation} loop={true} />
    </div>
  );
}

export default Loader;
