export const getCookies = (name) => {
  const matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ))
  return matches && matches[1]
};

export const setCookies = (name, value) => {
  document.cookie = `${name}=${value}`
}
