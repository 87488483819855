import React from "react";
import { checkPromoCode, createOrder } from "../api/authPopup";
import { objectToCamel } from "../helpers/renameVariablesHelper";

export const useOrderHook = ({
  onSuccess,
  onFail,
  days,
  date,
  dietName,
  isCash,
  promo,
  onCompletePayment,
  city,
  onPromoSuccess,
  onPromoFailed,
  onAcceptOrderFailed
}) => {
  const [isRecurrent, setIsRecurrent] = React.useState(false);

  const acceptOrder = () => {
    const paymentWidget = new cp.CloudPayments();
    const paymentOptions = { onSuccess, onFail, }

    createOrder({
      days,
      first_delivery_at: date,
      diet: dietName(),
      cash: isCash,
      ...(promo.isCurrent && { promo_code: promo.code })
    })
      .then(({ data: { payment } }) => {
        const { recurrent, receipt, ...paymentData } = payment;
        const customerReceipt = {
          receipt: {
            Items: [...receipt.items],
          },
        };
        const recurrentData = {
          ...paymentData,
          data: {
            CloudPayments: {
              CustomerReceipt: customerReceipt,
              recurrent: {
                ...objectToCamel(recurrent),
                customerReceipt,
              }
            }
          }
        };

        isCash
          ? onCompletePayment()
          : isRecurrent
            ? paymentWidget.charge(recurrentData, onSuccess, onFail)
            : paymentWidget.pay('charge', paymentData, paymentOptions)

      })
    .catch(error => onAcceptOrderFailed(error.response.data.errors.status))
  }

  const checkPromo = () => {
    if (!promo.code) return

    return checkPromoCode({
      promo: promo.code,
      days,
      diet: dietName(),
      city,
    }).then(( { data } ) => {
      onPromoSuccess(data.new_price)
      return true
    }).catch(error => {
      onPromoFailed(error.response.data.errors.detail, error.response.data.errors.status)
      return false
    })
  }

  return {
    acceptOrder,
    checkPromo,
    isRecurrent,
    setIsRecurrent,
  }
}
