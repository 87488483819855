import React from "react"
import axios from "axios"

export default class DeliveryChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      request_status: "idle",
      error: null,
      message: null,
      deliveries: [].concat(this.props.deliveries),
    }
  }

  handleChangeSelect = (date) => (delivery_time_range_id) => {
    const { api_path } = this.props

    this.setState((state) => ({
      ...state,
      request_status: "pending",
    }))
    axios
      .patch(api_path, {
        date,
        delivery_time_range_id,
      })
      .then((response) => {
        let delivery_date = response.data
        this.setState((state) => ({
          ...state,
          request_status: "success",
          deliveries: state.deliveries.map((delivery) =>
            delivery.production_date === date
              ? { production_date: date, delivery_date: delivery_date, delivery_time_range_id }
              : delivery,
          ),
        }))
      })
      .catch((error) =>
        this.setState((state) => ({
          ...state,
          request_status: "error",
          error: error.message,
          message: error.response.data.message
        })),
      )
  }

  render() {
    const { deliveries, request_status, error, message } = this.state
    const { delivery_time_options } = this.props

    if (request_status === "error") {
       alert(message)
    }

    return (
      <table className="table table--fixed">
        <thead>
          <tr>
            <th width="40%">Дата доставки</th>
            <th width="60%">Время доставки</th>
          </tr>
        </thead>
        <tbody>
          {deliveries.map((delivery, index) => (
            <tr key={`delivery-${index}`}>
              <td>{delivery.delivery_date}</td>
              <td>
                <DeliverySelect
                  disabled={request_status === "pending"}
                  value={
                    delivery_time_options.find(
                      (option) => option.id === delivery.delivery_time_range_id,
                    ).name
                  }
                  options={delivery_time_options.filter(option => option.enabled === true)}
                  onChange={this.handleChangeSelect(delivery.production_date)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
}

export function DeliverySelect(props) {
  const { disabled, value = "Любое", options, onChange } = props

  const click = (id) => (event) => {
    event.preventDefault()
    onChange(id)
  }

  return (
    <div className="btn-group menu-select delivery-time-select">
      <button
        disabled={disabled}
        type="button"
        className="btn-menu dropdown-toggle delivery__toggle-button"
        data-toggle="dropdown"
      >
        <span className="btn-menu-text">{value}</span>
        <span className="caret"></span>
      </button>
      <ul className="dropdown-menu menu-list">
        {options.map((option) => (
          <li key={`delivery-time-${option.id}`}>
            <a href="#" onClick={click(option.id)}>
              {option.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
