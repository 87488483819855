import React from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import s from './header.module.scss';
import { CityPopup } from "./CityPopup";
import { useCityContext } from "../../context/CityContext";

const cookies = new Cookies();

const CitySelect = ({ cities, cityData, userData }) => {
  const { idToCityName } = cities;
  const {
    city,
    setCity,
    openCitySelectPopup,
    setOpenCitySelectPopup,
    isCitySelected,
    setIsCitySelected,
  } = useCityContext();

  const openPopup = () => {
    setOpenCitySelectPopup(true);
  };

  const setCityHandler = (selectedId) => {
    const { userLoggedIn } = userData;
    if (userLoggedIn) {
      axios.put("/users/set_city", { city: selectedId });
      setCity(selectedId);
    } else {
      setCity(selectedId);
      cookies.set("current_city", selectedId, {
        expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
      });
    }
    if (isCitySelected !== 'selected') setIsCitySelected('selected');
    onClose();
  };

  const onClose = () => {
    setOpenCitySelectPopup(false);
  };

  return (
    <div>
      <CityPopup
        cityData={cityData}
        cities={cities}
        open={openCitySelectPopup}
        onClose={onClose}
        setCity={setCityHandler}
      />
      <div id="citySelect" className={s.header__city_select} onClick={openPopup}>
        {idToCityName[city]}
        <i />
      </div>
    </div>
  );
};

export default CitySelect;
