import React, {useEffect} from "react";
import s from "../auth-popap.module.scss";
import Loader from "../../../../Entity/loader/Loader";
import {AcceptIcon, UnacceptIcon} from "../../../../icons/AuthPopupIcons";

export const PromoInput = ({ promoError: propsError, setPromo, checkPromo, promo }) => {
  const [value, setValues] = React.useState('button')
  const [promoError, setPromoError] = React.useState(propsError)

  const checkPromoCode = async () => {
    setValues('loading')
    const isCurrent = await checkPromo()
    isCurrent ? setValues('currentPromo') : setValues('wrongPromo')
  }

  const values = {
    loading: <div className={s.promo_loader}>
                <Loader/>
              </div>,
    button: <div className={s.title2} onClick={checkPromoCode}>
              Активировать
            </div>,
    currentPromo: <AcceptIcon/>,
    wrongPromo: <UnacceptIcon/>
  }

  useEffect(() => setPromoError(propsError), [propsError])

  return (
    <div style={{position: 'relative'}}>
      <span className={s.title2}>Промокод к заказу</span>
      <input
        className={`${s.input} ${value === 'currentPromo' ? s.success : s.error}`}
        value={promo.code}
        onChange={e => {
          setValues('button')
          setPromo({ ...promo, code: e.target.value})
          setPromoError('')
        }}
        placeholder='Промокод'
      />

      <div className={s.promo_button}>
        {values[value]}
      </div>

      {promoError.length ? <div className={s.promo_error}>{promoError}</div> : null}
    </div>
  )
}
