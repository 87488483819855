import React from "react";

export const CardIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5958 6.20428H4.40419C3.0785 6.20428 2 7.18975 2 8.40108V19.5994C2 20.8108 3.0785 21.7962 4.40419 21.7962H23.5958C24.9215 21.7962 26 20.8108 26 19.5994V8.40108C26 7.18975 24.9215 6.20428 23.5958 6.20428ZM4.40419 7.13397H23.5958C24.3607 7.13397 24.9825 7.70218 24.9825 8.40108V9.98046H3.01746V8.40108C3.01746 7.70218 3.6393 7.13397 4.40419 7.13397ZM23.5958 20.866H4.40419C3.6393 20.866 3.01746 20.2978 3.01746 19.5989V10.9101H24.9825V19.5989C24.9825 20.2978 24.3607 20.866 23.5958 20.866Z"
      fill="black"/>
    <path
      d="M22.6522 11.982H17.5224C17.2417 11.982 17.0137 12.1904 17.0137 12.4468V15.6739C17.0137 15.9304 17.2417 16.1388 17.5224 16.1388H22.6522C22.9329 16.1388 23.1609 15.9304 23.1609 15.6739V12.4468C23.1609 12.3236 23.1073 12.2053 23.0119 12.1181C22.9165 12.031 22.7871 11.982 22.6522 11.982ZM22.1434 15.2091H18.0311V12.9117H22.1434V15.2091Z"
      fill="black"/>
  </svg>
)
