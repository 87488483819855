import React from "react";

export const PencilIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white"/>
    <g clipPath="url(#clip0_793_2449)">
      <path d="M13.1755 8.67963L7.6734 14.1822C7.64572 14.2099 7.62573 14.245 7.61621 14.2827L7.00635 16.7305C6.98811 16.8042 7.00976 16.8826 7.06354 16.9363C7.10423 16.977 7.15967 16.9995 7.21638 16.9995C7.23375 16.9995 7.25152 16.9974 7.26881 16.993L9.71666 16.3831C9.75481 16.3736 9.78947 16.3537 9.81715 16.326L15.3198 10.8239L13.1755 8.67963Z" fill="#FF6938"/>
      <path d="M16.6835 7.92911L16.071 7.31663C15.6616 6.90728 14.9482 6.90768 14.5393 7.31663L13.7891 8.06688L15.9332 10.211L16.6835 9.46077C16.8879 9.25638 17.0006 8.98432 17.0006 8.69498C17.0006 8.40564 16.8879 8.13358 16.6835 7.92911Z" fill="#FF6938"/>
    </g>
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#FF6938"/>
    <defs>
      <clipPath id="clip0_793_2449">
        <rect width="10" height="10" fill="white" transform="translate(7 7)"/>
      </clipPath>
    </defs>
  </svg>
)
