import React from "react";

export const LandingPageContext = React.createContext({});

export const LandingProvider = ( { children, providerProps } ) => {
  const [user, setUser] = React.useState(null)
  const [reCaptchaV2Token, setReCaptchaV2Token] = React.useState(null)

  React.useEffect(() => {
    setUser({ ...providerProps.userData })
    setReCaptchaV2Token(providerProps.captchaKeys.v2)
  }, [providerProps, setReCaptchaV2Token, setUser])

  const landingContextValue = { user, setUser, reCaptchaV2Token }

  if (!user && !reCaptchaV2Token) return null

  return (
    <LandingPageContext.Provider value={ landingContextValue }>
      { children }
    </LandingPageContext.Provider>
  );
};
