import React from "react";
import MealsSlider from './calculatorComponents/MealsSlider';
import { getCookies, setCookies } from "../../../../helpers/coockiesHelper";
import s from "./calculator.module.scss";
import Broccoli from '../../../../../../app/assets/images/decor/broccoli.png';
import Carrot from '../../../../../../app/assets/images/decor/carrot.png';
import Mint from '../../../../../../app/assets/images/decor/decor-3.png';
import { DietPicker } from "../../../Widgets/DietPicker/DietPicker";
import { WeekString } from "./calculatorComponents/WeekString";
import { Slider } from "../../../Entity/Slider/Slider";
import { useCalculator } from "../../../../hooks";
import { Rub } from "../../../Entity/Currency/Rub";
import { DayPriceTitle } from "../../../Entity/DayPriceTitle/DayPriceTitle";
import { useScrollTo } from "../../../../hooks/useScrollTo";
import { ORDER, CALC_MENU } from "../../../../constants/scrollList";
import { useCityContext } from '../../../../context/CityContext';
import { useAuthContext, AUTH_ACTION } from '../../../../context/AuthContext';
import { useElementVisibilityObserver } from "../../../../hooks/useElementVisibilityObserver";
import { handleCalculatorOrderTracking } from "../../../../helpers/trackingsHelper";

const { OPEN_MODAL_CALCULATOR } = AUTH_ACTION;

export const Calculator = ( {
  initialSettings,
  dietsOption,
  daysOption,
  prices,
  dayPrices,
  initialWeekDay,
  nutritionStats,
  menu,
  dishes,
  oldPrices,
} ) => {
  const [activeDiet, setActiveDiet] = React.useState(null)
  const [currentDiet, setCurrentDiet] = React.useState(null)
  const [activeExtraOption, setActiveExtraOption] = React.useState(null)
  const [extraOptions, setExtraOptions] = React.useState(null)
  const { setOpenAuthAction, setAuthArguments } = useAuthContext();

  const { city, isCitySelected, setOpenCitySelectPopup } = useCityContext();
  const [weekDay, setWeekDay] = React.useState(initialWeekDay)
  const [days, setDays] = React.useState(null);
  const [price, setPrice] = React.useState(null);
  const activeDietOrExtraDietName = activeExtraOption && activeExtraOption?.name !== 'default' ? activeExtraOption.name : activeDiet?.diet;
  const dishesList = activeDiet && menu[activeDietOrExtraDietName][weekDay]
    .reduce(( acc, val ) => ([...acc, {
      mealName: val.meal_name,
      ...(dishes.find(dish => dish.id === val.dish_id) || {})
    }]), [])

  const [orderButtonRef] = useScrollTo(ORDER, 'end');
  const [menuRef] = useScrollTo(CALC_MENU, 'start');
  const hasOpenedOnceRef = React.useRef(false);
  const { dietTitle, selectDiet, changeDays, selectExtraDiet } = useCalculator({
    activeDiet,
    dietsOption,
    setActiveDiet,
    setPrice,
    city,
    dayPrices,
    prices,
    days,
    activeExtraOption,
    setActiveExtraOption,
    setExtraOptions,
    setDays,
    currentDiet,
    setCurrentDiet,
  })

  const openCitySelectHandler = () => {
    if(isCitySelected !== 'selected' && !hasOpenedOnceRef.current) {
      setOpenCitySelectPopup(true);
      hasOpenedOnceRef.current = true;
    }
  }

  const [priceTargetRef] = useElementVisibilityObserver(openCitySelectHandler);

  const addOrder = () => {
    handleCalculatorOrderTracking()
    setCookies('daysCounter', days)
    setCookies('userDiet', activeDiet.diet)
    setOpenAuthAction(OPEN_MODAL_CALCULATOR)
    setAuthArguments({ days, activeDiet, activeExtraOption, price })
  }
  const initialFromCookies = ( diet, currentDays ) => {
    const dietType = dietsOption.find(option => option.diets.some(d => d.diet === diet))
    setActiveDiet({ type: dietType.dietType, diet: diet })
    setCurrentDiet(diet)

    const options = dietType.diets.find(d => d.diet === diet).options
    if (options) {
      setExtraOptions([{ name: 'default', title: 'Базовое' }, ...options])
      setActiveExtraOption({ name: 'default', title: 'Базовое' })
    }
    setPrice({
      day: dayPrices[diet][initialSettings.city][currentDays]["with_breakfast"],
      total: prices[diet][initialSettings.city][currentDays]["with_breakfast"]
    })
    setDays(Number(currentDays))
  }
  const selectInitial = ( currentDays ) => {
    setActiveDiet({ type: initialSettings.dietType, diet: initialSettings.diet })
    setCurrentDiet(initialSettings.diet)

    setPrice({
      day: dayPrices[initialSettings.diet][initialSettings.city][currentDays]["with_breakfast"],
      total: prices[initialSettings.diet][initialSettings.city][currentDays]["with_breakfast"]
    })
    const options = dietsOption.find(d => d.dietType === initialSettings.dietType)?.diets.find(d => d.diet === initialSettings.diet)?.options
    if (options) {
      setExtraOptions([{ name: 'default', title: 'Базовое' }, ...options])
      setActiveExtraOption({ name: 'default', title: 'Базовое' })
    }
    setDays(Number(currentDays))
  }

  React.useEffect(() => {
    if (activeDietOrExtraDietName && days) {
      setPrice({
        day: dayPrices[activeDietOrExtraDietName][city][days]["with_breakfast"],
        total: prices[activeDietOrExtraDietName][city][days]["with_breakfast"]
      })
    }
  }, [city])

  React.useEffect(() => {
    const diet = getCookies('userDiet')

    const isDietExist = diet && dietsOption.some(option => option.diets.some(d => d.diet === diet))
    const currentDays = getCookies('daysCounter') && isDietExist ? getCookies('daysCounter') : initialSettings.days
    isDietExist ? initialFromCookies(diet, currentDays) : selectInitial(currentDays)
  }, [dietsOption, prices, setPrice, setExtraOptions, setActiveExtraOption, dayPrices, setActiveDiet])

  return (
    <div style={ { position: 'relative' } } id="calc">
      <div className={ s.calc }>
        <img className={ s.decor_1 } src={ Mint } alt=""/>
        <div ref={menuRef} className={ s.block_title } style={ { display: 'flex', justifyContent: 'center' } }>Меню</div>

        <div style={ { margin: '30px auto 0' } }>
          <div style={ { width: '100%', display: 'flex', justifyContent: 'center' } }>
            <div style={ { width: '430px' } }>
              <DietPicker
                dietsOption={ dietsOption }
                extraOptions={ extraOptions }
                activeExtraOption={ activeExtraOption }
                activeDiet={ activeDiet }
                selectExtraDiet={ selectExtraDiet }
                selectDiet={ selectDiet }
              />
            </div>
          </div>
          <WeekString
            currentDate={ weekDay }
            onChange={ ( index ) => setWeekDay(index + 1) }
          />

          <MealsSlider
            dishesList={ dishesList }
            stats={ dietTitle && nutritionStats[activeDietOrExtraDietName][weekDay] }
          />
          <div className={ s.total_wrapper }>
            <DayPriceTitle price={ price } page={'calculator'}/>
            <div className={ s.total_wrapper_slider }>
              { days &&
                <Slider
                  onChange={ changeDays }
                  value={ days }
                  option={ daysOption }
                />
              }
            </div>
            <div className={ s.result }>
              <span className={ s.result_old }>{ activeDietOrExtraDietName ? oldPrices[activeDietOrExtraDietName][city][days] : '' }</span>
              <span className={ s.result_price } ref={priceTargetRef} data-target="price">{ price?.total || 0 }<Rub/></span>
            </div>
            <div
              ref={orderButtonRef}
              data-target='Заказать'
              className={s.btn}
              onClick={ addOrder }
            >
              Заказать
            </div>
          </div>
        </div>
      </div>
      <img className={ s.decor_3 } src={ Broccoli } alt=""/>
      <img className={ s.decor_4 } src={ Carrot } alt=""/>
    </div>
  )
}
