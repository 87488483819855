import React from "react";
import s from './auth-popap.module.scss'
import { autocompletesLoad } from "../../../../api/authPopup";
import { Autocomplete } from "../../../Entity/Selects";
import { useCityContext } from '../../../../context/CityContext';
import { handleRegistrationTracking } from "../../../../helpers/trackingsHelper";

export const UserInformation = ({ token, acceptRegistration, city }) => {
  const [tellByPhone, setTellByPhone] = React.useState(false)
  const [requiredError, setRequiredError] = React.useState(false)
  const { isCitySelected, setOpenCitySelectPopup } = useCityContext();

  const cities = {
    'St Petersburg': 'г Санкт-Петербург',
    'Moscow': 'г Москва'
  }

  const [userInfo, setUserInfo] = React.useState({
    name: '',
    street: '',
    building: '',
    apartment: '',
    houseEntrance: '',
    floor: '',
    doorPhone: '',
    comment: ''
  })
  const [street, setStreet] = React.useState('')
  const [building, setBuilding] = React.useState()

  const [streetList, setStreetList] = React.useState([])
  const [buildingList, setBuildingList] = React.useState([])

  React.useEffect(() => {
    const query = `${cities[city]}, `
    autocompletesLoad(query, token, 'street')
      .then(data => setStreetList(data.data.suggestions.map(item => item.value)))
  }, [token, setStreetList, city])

  React.useEffect(() => {
    if (isCitySelected !== 'selected') {
      setOpenCitySelectPopup(true);
    }
  }, [])

  const inputChange = (value, type) => {
    setRequiredError(false)
    setUserInfo({ ...userInfo, [type]: value })
  }
  const handleAccept = () => {
    handleRegistrationTracking();

    if (tellByPhone) {
      acceptRegistration({
        name: '',
        street: '',
        house: '',
        placement: '',
        entrance: '',
        floor: '',
        intercom_code: '',
        comment: ''
      })
      return
    }

    if (!(userInfo.name && userInfo.building && userInfo.street)) {
      setRequiredError(true)
      return
    }
    acceptRegistration({
      name: userInfo.name,
      street: userInfo.street,
      house: userInfo.building,
      placement: userInfo.apartment,
      entrance: userInfo.houseEntrance,
      floor: userInfo.floor,
      intercom_code: userInfo.doorPhone,
      comment: userInfo.comment
    })
  }

  const buildingName = (item) => {
    if (!item.house) return null
    const house = `${item.house_type} ${item.house}`
    const block = item.block ? item.block_type + ' ' + item.block : ''

    return house + ' ' + block
  }

  const chooseStreet = (value) => {
    inputChange(value, 'street')
    setRequiredError(false)
    setUserInfo({ ...userInfo, building: '', street: value })
    setStreet(value)
    setBuilding('')
    autocompletesLoad(value, token, 'house')
      .then(data => {
        const buildings = data.data.suggestions.map(item => buildingName(item.data)).filter(e => !!e)
        if (buildings.length) {
          setBuildingList(buildings)
        }
      })
  }

  const streetInputChange = (value) => {
    setUserInfo({ ...userInfo, building: '' })

    const query = `${cities[city]}, ${value}`
    autocompletesLoad(query, token, 'street')
      .then(data => setStreetList(data.data.suggestions.map(item => item.value)))

    if (!value) {
      setUserInfo({ ...userInfo, street: '' })
      setStreet('')
      setBuildingList([])
      return
    }

    setStreet(value)
  }

  const buildingInputChange = (value) => {
    setRequiredError(false)
    const query = `${userInfo.street} ${value}`
    setUserInfo({ ...userInfo, building: '' })

    if (!value) {
      setBuilding('')
      return
    }

    autocompletesLoad(query, token, 'house').then(data => {
      const buildings = data.data.suggestions.map(item => buildingName(item.data)).filter(e => !!e)
      buildings.length && setBuildingList(buildings)
    })

    setBuilding(value)
  }

  const Required = () => <span style={{ color: '#FF5D5D', opacity: tellByPhone ? 0.5 : 1 }}>*</span>
  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
      <div>
        <div>
          <span style={{ fontWeight: '500', fontSize: '18px', opacity: (tellByPhone ? 0.5 : 1) }}>Информация о заказчике</span>
        </div>

        <div style={{ marginTop: '10px' }}>
          <span className={tellByPhone ? s.inputTitleDisabled : s.title2}>
            Имя
            <Required />
          </span>
          <div style={{ height: '70px' }}>
            <input
              className={s.input + ' ' + (requiredError && !userInfo.name ? s.error : '')}
              data-target='name'
              value={userInfo.name}
              onChange={e => inputChange(e.target.value, 'name')}
              placeholder='Введите ваше имя'
              disabled={tellByPhone}
            />
            {requiredError && !userInfo.name &&
              <span className={s.errorMessage} data-target='error'>Введите имя</span>
            }
          </div>
        </div>

        <div style={{ marginTop: '30px' }}>
          <span style={{ fontWeight: '500', fontSize: '18px', opacity: (tellByPhone ? 0.5 : 1) }}>Адрес</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <span className={tellByPhone ? s.inputTitleDisabled : s.inputTitle}>
            Улица
            <Required />
          </span>
          <div style={{ height: '70px' }}>
            <Autocomplete
              value={street || userInfo.street}
              data-target='street'
              onSelect={chooseStreet}
              onInputChange={streetInputChange}
              disabled={tellByPhone}
              error={requiredError && !userInfo.street}
              placeholder={'Улица'}
              optionsList={streetList}
            />
            {requiredError && !userInfo.street &&
              <span className={s.errorMessage} data-target='error'>Выберите из списка</span>
            }
          </div>

          <div style={{ display: 'flex', marginTop: '20px' }}>
            <div style={{ width: '65%', marginRight: '10px' }}>
              <span className={tellByPhone ? s.inputTitleDisabled : s.title2}>
                Дом
                <Required />
              </span>
              <div style={{ height: '70px' }}>
                <Autocomplete
                  value={building || userInfo.building}
                  onSelect={value => {
                    inputChange(value, 'building')
                    setBuilding(value)
                    setRequiredError(false)
                  }}
                  data-target='building'
                  onInputChange={buildingInputChange}
                  disabled={tellByPhone}
                  error={requiredError && !userInfo.building}
                  placeholder={'Дом'}
                  optionsList={buildingList}
                />
                {requiredError && !userInfo.building &&
                  <span className={s.errorMessage} data-target='error'>Выберите из списка</span>
                }
              </div>
            </div>

            <div style={{ width: '35%' }}>
              <span className={tellByPhone ? s.inputTitleDisabled : s.title2}>
                Квартира
              </span>
              <input
                className={s.input}
                data-target='apartment'
                value={userInfo.apartment}
                onChange={e => inputChange(e.target.value, 'apartment')}
                placeholder='Квартира'
                disabled={tellByPhone}
              />
            </div>
          </div>

          <div style={{ display: 'flex', marginTop: '20px' }}>
            <div style={{ width: '33%', marginRight: '10px' }}>
              <span className={tellByPhone ? s.inputTitleDisabled : s.title2}>
                Подъезд
              </span>
              <input
                className={s.input}
                data-target='houseEntrance'
                value={userInfo.houseEntrance}
                onChange={e => inputChange(e.target.value, 'houseEntrance')}
                placeholder='Подъезд'
                disabled={tellByPhone}
              />
            </div>
            <div style={{ width: '33%', marginRight: '10px' }}>
              <span className={tellByPhone ? s.inputTitleDisabled : s.title2}>
                Этаж
              </span>
              <input
                className={s.input}
                data-target='floor'
                value={userInfo.floor}
                onChange={e => inputChange(e.target.value, 'floor')}
                placeholder='Этаж'
                disabled={tellByPhone}
              />
            </div>

            <div style={{ width: '120px' }}>
              <span className={tellByPhone ? s.inputTitleDisabled : s.title2}>
                Код домофона
              </span>
              <input
                className={s.input}
                data-target='doorPhone'
                value={userInfo.doorPhone}
                onChange={e => inputChange(e.target.value, 'doorPhone')}
                placeholder='Код'
                disabled={tellByPhone}
              />
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <span className={tellByPhone ? s.inputTitleDisabled : s.title2}>
              Коментарий к заказу
            </span>
            <input
              value={userInfo.comment}
              onChange={e => inputChange(e.target.value, 'comment')}
              className={s.input}
              placeholder='Коментарий к заказу'
              disabled={tellByPhone}
            />
          </div>

          <div style={{ display: 'flex', margin: '20px 0', alignItems: 'center' }}>
            <input
              value={tellByPhone}
              className={s.modalCheckbox}
              data-target="skip_address"
              type='checkbox'
              onChange={() => {
                setTellByPhone(!tellByPhone)
                setRequiredError(false)
              }}
            />
            <span className={s.text} style={{ color: '#252525' }}>Сообщу по телефону</span>
          </div>
        </div>
      </div>

      <button className='registration' onClick={handleAccept}>Далее</button>
    </div>
  )
}
