export const TELEGRAM_BOT = "https://t.me/polzadiet_bot"
export const TELEGRAM_CHANNEL = "https://t.me/polzadiet"
export const WHATS_APP = "https://wa.me/78007776875"
export const VK_GROUP = "https://vk.com/polza.diet"
export const INSTAGRAM = "https://www.instagram.com/polzadiet/"
export const OK_GROUP = "https://ok.ru/group/70000005123488/"

export const CONTACTS = Object.freeze({
  TEL: Object.freeze({
    HREF: "+78007776875",
    VIEW: "8 800 777-68-75",
  }),
  EMAIL: "hello@polza.diet",
})

export const MAIN_LINKS = [
  {
    name: "Меню",
    path: "/menu",
  },
  {
    name: "Доставка и оплата",
    path: "/dostavka_oplata",
  },
  {
    name: "Статьи",
    path: "http://articles.polza.diet/",
  },
  {
    name: "О нас",
    path: "/about",
  },
  {
    name: "Контакты",
    path: "/contacts",
  },
  // {
  //   name: 'Карта сайта',
  //   path: '/sitemap'
  // },
]

export const DIET_MENU_LINKS = [
  {
    name: "Стол № 1",
    path: "/stol1",
  },
  {
    name: "Стол № 9",
    path: "/stol9",
  },
  {
    name: "Стол № 5",
    path: "/stol5",
  },
  {
    name: "Холецистит",
    path: "/holetsistit",
  },
  {
    name: "Дуоденит",
    path: "/duodenit",
  },
  {
    name: "Аппендицит",
    path: "/appendicit",
  },
  {
    name: "Рефлюкс",
    path: "/refluks",
  },
  {
    name: "Язва",
    path: "/yazva",
  },
  {
    name: "Гастрит",
    path: "/gastrit",
  },
  {
    name: "Удаление желчного пузыря",
    path: "/udalenie_zhelchnogo_puzyrya",
  },
  {
    name: "Панкреатит",
    path: "/pankreatit",
  },
  {
    name: "Диабет",
    path: "/diabet",
  },
]

export const OFFER_LINK = "/oferta"
export const CONFIDENTIALITY_LINK = '/confidetiality'
