import React from "react";

export const CashIcon = () => (
  <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 7.75H1.5C1.22386 7.75 1 7.97386 1 8.25V19.25C1 19.5261 1.22386 19.75 1.5 19.75H24.5C24.7761 19.75 25 19.5261 25 19.25V8.25C25 7.97386 24.7761 7.75 24.5 7.75H23.5M3.5 7.75L20.5367 1.4221C20.7934 1.32673 21.0791 1.45568 21.1774 1.71132L23.5 7.75M3.5 7.75H23.5M19 14H22M4 14H7"
      stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="13" cy="13.75" r="3.5" stroke="black"/>
  </svg>
)
