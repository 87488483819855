import React from 'react';
import s from './header.module.scss';
import { useScrollContext } from '../../context/ScrollContext';
import { navList } from './navList';

export const NavMenu = () => {
  const { setScrollToElement } = useScrollContext();

  const handleScroll = (to) => {
    setScrollToElement(to);
  };

  return (
    <ul className={s.header__nav_list}>
      {navList.map(({ id, name }) => (
        <li className={s.header__nav_item} key={id}>
          <span
            className={s.header__nav_link}
            onClick={() => handleScroll(id)}
          >
            {name}
          </span>
        </li>
      ))}
    </ul>
  )
}
