import React from "react";
import { Modal } from '../../Entity/modal/modal';
import { BadRequest, SuccessRequest } from '../../Widgets/RequestComponents';
import { SelectedDiet } from '../LandingPage/AuthPopup/components/SelectedDiet';
import s from './checkoutPay.module.scss';
import { handleOrderTracking } from "../../../helpers/trackingsHelper";

export const CheckoutPay = ({ payment }) => {
  const { days, diet, amount } = payment;

  const [isOpen, setIsOpen] = React.useState(false)
  const [messageText, setMessageText] = React.useState(null)

  const payOrder = () => {
    const paymentWidget = new cp.CloudPayments();
    const { days, diet, receipt, ...paymentData } = payment;

    paymentWidget.pay('charge', paymentData, {
      onSuccess: () => {
        setIsOpen(true)
        setMessageText('Success')
      },
      onFail: (reason) => {
        setIsOpen(true)
        setMessageText(reason ? reason : 'Во время оплаты произошла ошибка')
      }
    });

    handleOrderTracking();
  }

  return (
    <div className={s.wrapper}>
      <div className={s.content_wrapper}>
        <SelectedDiet
          days={days}
          dietTitle={diet}
          price={{ total: amount }}
        />
        <div className={s.pay_button}>
          <button onClick={payOrder}>
            Оплатить
          </button>
        </div>
      </div>
      {isOpen &&
        <Modal onClose={() => setIsOpen(!isOpen)}>
          {messageText === 'Success'
            ? <div className={`${s.request_wrapper} ${s.request_wrapper_success}`}>
                <SuccessRequest
                  onOkay={() => {
                    window.location.replace('/account');
                    setIsOpen(!isOpen)
                    setMessageText(null)
                  }}
                />
              </div>
            : <div className={`${s.request_wrapper} ${s.request_wrapper_bad}`}>
                <BadRequest
                  onOkay={() => {
                    setIsOpen(!isOpen)
                    setMessageText(null)
                  }}
                  errorText={messageText}
                />
              </div>
          }
        </Modal>
      }
    </div>
  )
}
