import React from 'react'

export const FirstStepSvg = ({ style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 92" className={style}>
      <defs>
        <style>
          {`
            .work-1-cls-1 {
              fill: #fff;
            }
            .work-1-cls-2 {
              fill: #ff6938;
            }
          `}
        </style>
      </defs>
      <title>work-1</title>
      <g id="Page-1">
        <g id="mainpage">
          <g id="Group-38">
            <g id="Archives_1-line-53" data-name="Archives 1-line-53">
              <rect id="Rectangle" className="work-1-cls-1" x="1" width="72" height="91" />
              <polygon className="work-1-cls-2" points="62 79 12 79 12 18 19 18 19 20 14 20 14 77 60 77 60 20 55 20 55 18 62 18 62 79" />
              <rect className="work-1-cls-2" x="25" y="33" width="24" height="2" />
              <rect className="work-1-cls-2" x="25" y="49" width="24" height="2" />
              <rect className="work-1-cls-2" x="25" y="63" width="24" height="2" />
              <path className="work-1-cls-2" d="M70,92H4a4,4,0,0,1-4-4V14a4,4,0,0,1,4-4H20.35v2H4a2,2,0,0,0-2,2V88a2,2,0,0,0,2,2H70a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2H53.65V10H70a4,4,0,0,1,4,4V88A4,4,0,0,1,70,92Z" />
              <path className="work-1-cls-2" d="M56,20H18V14.75a7.41,7.41,0,0,1,2.36-5.87,8.28,8.28,0,0,1,6.2-2.21H28.9a8.63,8.63,0,0,1,16.22,0h2.37a8.46,8.46,0,0,1,6.1,2.26A7.61,7.61,0,0,1,56,14.8V20ZM20,18H54V14.75a5.64,5.64,0,0,0-1.79-4.37,6.42,6.42,0,0,0-4.67-1.71h-4l-0.17-.79a6.59,6.59,0,0,0-12.74,0l-0.17.79h-4a6.3,6.3,0,0,0-4.76,1.66A5.46,5.46,0,0,0,20,14.68V18Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
