import React from "react"
import Popup from "reactjs-popup"


export default class CashOrderPopup extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Popup open onClose={this.props.onClose}>
        <div
          className="popup"
          style={{
            display: "inline-block",
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: "50px",
          }}
        >
          {this.props.text}
          <div
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: 10,
              right: 0,
              cursor: "pointer",
            }}
            onClick={this.props.onClose}
          >
            <span
              className="header__btn-line"
              style={{ transform: "rotate(-45deg)", top: "13px", right: 0 }}
            ></span>
            <span
              className="header__btn-line"
              style={{
                transform: "rotate(45deg)",
                top: "13px",
                right: 0,
                width: "100%",
              }}
            ></span>
          </div>
        </div>
      </Popup>
    )
  }
}
