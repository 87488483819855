import React from "react"
import Cookies from "universal-cookie"
import axios from "axios"

import emitterClient from "./emitterClient"

const cookies = new Cookies()

export default class CitySelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = { city_data: this.props.city_data }
    this.openPopup = this.openPopup.bind(this)
    this.setCity = this.setCity.bind(this)
  }

  openPopup() {
    const { city_data, cities } = this.props
    emitterClient.emit("PUSH_POPUP", "cityPopup", 0, { city_data, cities })
  }

  componentWillMount() {
    emitterClient.on("CITY_SELECTED", this.setCity)
  }

  componentWillUnmount() {
    emitter.removeListener("CITY_SELECTED", this.setCity)
  }

  setCity(selectedId) {
    const {
      city_data: { id },
    } = this.state
    if (this.props.user_data.user_logged_in) {
      axios.put("/users/set_city", { city: selectedId })
      this.setState({ city_data: { id: selectedId } })
    } else {
      this.setState({ city_data: { id: selectedId, status: "selected" } })
      cookies.set("current_city", selectedId, {
        expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
      })
    }
  }

  render() {
    const {
      city_data: { id },
    } = this.state
    const {
      cities: { id_to_city_name },
    } = this.props
    return (
      <div>
        <div className="header__city-select" onClick={this.openPopup}>
          {id_to_city_name[id]}
          <i />
        </div>
      </div>
    )
  }
}
