import React from "react";
import {SuccessIcon} from "../../icons/AuthPopupIcons";
import s from "../../Pages/LandingPage/AuthPopup/auth-popap.module.scss";

export const SuccessRequest = ( {onOkay}) => {
  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ padding: '0 0 20px 0'}}>
          <SuccessIcon/>
        </div>
        <div className={s.title1}>Заказ успешно оформлен</div>
        <div className={s.subtitle1}>Наш колл-центр свяжется с вами в ближайшее время</div>
      </div>
      <button onClick={onOkay}>окей</button>
    </div>
  )
}
