import React from 'react';
import { LandingPage } from '../components/Pages/LandingPage/LandingPage';
import { ErrorBoundary } from "@appsignal/react";
import appsignal from "./appsignal";

export default ( props ) => {

  const dev = ( props ) => <LandingPage { ...props } />

  const prod = ( props ) => (
    <ErrorBoundary instance={ appsignal }>
      <LandingPage { ...props } />
    </ErrorBoundary>
  )

  return process.env.NODE_ENV === 'production' ? prod(props) : dev(props)
};
