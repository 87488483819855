import React from "react"
import EmailCollectPopup from "./emailCollectPopup"

export default class EmailCollectLanding extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <EmailCollectPopup {...this.props}>
        <h4>
          {" "}
          Хотите получать полезные статьи про здоровье и советы от нашего
          диетолога - сотрудника Центра гастроэнтерологии СПбГУ?{" "}
        </h4>
        <div className="email-popup__discount">
          А так же поучаствовать в еженедельном розыгрыше{" "}
          <span className="email-popup__discount--bold">
            бесплатной консультации
          </span>
        </div>
      </EmailCollectPopup>
    )
  }
}
