import React from "react";
import s from './meals-slider.module.scss'

export const MealTooltip = ({calories, proteins, lipids, carbons}) => {
  return (
    <div className={s.mealTooltipBody}>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div>Калорийность</div>
        <div className={s.title2}>{calories} ккал</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
      <div>Белки</div>
        <div className={s.title2}>{proteins} г.</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div>Жиры</div>
        <div className={s.title2}>{lipids} г.</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div>Углеводы</div>
        <div className={s.title2}>{carbons} г.</div>
      </div>
    </div>
  )
}
