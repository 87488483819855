export const recommendedList = Object.freeze({
  main: "по медицинским показаниям",
  stol1: "при диете Стол №1",
  stol9: "при соблюдение диеты Стол №9",
  stol5: "при соблюдение диеты Стол №5",
  holetsistit: "при хроническом холецистите",
  duodenit: "при хроническом дуодените",
  appendicit: "после удаления аппендицита",
  refluks: "рефлюксе",
  yazva: "при язве",
  gastrit: "при гастрите",
  udalenie_zhelchnogo_puzyrya: "после удаления желчного пузыря",
  pankreatit: "при панкреатите",
  diabet: "при Диабете 2 типа",
})
