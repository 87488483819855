export const handleOrderTracking = () => {
  const yaCounter = window.yaCounter48845753
  if (!sessionStorage.getItem("alreadyClicked") && yaCounter) {
    yaCounter.reachGoal("ORDER", {
      order_price: 2000,
      currency: "RUB",
    })
  }
  sessionStorage.setItem("alreadyClicked", "true")
}

export const handleRegistrationTracking = () => {
  const yaCounter = window.yaCounter48845753
  if (!sessionStorage.getItem("regAlreadyClicked") && yaCounter) {
    yaCounter.reachGoal("REGISTRATION")
  }
  sessionStorage.setItem("regAlreadyClicked", "true")
}

export const handleHeaderOrderTracking = () => {
  const yaCounter = window.yaCounter48845753
  if (yaCounter) {
    yaCounter.reachGoal("LANDING_HEADER_ORDER_CLICK")
  }
}

export const handleCalculatorOrderTracking = () => {
  const yaCounter = window.yaCounter48845753
  if (yaCounter) {
    yaCounter.reachGoal("LANDING_CALCULATOR_ORDER_CLICK")
  }
}
