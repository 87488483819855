import slide1_1_0 from '../../../../app/assets/images/stol5/1_1-0.jpg'
import slide1_1_1 from '../../../../app/assets/images/stol5/1_1-1.jpg'
import slide1_1_2 from '../../../../app/assets/images/stol5/1_1-2.jpg'
import slide1_1_3 from '../../../../app/assets/images/stol5/1_1-3.jpg'
import slide1_1_4 from '../../../../app/assets/images/stol5/1_1-4.jpg'
import slide1_1_5 from '../../../../app/assets/images/stol5/1_1-5.jpg'
import slide1_1_6 from '../../../../app/assets/images/stol5/1_1-6.jpg'

import slide16x9_0 from '../../../../app/assets/images/stol5/1_1-0.jpg'
import slide16x9_1 from '../../../../app/assets/images/stol5/1_1-1.jpg'
import slide16x9_2 from '../../../../app/assets/images/stol5/1_1-2.jpg'
import slide16x9_3 from '../../../../app/assets/images/stol5/1_1-3.jpg'
import slide16x9_4 from '../../../../app/assets/images/stol5/1_1-4.jpg'
import slide16x9_5 from '../../../../app/assets/images/stol5/1_1-5.jpg'
import slide16x9_6 from '../../../../app/assets/images/stol5/1_1-6.jpg'

export const images1x1 = [slide1_1_0, slide1_1_1, slide1_1_2, slide1_1_3, slide1_1_4, slide1_1_5, slide1_1_6]
export const images16x9 = [slide16x9_0, slide16x9_1, slide16x9_2, slide16x9_3, slide16x9_4, slide16x9_5, slide16x9_6]
