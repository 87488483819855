import React, {useMemo, useCallback, useState, useRef} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Scrollbar } from 'swiper';
import { useScrollTo } from '../../../../hooks/useScrollTo';
import { REVIEWS } from '../../../../constants/scrollList';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';

import User from '../../../../../../app/assets/images/reviews/user.svg';
import Add from '../../../../../../app/assets/images/reviews/add.svg';
import Home from '../../../../../../app/assets/images/reviews/home.svg';
import Search from '../../../../../../app/assets/images/reviews/search.svg';
import Heart from '../../../../../../app/assets/images/reviews/heart.svg';
import Bookmark from '../../../../../../app/assets/images/reviews/Bookmark.svg';
import Comment from '../../../../../../app/assets/images/reviews/Comment.svg';
import Share from '../../../../../../app/assets/images/reviews/Share.svg';

import Doctor_1_avatar from '../../../../../../app/assets/images/reviews/review-1-ava.jpg';
import Doctor_1 from '../../../../../../app/assets/images/reviews/review-1.jpg';
import Doctor_2_avatar from '../../../../../../app/assets/images/reviews/review-2-ava.jpg';
import Doctor_2 from '../../../../../../app/assets/images/reviews/review-2.jpg';
import Doctor_3_avatar from '../../../../../../app/assets/images/reviews/review-3-ava.jpg';
import Doctor_3 from '../../../../../../app/assets/images/reviews/review-3.jpg';

import styles from './reviews.module.scss';

const Notch = <svg width="164" height="34" viewBox="0 0 164 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.76855 1H159.184V16C159.184 25.3888 151.572 33 142.184 33H21.7685C12.3797 33 4.76855 25.3888 4.76855 16V1Z" fill="white"/>
  <path d="M5.00001 1H1.05519e-05C4.71047e-05 1 -2.6001e-05 1 1.05519e-05 1C2.6506 1 4.79945 3.14875 4.79945 5.79934C4.79945 5.86656 4.79806 5.93345 4.79533 6H5.00001V1Z" fill="white"/>
  <path d="M0 1C2.65059 1 4.77 3.36894 4.77 6.01953" stroke="#D9D9D9"/>
  <path d="M4.76855 6.00001V16C4.76855 25.3888 12.3797 33 21.7685 33H142.184C151.572 33 159.184 25.3888 159.184 16L159.23 6" stroke="#D9D9D9"/>
  <path d="M159 1H164C161.349 1 159.201 3.14875 159.201 5.79934C159.201 5.86656 159.202 5.93345 159.205 6H159V1Z" fill="white"/>
  <path d="M164 1C161.349 1 159.23 3.36894 159.23 6.01953" stroke="#D9D9D9"/>
</svg>;

const data = [
  {
    login: 'doctor_smirnovaolga',
    name: 'Смирнова Ольга Андреевна',
    position: 'Врач гастроэнтеролог - диетолог, нутрициолог РНИМУ им. Н.И. Пирогова',
    users: '100к',
    likes: '24.8 тыс.',
    avatar: Doctor_1_avatar,
    image: Doctor_1,
    content: '<span>doctor_smirnovaolga</span> Причина гастроэнтерологических заболеваний очень часто неправильное питание и конечно все слышали от врача гастроэнтеролога, что надо соблюдать диету. Да! Эту банальность знают все, а если капнуть вглубь проблемы? Часто проблемы именно в незнание самой сути щадящей диеты, отсутствие времени и желания организовать самостоятельно приготовление блюд и как следствие обеднение рациона. С нашим общим темпом жизни закупить нужные и качественные продукты, приготовить 5 видов блюд на день нереально. Польза диет решила эту проблему, именно для пациентов с заболеваниями желудочно-кишечного тракта, каждый день вы с учётом щадящего питания можете вкусно питаться и при этом не думать что вам можно только гречку и курицу. Рекомендую. Я закончила РНИМУ им. Н.И.Пирогова , затем прошла ординатуру по терапии в Московском клиническом научно-практическом центре ДЗМ и потом первичную переподготовку по гастроэнтерологи в Московском клиническом научно-практическом центре ДЗМ, автор более 30 научных статей, выступления на российских и международных конференциях',
  },
  {
    login: 'Belodedova_aa',
    name: 'Белодедова Александра',
    position: 'Гастроэнтеролог, центр гастроэнетологии СПБГУ',
    users: '2.1 тыс.',
    likes: '1 432',
    avatar: Doctor_2_avatar,
    image: Doctor_2,
    content: '<span>Belodedova_aa</span> Как питаться правильно, если не хватает времени на готовку? Мое знакомство с @polzadiet началось несколько лет назад. @polzadiet - это доставка лечебного питания для пациентов с заболеваниями ЖКТ и сахарным диабетом 2 типа. Меню подойдёт не только пациентам с этими заболеваниями, а так же здоровым людям, соблюдающим принципы здорового питания. Например, мне очень нравится меню "Диабет", так как там много овощей и других продуктов с низким гликемическим индексом. Мои любимые блюда из @polzadiet - маффины из цветной капусты с куриными слайсами, овощной салат с курицей с заправкой из творожного крема, так же люблю творожные запеканки и блинчики на овсяной или полбяной муке.',
  },
  {
    login: 'zdorovaya',
    name: 'Климова Татьяна Алексеевна',
    position: 'Фитнес-нутрициолог FPA',
    users: '1 млн',
    likes: '251 тыс.',
    avatar: Doctor_3_avatar,
    image: Doctor_3,
    content: '<span>zdorovaya</span> Хочешь сделать хорошо - сделай это сам, обычно я жила по такому принципу. Но это до тех пор, пока не познакомилась с действительно вкусной и качественной доставкой еды @polzadiet До этого какие только я ни пробовала, но всё было не то. Польза - это не просто доставка еды. Это доставка еды по медицинским показаниям, такое встретишь редко. Меню разрабатываются с врачом-диетологом, гастроэнтерологом. Подходит для питания с заболеваниями ЖКТ. Есть три варианта калорийности (1200, 1600, 2000), соответственно, подходит под разные цели - похудение, поддержание, набор веса.Есть щадящая диета, есть для диабетиков, базовое, рыбное, безлактозное.⠀На фото безлактозно-безглютеновое меню, брала себе на пробу, и скажу я вам - очень достойно вкусно, с доставкой на дом и разнообразно.Доставка работает в Москве и Санкт-Петербурге. Берите на заметку и сохраняйте себе ⠀Кто-нибудь уже пользовался доставкой от Пользы?',
  }
]

const Review = ({item}) => {
  const ref = useRef();
  const [collapse, setCollapse] = useState(true);

  const introText = useMemo(() => {
    if (item.content.length <= 170) {
      return item.content;
    }
    return (
      item.content.substring(
        0,
        item.content.lastIndexOf(' ', 170),
      ) + '...'
    );
  }, [item.content]);

  const content = useMemo(() => {
    return collapse ? introText : item.content;
  }, [collapse]);

  const collapseContent = useCallback((e) => {
    e.preventDefault();
    setCollapse(!collapse);
    ref.current.style.maxHeight = !collapse ? 'none' : ref.current.scrollHeight + 'px';
  }, [collapse]);

  return (
    <>
      <div className={`${styles.review__top} ${!collapse ? styles.review__top_shadow : ''}`} id='review'>
        <div className={styles.review__notch}>
          {Notch}
        </div>
        <div className={styles.review__header}>
          <div className={styles.review__user}>
            <div className={styles.review__avatar}>
              {item.avatar && <img src={item.avatar} alt={item.login}/>}
            </div>
            <p className={styles.review__login}>{item.login}</p>
          </div>
          <div className={styles.review__users}>
            {item.users} <img src={User} alt="" className={styles.review__usericon}/>
          </div>
        </div>
      </div>
      <div className={`${styles.review__body} ${!collapse ? styles.review__body_hide : ''}`} ref={ref}>
        <div className={styles.review__photo}>
          {item.image && <img src={item.image} alt={item.name}/>}
        </div>
        <div className={styles.review__actions}>
          <div className={styles.review__action}>
            <img src={Heart} alt=""/>
          </div>
          <div className={styles.review__action}>
            <img src={Comment} alt=""/>
          </div>
          <div className={styles.review__action}>
            <img src={Share} alt=""/>
          </div>
          <div className={`${styles.review__action} ${styles.review__action_right}`}>
            <img src={Bookmark} alt=""/>
          </div>
        </div>
        <p className={styles.review__likes}>Нравится: {item.likes}</p>
        <div className={styles.review__author}>
          <p className={styles.review__author_name}>{item.name}</p>
          <span className={styles.review__author_position}>{item.position}</span>
        </div>
        <div className={styles.review__content}>
          <p dangerouslySetInnerHTML={{__html: content}} />
        </div>
        <a href="client/app/components/Pages/LandingPage/Reviews/Reviews#" onClick={collapseContent} className={`${styles.review__more} ${!collapse ? styles.review__more_hide : ''}`}>
          {collapse ? 'Читать далее' : 'Скрыть'}
        </a>
      </div>
      <div className={styles.review__footer}>
        <div className={styles.review__icon}>
          <img src={Home} alt=""/>
        </div>
        <div className={styles.review__icon}>
          <img src={Search} alt=""/>
        </div>
        <div className={styles.review__icon}>
          <img src={Add} alt=""/>
        </div>
        <div className={styles.review__icon}>
          <img src={Heart} alt=""/>
        </div>
        <div className={`${styles.review__icon} ${styles.review__icon_round}`}>
          <img src={item.avatar} alt=""/>
        </div>
      </div>
    </>
  )
}

const Reviews = () => {
  const [reviewsRef] = useScrollTo(REVIEWS, 'start');

  return (
    <div className={styles.container}>
      <div ref={reviewsRef} className={` ${styles.block_title} ${styles.review__caption} `}>
        Нас рекомендует врачебное сообщество
      </div>
      <Swiper
        className={styles.review__wrap}
        modules={[Pagination, Scrollbar]}
        slidesPerView="auto"
        spaceBetween={10}
        pagination={{clickable: true}}
        scrollbar={{draggable: true}}
        breakpoints={{
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide className={styles.review__item} key={(item.login + index).toString()}>
              <Review item={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default Reviews;
