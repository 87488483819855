import React from "react";
import s from './slider.module.scss'

export const Slider = ( { onChange, value, option, } ) => {
  const maxStep = 100
  const sliderThumbWidth = 15
  const step = maxStep / (option.length - 1)

  const sliderRef = React.useRef(null)
  const [position, setPosition] = React.useState(step * (option.findIndex(option => option === value) || 0))
  const [buttonWidth, setButtonWidth] = React.useState(0)
  const inputPadding = buttonWidth ? buttonWidth / 2 - sliderThumbWidth / 2 : 0

  React.useEffect(() => {
    if (sliderRef?.current?.offsetWidth) {
      const buttonWidth = sliderRef.current.offsetWidth / option.length
      setButtonWidth(buttonWidth)
      const range = buttonWidth * option.findIndex(option => option === value) + inputPadding
      sliderRef.current.style.setProperty("--litters-range", range + 'px');
    }
  }, [sliderRef, value, inputPadding])

  return (
    <div className={ s.slider }>
      <div style={ { display: 'flex', width: '100%' } }>
        { option.map(( el, idx ) =>
          <div
            className={ s.slider_label + ' ' + (el === value ? s.slider_label_active : '') }
            data-target="days"
            key={ idx }
            style={ { width: buttonWidth } }
            onClick={ () => {
              onChange(el)
              setPosition(step * (option.findIndex(option => option === el) || 0))
            } }
          >
            { el }
          </div>
        ) }
      </div>
      <input
        className={ s.slider_input }
        style={ { padding: `0 ${ inputPadding }px` } }
        ref={ sliderRef }
        type='range'
        min='0'
        max={ maxStep }
        value={ position }
        step={ step }
        onChange={ e => {
          setPosition(e.target.value)
          onChange(option[e.target.value / step])
        } }
      />
    </div>
  )
}
