import React from 'react';
import cx from 'classnames';
import s from './howItWorks.module.scss';
import { FirstStepSvg } from '../svgs/FirstStepSvg';
import { SecondStepSvg } from '../svgs/SecondStepSvg';
import { ThirdStepSvg } from '../svgs/ThirdStepSvg';
import { FourthStepSvg } from '../svgs/FourthStepSvg';
import { recommendedList } from './recommendedList';
import { useScrollTo } from '../../hooks/useScrollTo';
import { HOW_IT_WORKS } from '../../constants/scrollList';

export const HowItWorks = ({ page = 'main' }) => {
  const recommend = recommendedList[page] || recommendedList.main;

  const [stepRef] = useScrollTo(HOW_IT_WORKS, 'center');

  return (
    <div className={s.process}>
      <div className={s.container}>
        <div className={cx(s.caption, s.process__caption)}>Как это работает?</div>
        <div ref={stepRef} className={s.process__wrap}>
          <div className={s.process__item}>
            <div className={s.process__icon}>
              <FirstStepSvg style={s.icon_work_1} />
            </div>
            <span className={s.process__text}>
              Наш диетолог разработал диетическое меню, рекомендованное {recommend}
            </span>
          </div>
          <div className={s.process__item}>
            <div className={s.process__icon}>
              <SecondStepSvg style={s.icon_work_2} />
            </div>
            <span className={s.process__text}>Готовим еду исключительно из свежих и натуральных продуктов</span>
          </div>
          <div className={s.process__item}>
            <div className={s.process__icon}>
              <ThirdStepSvg style={s.icon_work_3} />
            </div>
            <span className={s.process__text}>Бесплатно доставим ваш заказ на дом в пределах города</span>
          </div>
          <div className={s.process__item}>
            <div className={s.process__icon}>
              <FourthStepSvg style={s.icon_work_4} />
            </div>
            <span className={s.process__text}>Вы питаетесь правильно, вкусно и разнообразно</span>
          </div>
        </div>
      </div>
    </div >
  )
}
