import React from "react"
import emitterClient from "./emitterClient"

export default class AccountSettingsButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      delivery_time_range_id: this.props.user_settings.delivery_time_range_id,
    }
  }

  componentWillMount() {
    emitterClient.on("DELIVERY_TIME_SELECTED", (delivery_time_range_id) =>
      this.setState((state) => ({
        ...state,
        delivery_time_range_id,
      })),
    )
  }

  componentWillUnmount() {
    emitterClient.removeListener("DELIVERY_TIME_SELECTED")
  }

  openPopup = () => {
    const { delivery_time_range_id } = this.state
    const { delivery_time_options } = this.props
    emitterClient.emit("PUSH_POPUP", "accountSettingsPopup", 0, {
      delivery_time_options,
      delivery_time_range_id,
    })
  }

  render() {
    return (
      <button
        type="button"
        className="btn btn--border"
        onClick={this.openPopup}
      >
        Настройки
      </button>
    )
  }
}
