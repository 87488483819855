import React from 'react';
import { ErrorBoundary } from "@appsignal/react";
import appsignal from "./appsignal";
import { ProfileOrder } from "../components/Pages/ProfileOrder/ProfileOrder";
import { changeProfileProps } from "../helpers/renameVariablesHelper";

export default ( props ) => {
  const renamedProps = changeProfileProps(props)

  const dev = ( props ) => <ProfileOrder { ...props } />

  const prod = ( props ) => (
    <ErrorBoundary instance={ appsignal }>
      <ProfileOrder { ...props } />
    </ErrorBoundary>
  )

  return process.env.NODE_ENV === 'production' ? prod(renamedProps) : dev(renamedProps)
};
