import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

export const useScrollContext = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  const [scrollToElement, setScrollToElement] = useState(null);

  return (
    <ScrollContext.Provider value={{ scrollToElement, setScrollToElement }}>
      {children}
    </ScrollContext.Provider>
  );
};
