import React from 'react';
import cx from 'classnames';
import s from './dietMenu.module.scss';
import { ChevronSvg } from '../../svgs/ChevronSvg';
import { DIET_MENU_LINKS as dietMenuLinks } from '../../../constants/links';

export const DietMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const handleOutsideClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const getMenu = () => (
    <div className={cx(s.popup, {[s.close_menu]: !isOpen})}>
      {dietMenuLinks.map(({ name, path }, index) => (
        <a key={index} className={s.popup_link} href={path}>
          {name}
        </a>
      ))}
    </div>
  );

  return (
    <div className={s.container} ref={containerRef}>
      <div className={s.release_button} onClick={toggleMenu}>
        <span>Ссылки</span>
        <ChevronSvg isDown={isOpen} />
      </div>
      {getMenu()}
    </div>
  );
};
