import React from "react"
import Cookies from "universal-cookie"
import axios from "axios"
import Popup from "reactjs-popup"

const cookies = new Cookies()

export default class EmailCollectPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = { address: "", errors: [] }
    this.width = window.innerWidth > 900 ? 800 : window.innerWidth
    this.onClose = this.onClose.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState({ address: e.target.value })
  }

  onClose() {
    cookies.set("subscription_declined", true, {
      expires: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000),
    })
    this.props.onClose()
  }

  onSubmit(e) {
    e.preventDefault()
    const { address } = this.state
    if (this.validateEmail(address)) {
      axios
        .post(this.props.create_email_path, {
          email: { address, form_id: this.props.form_id },
        })
        .then(
          function (response) {
            this.props.onClose()
          }.bind(this),
        )
        .catch(
          function (error) {
            const { response } = error
            if (response.status == 422) {
              this.setState({ errors: response.data.errors })
            }
          }.bind(this),
        )
    } else {
      this.setState({ errors: ["имеет неверное значение"] })
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  renderErrors() {
    const { errors } = this.state
    if (errors.length == 0) {
      return null
    } else {
      return (
        <div className="alert alert-warning">
          Электронная почта {errors.join(", ")}
        </div>
      )
    }
  }

  render() {
    return (
      <Popup
        open
        contentStyle={{
          borderRadius: "20px",
          padding: "10px",
          margin: "auto",
          width: `${this.width}px`,
        }}
        onClose={this.onClose}
      >
        <div className="email-popup">
          <div
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: 10,
              right: 0,
              cursor: "pointer",
            }}
            onClick={this.onClose}
          >
            <span
              className="header__btn-line"
              style={{ transform: "rotate(-45deg)", top: "13px", right: 0 }}
            ></span>
            <span
              className="header__btn-line"
              style={{
                transform: "rotate(45deg)",
                top: "13px",
                right: 0,
                width: "100%",
              }}
            ></span>
          </div>
          {this.props.children}
          <div className="email-popup__email">
            {this.renderErrors()}
            <div className="visible-block__input-wrap">
              <input
                value={this.state.address}
                onChange={this.onChange}
                placeholder="address@yourmail.ru"
              />
            </div>
            <div className="visible-block__btn-wrap">
              <button className="btn" onClick={this.onSubmit}>
                {" "}
                Подписаться
              </button>
            </div>
          </div>
        </div>
        <div className="email-popup__image"></div>
      </Popup>
    )
  }
}
