import React from "react"
import emitterClient from "../emitterClient"
import deliveryLogo from '../../../../app/assets/images/delivery_logo.svg';
import interval from '../../../../app/assets/images/interval.svg';
import cash from '../../../../app/assets/images/cash.svg';
import pay_card from '../../../../app/assets/images/pay_card.svg';

export default class DeliveryAndPay extends React.Component {
  constructor(props) {
    super(props)
    this.state = { city: this.props.city_data.id }
    this.setCity = this.setCity.bind(this)
  }

  componentWillMount() {
    emitterClient.on("CITY_SELECTED", this.setCity)
  }

  componentWillUnmount() {
    emitter.removeListener("CITY_SELECTED", this.setCity)
  }

  setCity(id) {
    this.setState({ city: id })
  }

  render() {
    const { city } = this.state
    const { maps } = this.props

    return (
      <div className="delivery-and-pay lp-block" id="delivery">
        <div className="container">
          <span className="caption review__caption left">Оплата и доставка</span>
          <div className="delivery-and-pay__wrap">
            <div className="delivery-and-pay__desc">
              <div className="delivery-and-pay__desc--text">
                Карта доставки
              </div>
              <div className="delivery-and-pay__info-blocks__wrap">
                <div className="delivery-and-pay__info-blocks item">
                    <img src={deliveryLogo}></img>
                    <div className="delivery-and-pay__info-blocks text">Бесплатная доставка</div>
                </div>
                <div className="delivery-and-pay__info-blocks item">
                    <img src={interval}></img>
                    <div className="delivery-and-pay__info-blocks text">2-часовой интервал</div>
                </div>
              </div>
              <div className="ddelivery-and-pay__desc--item-list">
                <div className="delivery-and-pay__desc--item-color-green" />
                <div className="delivery-and-pay__desc--item-prompt"> Бесплатно </div>
              </div>

              <div className="delivery-and-pay__desc--item-list">
                <div className="delivery-and-pay__desc--item-color-yellow" />
                <div className="delivery-and-pay__desc--item-prompt">
                  {" "}
                  Уточните условия доставки{" "}
                </div>
              </div>
            </div>
            <div className="delivery-and-pay__map">
              <picture>
                <source srcSet={maps[city].desktop.webp} media="(min-width: 500px)" type="image/webp" />
                <source srcSet={maps[city].desktop.jpg} media="(min-width: 500px)" type="image/jpeg" />
                <source srcSet={maps[city].mobile.webp} type="image/webp" />
                <img src={maps[city].mobile.jpg} alt={maps[city]} />
              </picture>
            </div>
          </div>

          <div className="delivery-and-pay__description">
            <div className="delivery-and-pay__description block">
              <div className="delivery-and-pay__description block__title">
                Способ оплаты
              </div>
              <div className="delivery-and-pay__description block__description">
                <div className="delivery-and-pay__description block__description__wrap">
                  <img src={pay_card}></img>
                  <div className="delivery-and-pay__description block__description__text">Картой на сайте</div>
                </div>
                <div className="delivery-and-pay__description block__description__wrap">
                  <img src={cash}></img>
                  <div className="delivery-and-pay__description block__description__text">Наличными курьеру</div>
                </div>
              </div>
            </div>

            <div className="delivery-and-pay__description block">
              <div className="delivery-and-pay__description block__title">
                Дни доставки
              </div>
              <div className="delivery-and-pay__description block__text">
                По всем программам через день с рационом питания на 2 дня
                Это от 10 до 12 ланчбоксов, которые легко помещаются в холодильник
              </div>
            </div>

            <div className="delivery-and-pay__description block">
              <div className="delivery-and-pay__description block__title">
                Время доставки
              </div>
              <div className="delivery-and-pay__description block__text">
                Через день с рационом питания на 2 дня.
                Доставим в двухчасовой интервал с 18:00 до 00:00.
                Курьер будет ждать 15 минут, можно попросить оставить коробку у двери
                Действует заморозка
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
