import React from 'react'

export const CloudPaymentsSvg = () => {
  return (
    <svg width="78" height="44" viewBox="0 0 78 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="78" height="44" rx="4" fill="#E4CEC7" fillOpacity="0.2" />
      <mask id="mask0_407_2351" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="4" y="9" width="70" height="26">
        <path d="M74 9H4V34.8462H74V9Z" fill="white" />
      </mask>
      <g mask="url(#mask0_407_2351)">
        <path d="M34.3893 29.4125C35.2827 29.4125 35.9012 29 35.9012 28.0675C35.9012 27.1349 35.2827 26.7224 34.3893 26.7224H32.6612V29.4115L34.3893 29.4125ZM31.7581 25.9857H34.4877C35.7054 25.9857 36.8048 26.5652 36.8048 28.0659C36.8048 29.5581 35.7444 30.1081 34.507 30.1081H32.6612V32.859H31.7581V25.9857Z" fill="#0B1E46" />
        <path d="M38.9053 32.3581C39.9363 32.3581 40.5451 31.6417 40.5451 30.6498V30.3357L39.1113 30.4834C38.2863 30.5615 37.8429 30.8954 37.8429 31.4849C37.8444 31.9659 38.1584 32.3581 38.9053 32.3581ZM39.0129 29.9334L40.5446 29.7857V29.2977C40.5446 28.699 40.1712 28.2175 39.3269 28.2271C38.6298 28.2271 38.0803 28.5316 38.0402 29.2287H37.2452C37.2848 28.0617 38.1879 27.599 39.3254 27.599C40.6801 27.599 41.397 28.2586 41.397 29.4646V32.8614H40.6141V31.801C40.3 32.5275 39.6222 32.9791 38.6506 32.9791C37.4918 32.9791 36.991 32.3312 36.991 31.5164C36.9905 30.5615 37.6876 30.0612 39.0129 29.9334Z" fill="#0B1E46" />
        <path d="M47.1931 27.7142H47.9882V28.6888C48.2728 27.9912 48.8719 27.599 49.667 27.599C50.4524 27.599 51.0216 27.9719 51.2084 28.7182C51.493 28.0013 52.1115 27.599 52.9166 27.599C53.9085 27.599 54.5463 28.2175 54.5463 29.3859V32.8614H53.6838V29.6092C53.6838 28.804 53.3286 28.3033 52.6234 28.3033C51.8182 28.3033 51.2977 28.9415 51.2977 30.0019V32.8593H50.4427V29.6097C50.4427 28.8045 50.079 28.3037 49.3722 28.3037C48.5671 28.3037 48.0567 28.942 48.0567 30.0024V32.8599H47.1941L47.1931 27.7142Z" fill="#0B1E46" />
        <path d="M59.2758 29.9623C59.2564 28.8918 58.6867 28.2241 57.8133 28.2241C56.9685 28.2241 56.3419 28.8136 56.2617 29.9623H59.2758ZM57.8137 27.5959C59.2849 27.5959 60.1308 28.4894 60.1308 30.4138V30.5315H56.2526C56.3216 31.7294 56.9593 32.3479 57.8828 32.3479C58.5699 32.3479 59.0313 31.9928 59.2478 31.4641L60.0625 31.5148C59.797 32.4083 59.0415 32.9775 57.8806 32.9775C56.3977 32.9775 55.3865 32.1034 55.3865 30.2885C55.388 28.4798 56.4088 27.5959 57.8137 27.5959Z" fill="#0B1E46" />
        <path d="M61.0365 27.7137H61.8313V28.7056C62.1259 28.0084 62.7639 27.5959 63.6675 27.5959C64.7864 27.5959 65.425 28.2048 65.425 29.4615V32.8583H64.5624V29.736C64.5624 28.8034 64.1989 28.3027 63.3745 28.3027C62.4107 28.3027 61.9029 28.9704 61.9029 30.0014V32.8588H61.0409V27.7137H61.0365Z" fill="#0B1E46" />
        <path d="M67.9057 28.3721V31.3778C67.9057 32.0257 68.0435 32.2322 68.5653 32.2322C68.7662 32.2288 68.9659 32.2118 69.1646 32.1814V32.8684C68.9153 32.9166 68.6622 32.9398 68.4086 32.9374C67.4071 32.9374 67.0436 32.5442 67.0436 31.5137V28.3721H65.9618V27.7339H66.7862C67.0124 27.7339 67.1007 27.6456 67.1007 27.4295V26.5852H67.9057V27.7141H69.163V28.3737L67.9057 28.3721Z" fill="#0B1E46" />
        <path d="M70.5485 31.357C70.6169 32.044 71.1273 32.3489 71.9425 32.3489C72.7082 32.3489 73.2187 32.0739 73.2187 31.5534C73.2187 31.1708 72.9339 30.9445 72.2269 30.7289L71.1176 30.4047C70.3223 30.1693 69.9195 29.6883 69.9195 28.9907C69.9195 27.9993 70.7348 27.5969 71.8047 27.5969C72.9441 27.5969 73.8471 28.0581 73.9451 29.119L73.1595 29.1698C73.0712 28.5218 72.5122 28.2271 71.8047 28.2271C71.1769 28.2078 70.7644 28.4808 70.7644 28.9638C70.7644 29.3763 71.0584 29.6117 71.5985 29.769L72.7174 30.0932C73.58 30.3387 74.0727 30.7705 74.0727 31.5168C74.0727 32.4691 73.2575 32.9795 71.9221 32.9795C70.6653 32.9795 69.8506 32.4986 69.7623 31.3986L70.5485 31.357Z" fill="#0B1E46" />
        <path d="M46.4092 27.7241L44.4944 33.4573C44.1393 34.5766 43.7674 34.95 42.7663 34.95C42.6096 34.95 42.4112 34.9404 42.1971 34.9206V34.2529C42.3742 34.2626 42.5604 34.2727 42.7176 34.2727C43.2473 34.2727 43.4731 34.0764 43.709 33.33L43.8567 32.8688L41.9119 27.7241H42.8541L44.2824 31.783L45.4848 27.7241H46.4077H46.4092Z" fill="#0B1E46" />
        <path d="M4.13462 29.5908C4.13462 27.6675 5.56335 26.2108 7.51468 26.2108C9.07432 26.2108 10.2879 27.1722 10.671 28.5635H9.24225C8.93429 27.8912 8.3087 27.4807 7.50555 27.4807C6.30106 27.4807 5.47913 28.3676 5.47913 29.5908C5.47913 30.8141 6.30106 31.701 7.50555 31.701C8.3087 31.701 8.93429 31.2906 9.24225 30.6183H10.671C10.2879 32.009 9.07432 32.9709 7.51468 32.9709C5.56335 32.9704 4.13462 31.5143 4.13462 29.5908Z" fill="#0B1E46" />
        <path d="M12.6898 25.9857H11.3544V32.859H12.6898V25.9857Z" fill="#0B1E46" />
        <path d="M14.7979 30.2916C14.7979 31.1414 15.3773 31.7015 16.1242 31.7015C16.871 31.7015 17.4504 31.1434 17.4504 30.2916C17.4504 29.4513 16.871 28.8816 16.1242 28.8816C15.3773 28.8816 14.7979 29.4498 14.7979 30.2916ZM18.8061 30.2916C18.8061 31.8416 17.6295 32.9714 16.1262 32.9714C14.632 32.9714 13.4559 31.8324 13.4559 30.2916C13.4559 28.7421 14.6513 27.6117 16.1262 27.6117C17.6082 27.6117 18.8035 28.7396 18.8035 30.2916H18.8061Z" fill="#0B1E46" />
        <path d="M24.3201 27.7241V32.8587H22.9847V32.1483C22.6113 32.6709 22.107 32.9698 21.3977 32.9698C20.2678 32.9698 19.5676 32.195 19.5676 30.9439V27.7241H20.903V30.6562C20.903 31.3284 21.1567 31.7018 21.8462 31.7018C22.5839 31.7018 22.9852 31.263 22.9852 30.4228V27.7241H24.3201Z" fill="#0B1E46" />
        <path d="M29.0198 30.2912C29.0198 29.451 28.45 28.8812 27.7037 28.8812C26.9574 28.8812 26.4079 29.4495 26.4079 30.2912C26.4079 31.1411 26.966 31.7012 27.7058 31.7012C28.45 31.7012 29.0198 31.1411 29.0198 30.2912ZM30.3552 25.9857V32.859H29.0198V32.168C28.6647 32.6535 28.1233 32.9711 27.4044 32.9711C26.069 32.9711 25.0608 31.8691 25.0608 30.2912C25.0608 28.7037 26.0786 27.6113 27.3948 27.6113C27.7129 27.6051 28.0277 27.6777 28.311 27.8227C28.5943 27.9677 28.8373 28.1806 29.0183 28.4424V25.9857H30.3552Z" fill="#0B1E46" />
        <path d="M43.878 20.6318C46.515 17.9947 46.515 13.7193 43.878 11.0823C41.241 8.44524 36.9655 8.44524 34.3285 11.0823C31.6915 13.7193 31.6915 17.9947 34.3285 20.6318C36.9655 23.2688 41.241 23.2688 43.878 20.6318Z" fill="#6496DC" />
        <path d="M37.6526 17.7443L42.0256 13.3713C42.1336 13.2631 42.2619 13.1773 42.4032 13.1187C42.5444 13.0602 42.6958 13.03 42.8488 13.03C43.0017 13.03 43.1531 13.0602 43.2943 13.1187C43.4356 13.1773 43.5639 13.2631 43.672 13.3713H43.6745C43.8923 13.59 44.0146 13.8861 44.0146 14.1948C44.0146 14.5034 43.8923 14.7995 43.6745 15.0183L40.9505 17.7443C40.5129 18.1812 39.9199 18.4265 39.3015 18.4265C38.6832 18.4265 38.0902 18.1812 37.6526 17.7443Z" fill="white" />
        <path d="M40.5538 13.969L36.1808 18.342C36.0727 18.4502 35.9443 18.5361 35.803 18.5946C35.6617 18.6532 35.5103 18.6833 35.3573 18.6833C35.2044 18.6833 35.0529 18.6532 34.9116 18.5946C34.7703 18.5361 34.642 18.4502 34.5338 18.342C34.4257 18.2339 34.3398 18.1055 34.2812 17.9642C34.2227 17.8229 34.1925 17.6715 34.1925 17.5185C34.1925 17.3656 34.2227 17.2141 34.2812 17.0728C34.3398 16.9315 34.4257 16.8032 34.5338 16.6951L37.2579 13.971C37.6951 13.5345 38.2876 13.2892 38.9054 13.2888C39.5232 13.2884 40.116 13.533 40.5538 13.969Z" fill="white" />
      </g>
    </svg>
  )
}
