import React from "react"
import {
  AddressInput,
  AddressInputStrict,
  addressWrapper,
  payloadModifierStreet,
  buildPayloadModifierHouse,
} from "./addressWrapper"
import axios from "axios"

class WrappedAddress extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSkip = this.onSkip.bind(this)
    this.state = { valid: true }
  }

  onSubmit(e) {
    e.preventDefault()
    const {
      street,
      house,
      placement,
      entrance,
      floor,
      intercom_code,
      comment,
      metaData,
    } = this.props.addressProps
    if (street && house && placement.length > 0) {
      axios
        .put(this.props.set_address_path, {
          street,
          house,
          placement,
          entrance,
          floor,
          intercom_code,
          comment,
          ...metaData,
        })
        .then(() => {
          window.location.href = this.props.proceed_path
        })
    } else {
      this.setState({ valid: false })
    }
  }

  onSkip(e) {
    e.preventDefault()
    window.location.href = this.props.proceed_path
  }

  render() {
    const {
      dadata_api_key,
      city,
      cities: { id_to_city_name },
    } = this.props
    const { house, placement, street_fias_id } = this.props.addressProps
    const { valid } = this.state
    let error
    if (!valid) {
      if (street_fias_id == null) {
        error = "В поле улица после начала ввода выберите улицу из списка"
      } else if (house == "") {
        error = "В поле улица после начала ввода выберите дом из списка"
      } else {
        error =
          "Введите квартиру/офис. Если у адреса нет квартиры или офиса, напишите нет или символ *"
      }
    }
    return (
      <div className="visible-block address">
        <div className="visible-block__wrap">
          <div className="visible-block__inner">
            {!valid && (
              <div className="alert alert-warning" role="alert">
                {error}
              </div>
            )}
            <form type="form">
              <div>
                <AddressInputStrict
                  labelClassName="visible-block__label"
                  label="Улица*"
                  inputWrapperClassName="visible-block__input-wrap"
                  token={dadata_api_key}
                  payloadModifier={payloadModifierStreet}
                  onChange={this.props.onStreetSelect}
                  query={city ? `г. ${id_to_city_name[city]}` : null}
                />
                {street_fias_id && (
                  <AddressInputStrict
                    wrapperClassName="visible-block__input-inline house"
                    labelClassName="visible-block__label"
                    label="Дом*"
                    inputWrapperClassName="visible-block__input-wrap"
                    token={dadata_api_key}
                    payloadModifier={buildPayloadModifierHouse(street_fias_id)}
                    onChange={this.props.onHouseSelect}
                  />
                )}
                {house && (
                  <AddressInput
                    wrapperClassName="visible-block__input-inline placement"
                    labelClassName="visible-block__label"
                    label="кв. / офис"
                    inputWrapperClassName="visible-block__input-wrap"
                    inputProps={{
                      name: "placement",
                      value: this.props.addressProps.placement,
                      onChange: this.props.onChange,
                    }}
                  />
                )}
                {house && (
                  <div>
                    <AddressInput
                      wrapperClassName="visible-block__input-inline ent"
                      labelClassName="visible-block__label"
                      label="под."
                      inputWrapperClassName="visible-block__input-wrap"
                      inputProps={{
                        name: "entrance",
                        value: this.props.addressProps.entrance,
                        onChange: this.props.onChange,
                      }}
                    />

                    <AddressInput
                      wrapperClassName="visible-block__input-inline fl"
                      labelClassName="visible-block__label"
                      label="эт."
                      inputWrapperClassName="visible-block__input-wrap"
                      inputProps={{
                        name: "floor",
                        value: this.props.addressProps.floor,
                        onChange: this.props.onChange,
                      }}
                    />

                    <AddressInput
                      wrapperClassName="visible-block__input-inline fl"
                      labelClassName="visible-block__label"
                      label="код домофона"
                      inputWrapperClassName="visible-block__input-wrap"
                      inputProps={{
                        name: "intercom_code",
                        value: this.props.addressProps.intercom_code,
                        onChange: this.props.onChange,
                      }}
                    />

                    <AddressInput
                      labelClassName="visible-block__label"
                      label="Комментарий"
                      inputWrapperClassName="visible-block__input-wrap"
                      inputProps={{
                        name: "comment",
                        value: this.props.addressProps.comment,
                        onChange: this.props.onChange,
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="visible-block__btn-wrap btn-inline">
                <button className="btn" onClick={this.onSubmit} type="submit">
                  {" "}
                  Далее{" "}
                </button>
              </div>
              <div className="visible-block__btn-wrap">
                <button className="btn btn--grey" onClick={this.onSkip}>
                  {" "}
                  Cкажу по телефону{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const Address = addressWrapper(WrappedAddress)

export default Address
