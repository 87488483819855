import React from 'react';
import cx from 'classnames';
import s from './CoupleTopButtons.module.scss';
import { useScrollContext } from '../../../context/ScrollContext';
import { handleHeaderOrderTracking } from '../../../helpers/trackingsHelper';

export const CoupleTopButtons = () => {
  const { setScrollToElement } = useScrollContext();

  const handleScroll = () => {
    setScrollToElement('order');
    handleHeaderOrderTracking();
  };

  const handleCallBack = () => {
    if (window.jivo_api) {
      window.jivo_api.open({ start: 'call' });
    }
  }

  return (
    <div className={s.buttons}>
      <div className={cx(s.btn_wrap, s.btn_wrap_first)}>
        <button onClick={handleScroll} data-target="top-order" className={cx(s.btn, s.main)}>Заказать</button>
      </div>
      <div className={cx(s.btn_wrap, s.btn_wrap_second)}>
        <button onClick={handleCallBack} className={cx(s.btn, s.btn_grey)}>перезвонить мне</button>
      </div>
    </div>
  );
};
