import React from "react";
import s from './LeftSide.module.scss';
import {HeartIcon} from "../../../../icons/Prefooter/HeartIcon";
import {LogoIcon} from "../../../../icons/Prefooter/LogoIcon";
import InputMask from "react-input-mask";
import {getPromo} from "../../../../../api/promo";
import {inputNumber, totalNumber} from "../../../../../helpers/numberHelper";
import ReCAPTCHA from "react-google-recaptcha";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {sandPhone} from "../../../../../api/authPopup";
import {PromoInform} from "./PromoInform";
import {LandingPageContext} from "../../LandingContext/LandingProvider";
import { useAuthContext, AUTH_ACTION } from "../../../../../context/AuthContext";

const { OPEN_MODAL_PREFOOTER } = AUTH_ACTION;

export const LeftSide = ({isTimerActive, setIsTimerActive}) => {
  const [open, setOpen] = React.useState(false)
  const [isLoggedError, setIsLoggedError] = React.useState(false)

  const [number, setNumber] = React.useState('+7')
  const [numberError, setNumberError] = React.useState(false)

  const [tokenV2, setTokenV2] = React.useState('')
  const [isShowV2, setIsShowV2] = React.useState(false)
  const [tokenV3, setTokenV3] = React.useState('')

  const { executeRecaptcha } = useGoogleReCaptcha();
  const {user, reCaptchaV2Token} = React.useContext(LandingPageContext);
  const { setOpenAuthAction, setAuthArguments } = useAuthContext();

  const sandPromo = (token, version) => {
    const currentNumber = user.isUserLogged ? user.phone : totalNumber(number)

    if (!user.isUserLogged && currentNumber.length < 10) {
      setNumberError(true)
      return
    }

    if (isTimerActive) {
      getPromo({
        captcha_token: token,
        captcha_version: version,
        phone: currentNumber,
      })
        .then(() => {
          setIsTimerActive(false)
          setOpen(!open)
        })
        .catch(error => {
          if (error.response.data.errors.source.pointer === 'data/attributes/failed_promo_check') {
            setIsLoggedError(true)
            setOpen(true)
          } else {
            setIsShowV2(true)
          }
        })
    } else {
      sandPhone({
        phone: currentNumber,
        captcha_token: token,
        captcha_version: version
      })
        .then(() => {
          setOpen(false)
          setOpenAuthAction(OPEN_MODAL_PREFOOTER)
          setAuthArguments({isOpen: true, number})
        })
        .catch(error => {
          if(error.response.data.errors.source.pointer === 'data/attributes/failed_promo_check') {
            setIsLoggedError(true)
            setOpen(true)
          } else {
            setIsShowV2(true)
          }
        })
    }
  }

  const handleSubmit = () => {
    user.phone && setNumber(inputNumber(user.phone))
    if (!isTimerActive && user.isUserLogged) {
      setOpenAuthAction(OPEN_MODAL_PREFOOTER)
      setAuthArguments({ number: inputNumber(user.phone) })
      return
    }

    if (!isShowV2) {
      sandPromo(tokenV3, 'v3')
      return
    }
    if (!tokenV2) return
    sandPromo(tokenV2, 'v2')
  }

  React.useEffect(() => {
    if (!executeRecaptcha) return
    executeRecaptcha(isTimerActive ? 'prefooter' : 'auth_popup').then(token => setTokenV3(token))
  }, [isTimerActive, executeRecaptcha, setTokenV3])

  return (
    <div
      className={`${s.left_side} ${isTimerActive ? '' : s.left_side_without_timer}`}
      style={{ justifyContent: isTimerActive ? '' : 'center' }}
    >
      <div
        className={`
          ${s.left_side_box}
          ${isTimerActive ? '' : s.left_side_box_without_timer}
          ${user.isUserLogged ? s.left_side_box_with_user : ''}
          `}
      >
        <div style={{display: 'flex'}} className={s.block_title}>Здоровье - бесценно
          <div style={{marginTop: '6px', marginLeft: '7px'}}>
            <HeartIcon/>
          </div>
        </div>
        <div className={s.text_wrapper}>
          <LogoIcon/>
          <div className={s.left_box_content}>
            <div className={s.text1}>от</div>
            <div className={s.text2}>1 013</div>
            <div className={s.text3}>рублей в день</div>
          </div>
        </div>

        {!user.isUserLogged && <div className={s.input_wrapper}>
          <div className={s.title2}>Номер телефона</div>
          <InputMask
            mask="+7\(999) 999 99 99"
            value={number}
            onChange={e => {
              setNumberError(false)
              setNumber(e.target.value)
            }}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                data-target="input-mask"
                className={`${s.input} ${numberError ? s.error : ''} ${s.left_side_input}`}
                style={{marginTop: '5px', fontSize: '20px'}}
                placeholder='+7 (999) 999 99 99'
                inputMode="numeric"
              />
            )}
          </InputMask>
        </div>}

        {isShowV2 &&
          <ReCAPTCHA
            sitekey={reCaptchaV2Token}
            onChange={setTokenV2}
          />
        }

        <div className={s.button}>
          <button onClick={handleSubmit}>Хочу быть здоровым</button>
        </div>
      </div>

      <PromoInform
        open={open}
        setOpen={setOpen}
        number={number}
        isTimerActive={isTimerActive}
        isLoggedError={isLoggedError}
      />
    </div>
  )
}
