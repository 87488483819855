import React from 'react'

export const CloseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path d="M15 5L5 15" stroke="#252525" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 5L15 15" stroke="#252525" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
)
