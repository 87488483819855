import React from 'react'
import {InformIcon} from "../../icons/Calculator/InformIcon";
import s from './tooltip.module.scss'
import {isMobile} from "react-device-detect";
import {CloseIcon} from "./CloseIcon";

export const Tooltip = ({children}) => {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const hideToolTip = (event) => {
      const isItTooltip = !!(event.target).closest(`[data-modal="tooltip"]`);
      !isItTooltip && open && setOpen(false);
    }
    const hideByScroll = () => setOpen(false)

    document.addEventListener('click', hideToolTip);
    document.addEventListener('scroll', hideByScroll);
    return () => {
      document.removeEventListener('click', hideToolTip)
      document.removeEventListener('click', hideByScroll)
    }
  }, [open]);

  return (
    <div
      className={`${s.wrapper} ${open ? s.hover : ''}`}
      onMouseEnter={() => !isMobile && setOpen(true)}
      onMouseLeave={() => !isMobile && setOpen(false)}
      onClick={e => {
        if (!isMobile) return
        e.stopPropagation()
        setOpen(!open)
      }}
    >
      <InformIcon/>
      {open &&
        <>
          <div
            onClick={e => e.stopPropagation()}
            data-modal="tooltip"
            className={s.content}
          >
            {children}
            {isMobile &&
              <div
                className={s.close}
                onClick={e => {
                  e.stopPropagation()
                  setOpen(false)
                }}
              >
                <CloseIcon/>
              </div>
            }
          </div>
          <div className={s.triangle}/>
        </>
      }
    </div>
  )
}
